import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import dayjs from "dayjs";
import { useExternalBookStore } from "../../../../../../Zustand";

// Assuming 'date', 'time', and 'therapist' are props or state

const iconStyles = {
  color: "inherit",
  fontWeight: 600,
  fontSize: 19,
};

const listItemButtonStyles = {
  color: "black",
  borderRadius: 5,
};

const InfoItem = ({ icon, text }) => (
  <ListItem disablePadding className="m-0">
    <ListItemButton
      className="onHover"
      style={listItemButtonStyles}
    >
      <ListItemIcon
        className="!min-w-[40px] md:!min-w-[56px]"
        style={iconStyles}
      >
        {icon}
      </ListItemIcon>
      <ListItemText className="text-white" primary={text} />
    </ListItemButton>
  </ListItem>
);

const BookingInfoList = () => {
  const { bookInfo } = useExternalBookStore();
  return (
    <List className="!p-0 w-full md:!w-max bg-[#4594d3] flex justify-center flex-col rounded-t-lg md:rounded-l-lg md:rounded-tr-none">
      <InfoItem
        icon={
          <CalendarMonthIcon className="md:w-[30px] md:h-[30px] mr-[5px] text-white" />
        }
        text={bookInfo?.date}
      />
      <InfoItem
        icon={<AccessTimeIcon className="md:w-[30px] md:h-[30px] mr-[5px] text-white" />}
        text={dayjs(bookInfo?.time).format("HH:mm")}
      />
      <InfoItem
        icon={
          <PersonIcon className="w-6 h-6 md:w-[30px] md:h-[30px] mr-[5px] text-white" />
        }
        text={`${bookInfo?.therapistInfo?.name} ${bookInfo?.therapistInfo?.surname}`}
      />
    </List>
  );
};

export default BookingInfoList;
