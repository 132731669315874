import { Box, CircularProgress } from "@mui/material"
import { Navigate, Outlet } from "react-router-dom"
import dayjs from "dayjs"
import { useRef } from "react"
import { useStoreUser } from "../../../Zustand"
var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

function ProtectedRouteUserOnboarding({ token, loading }) {
	const token_expiry_time = dayjs(new Date(token?.expires_on))
		.utc()
		.local()
		.format()

	const current_time = dayjs(new Date()).utc().local().format()
	const { userInfo } = useStoreUser()
	if (userInfo?.data && ("therapist" in userInfo?.data)) {
		return <Navigate to="/therapist" />
	}
	if (userInfo?.data?.onboarding_completed) {
		return <Navigate to="/" />
	}
	if (
		current_time > token_expiry_time ||
		token_expiry_time === "Invalid Date" || 
		!token?.access_token || !userInfo?.data
	) {
		return <Navigate to="/auth/login" />
	}
	if (loading) {
		return (
			<Box
				sx={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<Box>
					<CircularProgress />
				</Box>
			</Box>
		)
	}

	return <Outlet />;
}

export default ProtectedRouteUserOnboarding
