import { useQuery } from "@tanstack/react-query";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import BookingSide from "./components/BookingSide";
import therapistImage from "../../../User/images/therapist1.png";
import CommunitiesIcon from "./communities.svg";
import ParticipantsIcon from "./participants.svg";
import ProfilePhotoIcon from "./profile_photo.svg";
import ApiRequests from "../../../../api/ApiRequests";
import NotFound from "../../../helpers/NotFound";
import MedicalConditionChip from "./components/MedicalConditionChip";
import LoadingPage from "../../../../../components/Cards/LoadingPage";
import Calendar from "../../../User/Therapists/Calendar";
function SakinaTag(props) {
  const { tag } = useParams();
  const { getTherapistInfoByTag } = ApiRequests();
  const isDesktop = window.innerWidth >= 768;
  const { data, isError, isLoading } = useQuery(["therapist-data"], () =>
    getTherapistInfoByTag(tag)
  );
  if (isLoading) return <LoadingPage />;
  if (!data) return <NotFound />;
  const therapistInfo = data.data;

  console.log({ data, isError, isLoading });

  return (
    <Box className="h-full flex flex-wrap md:flex-row flex-col items-center md:relative md:top-[50%] md:p-4 md:translate-y-[-50%] md:transform-[translateY(-50%)] max-w-7xl m-auto">
      <div
        className="min-h-max md:min-h-full p-4 md:py-5 md:px-4 flex-2 md:m-[5px] m-auto rounded-[23px] flex items-center flex-col bg-[#e0e1f1] self-stretch"
        style={{ flex: 2, width: !isDesktop ? "calc(100vw - 32px)" : null }}
      >
        <Box className="flex justify-start w-full md:py-2.5 md:px-5 overflow-auto">
          <Avatar
            src={therapistInfo.profile_picture || therapistImage}
            className="bg-purple !w-24 !h-24 mr-2.5 md:mr-0"
          />
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            className="md:ml-0 md:w-full p-0 md:px-[30px] flex justify-center !items-start"
          >
            <h4
              style={{
                color: "#323331",
                fontWeight: 600,
                fontSize: 18,
                padding: 0,
                margin: 0,
              }}
            >
              {therapistInfo.name || "Name"}
            </h4>
            <p style={{ color: "grey", marginBottom: 5 }}>
              {therapistInfo.title}
            </p>
            {isDesktop ? (
              <Prices
                sessionInfo={therapistInfo.session_cost}
                total_therapy_session_count={
                  therapistInfo.total_therapy_session_count
                }
              />
            ) : null}
          </Box>
        </Box>
        {!isDesktop ? (
          <Prices
            sessionInfo={therapistInfo.session_cost}
            total_therapy_session_count={
              therapistInfo.total_therapy_session_count
            }
          />
        ) : null}
        <Box sx={{ width: "100%" }} className="mt-4">
          <h4
            style={{
              color: "#323331",
              fontWeight: 700,
              fontSize: 20,
              padding: 0,
              margin: 0,
              marginBottom: 2,
            }}
          >
            About therapist
          </h4>
          <Typography>
            Year of Experience: <strong>{therapistInfo.full_years_of_experience}</strong>
          </Typography>
          <Typography>
            Education: <strong>{therapistInfo.higher_education}</strong>
          </Typography>
          <Typography>
            Achievement: <strong>{therapistInfo.professional_achievements}</strong>
          </Typography>
        </Box>
        <Box className="mb-4 mt-4 w-full">
          <Typography variant="h6" className="!font-semibold">
            About me
          </Typography>
          <Typography className="!mt-2">
            {therapistInfo.about_me}
          </Typography>
        </Box>
        <div className="mb-4 w-full">
          <Typography variant="h6" className="!font-semibold">
            Issues I work with
          </Typography>
          <div className="mt-2 flex flex-wrap gap-2">
            {
              therapistInfo.medical_conditions.map(({name, id}) => {
                return <MedicalConditionChip name={name} key={`medchip-${id}`}/>
              })
            }
          </div>
        </div>
{/* 
        <div className="mb-4 w-full">
          <Typography variant="h6" className="!font-semibold">
            Qualifications
          </Typography>
          <Typography className="!mt-2 pl-2">
            • Verified by Sakina | Membership with Health & Care Professions
            Council | SKN040358
          </Typography>
          <Typography className="pl-2">• Certificate from PHOLA</Typography>
          <Typography className="pl-2">• Certificate from UCL</Typography>
          <Typography className="pl-2">
            • Doctorate in Clinical Psychology (DClinPsy), Graduated 2021
          </Typography>
        </div> */}
        <div className="mb-4 w-full">
          <Typography variant="h6" className="!font-semibold">
            Client Focus
          </Typography>

          <div className="flex mt-2 justify-between gap-3 flex-wrap">
            <div className="flex flex-col items-center">
              <Box display="flex" width="100%">
                <img src={ProfilePhotoIcon} alt="" style={{ width: "24px" }} />
                <Typography variant="subtitle1" className="mt-2 !ml-3">
                  Age
                </Typography>
              </Box>
              <Typography variant="body2" className="!mt-2">
                Preteen, Teen, Adults, Elders (65+)
              </Typography>
            </div>

            <div className="flex flex-col items-center">
              <Box display="flex" width="100%">
                <img src={ParticipantsIcon} alt="" style={{ width: "24px" }} />
                <Typography variant="subtitle1" className="mt-2 !ml-3">
                  Participants
                </Typography>
              </Box>
              <Typography variant="body2" className="!mt-2">
                Individuals, Couples, Group
              </Typography>
            </div>

            <div className="flex flex-col items-center">
              <Box display="flex" width="100%">
                <img src={CommunitiesIcon} alt="" style={{ width: "24px" }} />
                <Typography variant="subtitle1" className="mt-2 !ml-3">
                  Communities
                </Typography>
              </Box>
              <Typography variant="body2" className="!mt-2">
                Body Positivity, Racial Justice Allied
              </Typography>
            </div>
          </div>
        </div>
        <Box></Box>
        {/* <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          sx={{ bottom: 30 }}
        >
          <Link to="/therapist-list">
            <Button
              variant="contained"
              style={{
                borderRadius: 20,
                color: "black",
                background: "white",
                width: 100,
                fontWeight: 600,
                boxShadow: "none",
                marginRight: 20,
              }}
            >
              Back
            </Button>
          </Link>
        </Box> */}
      </div>
      {/* <Calendar therapist={therapistInfo}/> */}
      <BookingSide therapistInfo={therapistInfo} />
    </Box>
  );
}
function Prices({ sessionInfo, total_therapy_session_count }) {
  return (
    <>
      <Box className="flex flex-wrap gap-2 w-full">
        <p className="flex flex-col text-[rgb(226,109,128)] px-2.5 py-0.5 bg-white border-2 border-[rgb(226,109,128)] rounded-xl w-fit text-xs">
          Price for individual:
          <span className="font-bold text-base">
            £{sessionInfo.individual_cost / 100} /{" "}
            {sessionInfo.individual_cost_duration_minutes} min
          </span>
        </p>
        <p className="flex flex-col text-[rgb(226,109,128)] px-2.5 py-0.5 bg-white border-2 border-[rgb(226,109,128)] rounded-xl w-fit text-xs">
          Price for couples:
          <span className="font-bold text-base">
            £{sessionInfo.couple_cost / 100} /{" "}
            {sessionInfo.couple_cost_duration_minutes} min
          </span>
        </p>
        <p className="flex text-[rgb(226,109,128)] px-2.5 py-0.5 bg-white border-2 border-[rgb(226,109,128)] rounded-xl w-fit text-xs items-center">
          <span className="font-bold text-base">
            {total_therapy_session_count > 1000
              ? `1000+`
              : total_therapy_session_count}{" "}
            sessions
          </span>
        </p>
      </Box>
    </>
  );
}
export default SakinaTag;
