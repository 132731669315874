import axios from "axios"
import { useTokenStore } from "../../Zustand"
import { createAxios } from "../sakinah/helpers/createAxios"

function ApiRequests() {
	const { token } = useTokenStore()
	const Axios = createAxios(token?.access_token)
	const END_POINT = process.env.REACT_APP_API_ENDPOINT;

	// Booking
	function getBookingList() {
		const response = Axios.get("/bookings")
		return response
	}

	function getBookingById(id) {
		const response = Axios.get("/bookings/{id}")
		return response
	}

	function cancelBooking(id) {
		const response = Axios.post(`/bookings/${id}/cancel`)
		return response
	}

	function getBookingCancellationFee(id) {
		const response = Axios.get(`/bookings/${id}/cancellation-fee`)
		return response
	}

	function postReservation(body) {
		const response = Axios.post(`/reservation`, body)
		return response
	}

	// Calendar
	function authCalendarCompleted() {
		console.log("authCalendarCompleted API called");
		
		const response = Axios.post("/calendar/auth-completed")
		return response
	}

	function generateCalendarAuthUrl() {
		const response = Axios.post("/calendar/auth-url")
		return response
	}

	function getCalendarAvailability(timeSlots) {
		console.log("getCalendarAvailability", timeSlots);
		
		const response = Axios.get("/calendar/availability", {
			params: timeSlots,
		})
		return response
	}

	// function getCalendarEvents() {
	// 	const response = Axios.get("/calendar/events")
	// 	return response
	// }

	function formatDate(date) {
		return date.toISOString().slice(0, 19) + ".000Z"
	}
	function getCalendarEvents() {
		const today = new Date()
		const past30Days = new Date(today)
		past30Days.setDate(today.getDate() - 30)

		const nextTwoMonths = new Date(today)
		nextTwoMonths.setMonth(today.getMonth() + 2)

		const params = {
			from_date: formatDate(past30Days),
			to_date: formatDate(nextTwoMonths),
		}
		const response = Axios.get("/calendar/events", { params })
		return response
	}

	function cancelCalendarEvents(id) {
		const response = Axios.post(`/calendar/events/${id}/cancel`)
		return response
	}

	function getCalendarSync() {
		const response = Axios.get("/calendar/sync")
		return response
	}

	// Therapist
	function getTherapistInfo() {
		const response = Axios.get("/therapist")
		return response
	}

	function getTherapistsList() {
		const response = Axios.get("/therapist/list")
		return response
	}

	// Patient
	function getPatientInfo() {
		const response = Axios.get("/patient")
		return response
	}

	function getOnePatientInfo(id) {
		console.log("id :>> ", id)
		const response = Axios.get(`/patient/${id}`)
		return response
	}

	// Patient info by id
	function getPatientInfoById(id) {
		const response = Axios.get(`/patient/${id}`)
		return response
	}

	// Languages
	function getLanguageList() {
		const response = Axios.get("/language/list")
		return response
	}

	// Medical Conditions
	function getMedicalConditions() {
		const response = Axios.get("/medical-conditions")
		return response
	}

	//Get Calendar Sync Info, will tell if the therapist need to sync their calendar
	function getCalendarSyncedInfo() {
		const response = Axios.get("/calendar/sync")
		return response
	}

	function getMedicalConditionsOfUser() {
		const response = Axios.get("/medical-conditions/selected")
		return response
	}

	// Uploading files and images
	function postUploadFiles(formData) {
		const response = axios.post(
			END_POINT + "/file/upload",
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					accept: "multipart/form-data",
					authorization: "Bearer " + token?.access_token,
				},
			}
		)
		return response
	}

	// Post therapist onboarding information

	function postTherapistInfo(info) {
		console.log("sadasD", `${END_POINT}/onboarding/complete-therapist`);
		
		const response = axios.post(
			`${END_POINT}/onboarding/complete-therapist`,
			info,

			{
				headers: {
					"Content-Type": "application/json",
					accept: "application/json",
					authorization: "Bearer " + token.access_token,
				},
			}
		)
		return response
	}

	// Sync calendar

	function syncCalendar(email) {
		const response = axios.post(
			`${END_POINT}/calendar/auth-url`,
			{ email: email },
			{
				headers: {
					"Content-Type": "application/json",
					accept: "application/json",
					authorization: "Bearer " + token.access_token,
				},
			}
		)
		return response
	}
	
	// Reset password

	function sendPasswordResetLink(email, type) {
		const response = axios.post(
			`${END_POINT}/session/send-password-reset-link`,
			{ email: email, session_type: type },
			{
				headers: {
					"Content-Type": "application/json",
					accept: "application/json",
				},
			}
		)
		return response
	}
	// Set new password

	function setNewPassword ({password, token: token1, type}) {
		console.log(JSON.stringify({password, token: token1, type}));
		console.log(`${END_POINT}/session/set-password-with-token`);
		
		const response = axios.post(
			`${END_POINT}/session/set-password-with-token`,
			{ new_password: password, session_type: type, token: token1},
			{
				headers: {
					"Content-Type": "application/json",
					accept: "application/json",
				},
			}
		)
		return response
	}

	// Get tag names
	function getTagNames(name, surname, count) {
		const response = Axios.get(`/tags/available?name=${name}&surname=${surname}&max_count_tags=${count}`);
		console.log(`/tags/available?name=${name}&surname=${surname}&max_count_tags=${count}`);
		
		return response;
	}

	function getTherapistInfoByTag(tag) {
		const response = Axios.get(`/therapist/tag/${tag}`);
		return response
	}

	function getPublicCalendarAvailability(timeSlots) {
		console.log("Timeslots: ", timeSlots);
		
		const response = Axios.get(`/calendar/${timeSlots.therapist_id}/availability`, {
			params: timeSlots,
		})
		return response
	}

	return {
		getBookingList,
		cancelBooking,
		getBookingCancellationFee,
		postReservation,
		authCalendarCompleted,
		getCalendarAvailability,
		generateCalendarAuthUrl,
		getCalendarEvents,
		cancelCalendarEvents,
		getCalendarSync,
		getCalendarSyncedInfo,
		getTherapistInfo,
		getTherapistsList,
		getPatientInfo,
		getOnePatientInfo,
		getLanguageList,
		getMedicalConditions,
		getMedicalConditionsOfUser,
		postUploadFiles,
		postTherapistInfo,
		getPatientInfoById,
		syncCalendar,
		sendPasswordResetLink,
		setNewPassword,
		getTagNames,
		getTherapistInfoByTag,
		getPublicCalendarAvailability
	}
}

export default ApiRequests
