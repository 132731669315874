import React from "react"
import calendar from "../images/calendar.svg"
import {
	Box,
	Button,
	CssBaseline,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material"
import { useStore } from "../../../../Zustand"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import PersonIcon from "@mui/icons-material/Person"
import PaymentUI from "./PaymenyUI"
import dayjs from "dayjs"



function ConfirmBooking({
	therapist,
	open,
	setOpen,
	date,
	time,
	handleMutate,
	setOpenSnackbar,
	isForCouples
}) {
	const { setUpcomingSession } = useStore((state) => state)
	
	// Add a new session with a generated id.

	function handleBook() {
		handleMutate()
		setOpen(false)
		setUpcomingSession({ ...therapist, date: date, time: time })
	}

	// convert into time format 15:00

	return (
		<BootstrapDialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth={true}
			maxWidth="sm"
		>
			<CssBaseline />
			<Grid container sx={{ padding: 2 }}>
				{/* <Grid item xs={12} sm={9} sx={{ borderRadius: "4px" }}>
					<PaymentUI handleBook={handleBook} setOpen={setOpen} />
				</Grid> */}

				<Grid
					item
					xs={12}
					className="!m-0 md:!my-2.5 !rounded"
					sx={{
						background: "rgba(226, 230, 251, 0.3)",
					}}
				>
					<DialogTitle id="alert-dialog-title" textAlign="center">
						<span className="text-xl md:text-[1.2rem] font-semibold md:pt-5 md:px-[30px] md:pb-2.5">
							Chosen Date and Time
						</span>
					</DialogTitle>
					<DialogContent className="!h-auto md:!h-[50vh]">
						<Box className="m-0 h-auto pt-2 px-2 pb-4 md:p-0 md:my-2.5 md:mx-auto md:pt-2.5 md:pb-5 md:h-full md:max-w-[270px] "
							style={{
								width: "100%",
								background: "white",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								fontSize: 16,
								borderRadius: "6px",
								overflow: "none",
							}}
						>
							<List className="p-0 md:py-2">
								<ListItem disablePadding className="m-0 md:my-[1.5em]">
									<ListItemButton
										className="onHover md:mb-[5px]"
										style={{
											color: "black",
											borderRadius: 5,
										}}
									>
										<ListItemIcon className="!min-w-[40px] md:!min-w-[56px]"
											style={{
												color: "inherit",
												fontWeight: 600,
												fontSize: 19,
											}}
										>
											<CalendarMonthIcon className="md:w-[30px] md:h-[30px] mr-[5px]"/>
										</ListItemIcon>
										<ListItemText primary={date} />
									</ListItemButton>
								</ListItem>
								<ListItem disablePadding className="m-0 md:my-[1.5em]">
									<ListItemButton
										className="onHover md:mb-[5px]"
										style={{
											color: "black",
											borderRadius: 5,
										}}
									>
										<ListItemIcon className="!min-w-[40px] md:!min-w-[56px]" style={{ color: "inherit", fontWeight: 600 }}>
											<AccessTimeIcon className="md:w-[30px] md:h-[30px] mr-[5px]"/>
										</ListItemIcon>
										<ListItemText primary={dayjs(time).format("HH:mm")} />
									</ListItemButton>
								</ListItem>
								<ListItem disablePadding className="m-0 md:my-[1.5em]">
									<ListItemButton
										className="onHover md:mb-[5px]"
										style={{
											color: "black",
											borderRadius: 5,
										}}
									>
										<ListItemIcon className="!min-w-[40px] md:!min-w-[56px]" style={{ color: "inherit", fontWeight: 600 }}>
											<PersonIcon className="w-6 h-6 md:w-[30px] md:h-[30px] mr-[5px]"/>
										</ListItemIcon>
										<ListItemText
											primary={`${therapist?.name} ${therapist?.surname}`}
										/>
									</ListItemButton>
								</ListItem>
							</List>
							<img
								style={{
									color: "aqua",
								}}
								className="w-[100px] md:h-20 md:w-auto"
								src={calendar}
								alt="calendar"
							/>
						</Box>
					</DialogContent>
					<Divider />
					<DialogActions
						className="flex-col md:flex-row"
						style={{
							margin: "auto",
							color: "black",
							display: "flex",
							justifyContent: "space-evenly",
						}}
					>
						<Box className="flex items-center justify-between gap-3">
							<p className="text-[10px]">You have to Pay</p>
							<h2 className="text-[28px] md:text-3xl">
								£ <strong>{isForCouples ? therapist?.session_cost.couple_cost/100 : therapist?.session_cost.individual_cost/100}</strong>
							</h2>
						</Box>
						<Box className="mt-1 ml-0 md:mt-0 md:ml-2">
							<Button
								onClick={handleBook}
								color="inherit"
								disabled={!time}
								className="md:!w-[100px] !py-[5px] !px-3.5 m-0 md:!px-5 md:!m-[5px]"
								style={{
									borderRadius: "4px",
									backgroundColor: "rgb(95, 106, 196)",
									color: "white",
									boxShadow: "none",
								}}
							>
								Book
							</Button>
							<Button
								onClick={() => setOpen(false)}
								color="inherit"
								className="!ml-2 md:!m-[5px] md:!w-[100px] !py-[5px] !px-3.5 md:!px-5">
								Cancel
							</Button>
						</Box>
					</DialogActions>
				</Grid>
			</Grid>
		</BootstrapDialog>
	)
}

export default ConfirmBooking

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
		flexGrow: 1,
		height: "50vh",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
		flexGrow: 1,
	},
}))

export interface DialogTitleProps {
	id: string
	children?: React.ReactNode
	onClose: () => void
}
