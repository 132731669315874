import { Box, Grid } from "@mui/material"
import AddModeratorIcon from "@mui/icons-material/AddModerator"
import GppBadIcon from "@mui/icons-material/GppBad"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"
import PaidIcon from "@mui/icons-material/Paid"
import { DoughnutChart } from "./DoughnutChart"
import { BarChart } from "./BarChart"
import ApiRequests from "../../../api/ApiRequests"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"
import LoadingPage from "../../../../components/Cards/LoadingPage"
import { MY_SESSIONS_TITLE } from "../../helpers/constants"
import Navbar from "../Navbar"

function Statistics({ displayGraphs, showNavbar=true }) {
	const { getBookingList } = ApiRequests()

	const {
		data: session,
		isLoading,
		isError,
	} = useQuery(["bookingList"], () => getBookingList())

	const sessions = session?.data?.bookings

	// Filtering upcoming sessions
	const upcomingSessions =
		sessions?.length > 0 &&
		sessions
			?.filter((session) => dayjs(session.start_time).unix() > dayjs().unix())
			.filter((session) => session.status === "succeeded_payment_checkout")

	// Filtering unsuccessful payment
	const unsuccessfulPaymentSessions =
		sessions?.length > 0 &&
		sessions
			?.filter((session) => dayjs(session.start_time).unix() > dayjs().unix())
			.filter((session) => session.status === "failed_payment_checkout")

	// Filtering previous sessions
	const prevSessions =
		sessions?.length > 0 &&
		sessions?.filter(
			(session) => dayjs(session.start_time).unix() < dayjs().unix()
		)

	// Filtering previous sessions
	const cancelledSessions =
		sessions?.length > 0 &&
		sessions?.filter((session) => session.cancel === true)

	if (isLoading) return <LoadingPage />
	if (isError) return <div>Something went wrong...</div>
	// Data to display
	const data = [
		{
			name: "Total # of Sessions",
			color: "#e2e6fb4d",
			number: sessions?.length || 0,
			icon: (
				<AddModeratorIcon
					style={{ width: 50, height: 50, color: "rgb(95, 97, 196)" }}
				/>
			),
		},
		{
			name: "Previous Sessions",
			color: "rgb(65 182 255 / 8%)",
			number: prevSessions?.length || 0,
			icon: (
				<SkipPreviousIcon style={{ width: 50, height: 50, color: "#41b6ff" }} />
			),
		},
		{
			name: "Cancelled Sessions",
			color: "rgb(255 139 79 / 7%)",
			number: cancelledSessions?.length,
			icon: <GppBadIcon style={{ width: 50, height: 50, color: "#ff8b4f" }} />,
		},
		{
			name: "Amount Paid",
			color: "rgb(94 196 151 / 8%)",
			number: `£${
				(sessions?.length -
					cancelledSessions?.length -
					unsuccessfulPaymentSessions.length) *
					50 || 0
			}`,
			icon: <PaidIcon style={{ width: 50, height: 50, color: "#5fc497" }} />,
		},
	]

	return (
		<>
			{
				showNavbar ? <Navbar title={MY_SESSIONS_TITLE} /> : null
			}
			<Box sx={{ marginTop: "20px" }}>
				<h3
					className="m-2.5 mx-0 md:mb-5"
					style={{
						fontWeight: 600,
						fontSize: 19,
						color: "#5f616a",
					}}
				>
					Session Statistics
				</h3>

				<div className="flex flex-wrap gap-2.5"
					style={{
						width: window.innerWidth >= 768 ? "calc(100% - 10px)" : null,
					}}
				>
					{data.map((item, index) => (
						<div
							key={`statistics-${index}`}
							className="md:ml-2.5 md:mr-5 m-0"
							style={{
								padding: "20px 10px",
								flex: 1,
								color: "white",
								height: 100,
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								flexDirection: "row",
								borderRadius: 10,
								boxShadow:
									"rgb(50 50 93 / 5%) 0px 2px 5px -1px, rgb(0 0 0 / 20%) 0px 1px 3px -1px",
								background: item.color,
							}}
						>
							<span className="mx424:w-10 mx424:h-10">{item.icon}</span>
							<div className="ml-2">
								<h2
									style={{
										fontWeight: 600,
										fontSize: 22,
										color: "rgb(50, 51, 49)",
									}}
								>
									{item.number}
								</h2>
								<p className="mx424:text-sm text-[15px]" style={{ color: "rgba(50, 51, 49, 0.8)" }}>
									{item.name}
								</p>
							</div>
						</div>
					))}
				</div>
				{/* {displayGraphs && (
					<Grid container>
						<Grid
							item
							xs={6}
							style={{
								padding: 10,
								margin: "40px 0px 30px",
							}}
						>
							<Box
								style={{
									margin: "auto",
									borderRadius: 10,
									padding: 45,
									boxShadow:
										"rgb(50 50 93 / 5%) 0px 2px 5px -1px, rgb(0 0 0 / 20%) 0px 1px 3px -1px",
								}}
							>
								<DoughnutChart dataSessions={sessions} />
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								margin: "40px 0px 30px",
								padding: 10,
							}}
						>
							<Box
								style={{
									margin: "auto",
									display: "flex",
									alignItems: "center",
									height: "100%",
									width: "100%",
									padding: 15,
									borderRadius: 10,
									boxShadow:
										"rgb(50 50 93 / 5%) 0px 2px 5px -1px, rgb(0 0 0 / 20%) 0px 1px 3px -1px",
								}}
							>
								<BarChart dataSessions={sessions} />
							</Box>
						</Grid>
					</Grid>
				)} */}
			</Box>
		</>
	)
}

export default Statistics
