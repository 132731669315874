const availabilityData = [
	{
		id: "1",
		enable: true,
		day: "Monday",
		working_hours: [
			{ start_hour: "09:00", finish_hour: "18:00" },
		],
	},
	{
		id: "2",
		enable: true,
		day: "Tuesday",
		working_hours: [
			{ start_hour: "09:00", finish_hour: "18:00" },
		],
	},
	{
		id: "3",
		enable: true,
		day: "Wednesday",
		working_hours: [
			{ start_hour: "09:00", finish_hour: "18:00" },
		],
	},
	{
		id: "4",
		enable: true,
		day: "Thursday",
		working_hours: [
			{ start_hour: "09:00", finish_hour: "18:00" },
		],
	},
	{
		id: "5",
		enable: true,
		day: "Friday",
		working_hours: [
			{ start_hour: "09:00", finish_hour: "18:00" },
		],
	},
	{
		id: "6",
		enable: false,
		day: "Saturday",
		working_hours: [{ start_hour: "09:00", finish_hour: "18:00" }],
	},
	{
		id: "7",
		enable: false,
		day: "Sunday",
		working_hours: [{ start_hour: "09:00", finish_hour: "18:00" }],
	},
];

export default availabilityData;

// const availabilityData = {
// 	id: "11",
// 	working_days: {
// 	Monday: {
// 	working_hours: { start: "9:00", end: "17:00" },
// 	breaks: [
// 	{ start: "12:00", end: "13:00" },
// 	{ start: "16:00", end: "17:00" },
// 	],
// 	},
// 	Tuesday: {
// 	working_hours: { start: "9:00", end: "17:00" },
// 	breaks: [{ start: "12:00", end: "13:00" }],
// 	},
// 	Wednesday: {
// 	working_hours: { start: "9:00", end: "17:00" },
// 	breaks: [{ start: "12:00", end: "13:00" }],
// 	},
// 	Thursday: {
// 	working_hours: { start: "9:00", end: "17:00" },
// 	breaks: [{ start: "12:00", end: "13:00" }],
// 	},
// 	Friday: {
// 	working_hours: { start: "9:00", end: "17:00" },
// 	breaks: [{ start: "12:00", end: "13:00" }],
// 	},
// 	Saturday: {
// 	working_hours: {},
// 	breaks: [],
// 	},
// 	Sunday: {
// 	working_hours: {},
// 	breaks: [],
// 	},
// 	},
// 	blockedDays: [],
// 	};
