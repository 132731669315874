import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
const TermsAndConditionsModal = ({ handleClose, open, agreeAction }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: windowWidth > 432 ? 400 : "calc(100vw - 32px)",
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="focus:border-none"
    >
      <Box sx={style} className="rounded-lg overflow-hidden focus:outline-none">
        <Box className="h-[90vh] overflow-y-scroll p-8">
          <h1 className="text-2xl font-bold">Therapist Terms of Service</h1>
          <p className="text-base mt-4">Updated 11 Dec 2023</p>
          <h2 className="text-lg font-bold mt-6">Therapist Terms of Service</h2>
          <p className="text-base mt-4">
            Please read these Therapist Terms of Service (as well as the Client
            Terms and Conditions) carefully before you begin using the Sakina
            Platform Services as these will apply to your relationship with
            Sakina Platform. In using Sakina Platform Services you agree to
            these Terms.
          </p>

          <h2 className="text-lg font-bold mt-6">DEFINITIONS</h2>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Agreement&quot;</span> means this
            agreement which sets out the terms and conditions upon which Sakina
            Platform shall provide the Sakina Platform Services to the Therapist
            and which comes into effect on the Effective Date;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Terms and Conditions&quot;</span>{" "}
            means Sakina Platform’s terms and conditions in relation to the
            third party products or services offered on the Website;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Business Day&quot;</span> means a
            day other than a Saturday, Sunday or public holiday in England when
            banks in London are open for business;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Commission&quot;</span> means the
            commission (including VAT), as is in current force, payable by the
            Therapist to Sakina Platform (based on a percentage of the total
            value of a Session);
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Portal&quot;</span> means the
            &quot;Sakina Platform&quot; software licensed to the Therapist under
            this Agreement as one of the Sakina Platform Services
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Client&quot;</span> means any
            person who purchases or receives the Therapist Services;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">
              &quot;Client Terms and Conditions&quot;
            </span>{" "}
            means the terms and conditions of service and/or terms of business
            that the Therapist contracts with the Client;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">
              &quot;Data Protection Legislation&quot;
            </span>{" "}
            means the UK Data Protection Legislation and any other European
            Union legislation relating to personal data and all other
            legislation and regulatory requirements in force from time to time
            which apply to a party relating to the use of personal data
            (including without limitation the privacy of electronic
            communications) and the guidance and codes of practice issued by the
            relevant data protection or supervisory authority and applicable to
            a party.
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">
              &quot;UK Data Protection Legislation&quot;
            </span>{" "}
            means all applicable data protection and privacy legislation in
            force from time to time in the UK including the General Data
            Protection Regulation (EU) 2016/679; the Data Protection Act 2018
            the Privacy and Electronic Communications Directive 2002/58/EC as
            amended and the Privacy and Electronic Communications Regulations
            2003 as amended.
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Booking&quot;</span> means a time
            and date booked by a Client to receive Therapist Services;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Distribution Channels&quot;</span>{" "}
            means any third party website or other media through which the
            Sakina Platform Services are from time to time provided;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;DPA&quot;</span> means the Data
            Protection Act 2018 (as amended from time to time);
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Effective Date&quot;</span> means
            the date on which the Therapist ticks the box on the Sign up form to
            confirm acceptance of this Agreement;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Completed Session&quot;</span>{" "}
            means a Session in respect of which the Therapist has successfully
            provided the Therapist Services to the Client;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Guarantee&quot;</span> means a
            case where a Client is unsatisfied with their session, Sakina
            Platform will pay for one session for them to meet with an
            alternative Therapist.
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">
              &quot;Intellectual Property Rights&quot;
            </span>{" "}
            means all intellectual property rights on a world-wide basis whether
            currently in existence or otherwise and whether vested or contingent
            including (without limitation) copyright (including foreign language
            translation rights), design rights, database rights, rights in any
            domain names, registered designs, patents, trade marks, trade names,
            signs and other designations provided the foregoing are of a
            proprietary nature and all similar rights whether registered or
            otherwise (including, without limitation, all extensions,
            reversions, revivals and renewals thereof). The above shall include,
            in relation to registrable rights, any applications made or rights
            to make applications in respect of any such rights;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Material Breach&quot;</span> means
            a breach (including an anticipatory breach) which is not minimal or
            trivial in its consequences to Sakina Platform. In deciding whether
            any breach is material no regard shall be had to whether it occurs
            by some accident, mishap, mistake or misunderstanding;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Session&quot;</span> means any
            occurrence of an appointment booked by a Client or Therapist for the
            Client to receive any of the Therapist Services;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Page(s)&quot;</span> means the
            internet page or pages and contents of the Therapist’s designated
            section of the Website or Distribution Channels (including the
            Therapist’s Sakina Platform “homepage” and each page for the
            Therapist Services offered on the Website) along with any applicable
            page or pages and contents of the Therapist’s own website(s) or
            other website(s) where the Widget is used;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Therapist Services&quot;</span>{" "}
            means the therapy services which the Therapist is in the business of
            providing to Clients and which are marketed to Clients by the
            Therapist through use of the Sakina Platform Services;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Therapist Content&quot;</span>{" "}
            means any information, documentation, equipment, software,
            photographs or other material (which may include the Therapist name,
            logo and any other brand features and Intellectual Property Rights)
            which may be published on the Page(s) pursuant to this Agreement;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">
              &quot;Sakina Platform&quot; or “Sakina” or “Sakina Health”
            </span>{" "}
            means Sakina App Ltd, a company registered in England under company
            number 14498156 and whose registered office is at Flat 419, 20,
            Denison House Lanterns Way, London, United Kingdom, E14 9JJ;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">
              &quot;Sakina Platform Services&quot;
            </span>{" "}
            means the benefits and services a Therapist may receive from Sakina
            Platform in return for paying the Commission to Sakina Platform;
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Website&quot;</span> means the
            website at{" "}
            <Link href="https://www.sakina.io" target="_blank">
              www.sakina.io
            </Link>
            ,{" "}
            <Link href="https://www.sakina.health" target="_blank">
              www.sakina.health
            </Link>
          </p>
          <p className="text-base mt-4">
            <span className="font-bold">&quot;Working Hours&quot;</span> means
            9am - 5pm, Monday - Friday
          </p>

          <h2 className="text-lg font-bold mt-6">SAKINA PLATFORM SERVICES</h2>
          <p className="text-base mt-4">
            In consideration of payment by the Therapist of the Commission and
            the Therapist performing all its other obligations herein and
            subject to this Agreement, Sakina Platform shall provide the
            applicable Sakina Platform Services to the Therapist, which shall
            (unless otherwise agreed) include a personal, non-exclusive,
            non-transferable and fully revocable licence to use the Portal.
          </p>
          <p className="text-base mt-4">
            In respect of all sessions, Sakina Platform shall act and is hereby
            appointed as agent for the Therapist to conclude the Session with a
            Client and (where applicable) collect and process payments on behalf
            of the Therapist and nothing herein shall prevent or limit the
            Therapist from remaining fully responsible and liable for their
            provision and supply of Therapist Services to Clients.
          </p>
          <h2 className="text-lg font-bold mt-6">PORTAL SOFTWARE LICENCE</h2>
          <p className="text-base mt-4">
            The Therapist may use the Portal for the purpose of processing
            Sessions of Therapist Services for and on behalf of itself only. All
            Therapists are eligible to use the Portal.
          </p>
          <p className="text-base mt-4">
            The Therapist’s use of the Portal is at the Therapist’s sole risk.
            The service is provided on an “as is” and “as available” basis.
          </p>
          <p className="text-base mt-4">
            Technical support is provided by email primarily and is a benefit
            for the Therapist, but is not a right of the Therapist.
          </p>
          <p className="text-base mt-4">
            The Therapist understands and accepts that Sakina Platform uses
            third party vendors and hosting Therapists to provide the hardware,
            software, networking, storage, and related technology required to
            run the Portal. The Therapist also acknowledges and agrees that
            Sakina Platform shall have administrator’s access to all parts of
            the Portal, including those parts that have been specifically
            tailored for the Therapist.
          </p>
          <p className="text-base mt-4">
            The Therapist shall not reproduce, duplicate, copy, sell, resell or
            exploit the whole or any part of the Portal, or allow any third
            party (including group companies of the Therapist) to use or access
            the Portal without express prior written permission from Sakina
            Platform (which may be denied or granted on such terms as Sakina
            Platform in its sole discretion may determine).
          </p>
          <p className="text-base mt-4">Sakina Platform does not warrant:</p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              that the Portal will meet the Therapist’s specific requirements;
            </li>
            <li>
              that the Portal will be uninterrupted, timely, secure, or
              error-free;
            </li>
            <li>
              that any information or results that may be obtained from the use
              of the Portal will be accurate or reliable;
            </li>
            <li>
              that the quality of any products, services, information, or other
              material purchased or obtained by the Therapist through the Portal
              will meet the Therapist’s requirements or expectations; or
            </li>
            <li>that any errors in the Portal will be corrected.</li>
          </ul>
          <p className="text-base mt-4">
            The Therapist expressly understands and agrees that as regards use
            of the Portal Sakina Platform shall not be liable for any loss of
            income or profits, loss of contracts, loss of goodwill, loss of
            data, or other intangible losses or for any indirect or
            consequential loss or damage (even if Sakina Platform has been
            advised by the Therapist of the possibly of such loss or damage)
            resulting from:
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>the Therapist’s use of, or inability to use, the Portal;</li>
            <li>
              unauthorised access to or alteration of the Therapist’s
              transmissions or data;
            </li>
            <li>statements or conduct of any third party on the Portal; or</li>
            <li>any other matter relating to the Portal.</li>
          </ul>
          <h2 className="text-lg font-bold mt-6">
            Calendar Synchronisation with Third-Party Service Provider
          </h2>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li className="font-bold">
              Use of Third-Party Service Provider for Calendar Synchronisation
            </li>
          </ul>
          <p className="text-base mt-4">
            Sakina incorporates the services of a third-party service provider
            for calendar synchronization. This feature is intended to enhance
            the functionality of the Sakina Platform, allowing therapists to
            synchronise their personal calendars with the platform. This enables
            Sakina to view therapists&apos; availability and assist in managing
            bookings, such as adding, deleting, or rescheduling appointments
            made through the Sakina Platform.
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li className="font-bold">Limitations on Calendar Editing</li>
          </ul>
          <p className="text-base mt-4">
            While Sakina can manage bookings through its platform, it does not
            have the capability to alter events on therapists&apos; calendars
            that are not directly related to Sakina bookings. Therapists&apos;
            non-Sakina events remain private and are not affected by the
            synchronisation feature.
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li className="font-bold">
              Compliance with Google API Services User Data Policy
            </li>
          </ul>
          <p className="text-base mt-4">
            For Therapists using Google Calendar, Sakina&apos;s use and transfer
            of information received from Google APIs will adhere to the{" "}
            <Link
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
            >
              Google API Services User Data Policy
            </Link>
            , including the Limited Use requirements. This ensures that data
            from Google APIs is utilized in a manner consistent with
            Google&apos;s data protection and privacy policies.
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li className="font-bold">Data Security and Privacy</li>
          </ul>
          <p className="text-base mt-4">
            Sakina is committed to the highest standards of data security and
            privacy. All data synchronisation through the third-party service
            provider will be governed by stringent security measures to protect
            Therapists&apos; information and ensure confidentiality.
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li className="font-bold">Consent and Revocation</li>
          </ul>
          <p className="text-base mt-4">
            By agreeing to these terms, Therapists consent to the
            synchronisation of their calendars with Sakina via the third-party
            service provider. Therapists have the option to revoke this consent
            at any time. However, it is crucial to understand that revoking
            synchronisation will make their availability invisible to clients on
            the Sakina platform, thereby preventing the making or managing of
            bookings through Sakina.
          </p>
          <h2 className="text-lg font-bold mt-6">THERAPIST OBLIGATIONS</h2>
          <p className="text-base mt-4">
            In consideration of receiving the Sakina Platform Services, the
            Therapist agrees to pay all applicable Commission to Sakina Platform
            and to accept all Sessions and process and supply the Therapist
            Services to the highest professional standards and in line with any
            specific terms and conditions set out in this Agreement. A breach of
            this will be a Material Breach of this Agreement.
          </p>
          <p className="text-base mt-4">
            All Bookings will be accepted automatically without Therapists
            approval and will be added to the Platform as , by therapists
            indicating how many hours in advance clients can make booking.
          </p>
          <p className="text-base mt-4">
            In respect of all Bookings, the Therapist is obliged to comply with
            the cancellation and rescheduling policy set out in the Client’s
            Terms and Conditions. In summary:
          </p>
          <p className="text-base mt-4">
            Sakina Platform will offer a refund in respect of Bookings which are
            cancelled (or unable to be rescheduled) provided the relevant
            appointment is not due to take place within 48 hours of receipt by
            Sakina Platform of the cancellation.
          </p>
          <p className="text-base mt-4">
            If a Client wishes to change the date and/or time of a Session,
            provided the Client gives at least 48 hours prior to the time of the
            appointment, the Client can choose a suitable alternative Session
            time and/or date.
          </p>
          <p className="text-base mt-4">
            If a Therapist wishes to change the date and/or time of a Session,
            Sakina Platform will treat the Booking as cancelled by the Therapist
            and will refund the Client the full amount of the Booking. However,
            if Sakina Platform deems it reasonable to do so in the
            circumstances, and at Sakina Platform’s sole discretion, Sakina
            Platform may still require the Therapist to pay the applicable
            amount of Commission that would have been due from the Therapist in
            respect of that Booking.
          </p>
          <p className="text-base mt-4">
            The Therapist is responsible for ensuring that all Therapist Content
            (especially details of and fees for the Therapist Services) that it
            publishes or provides to Sakina Platform to publish on the Page(s)
            is accurate, correct and not misleading. The Therapist should only
            upload photographs to its Page(s) which the Therapist has a right to
            use. Any breach of this clause is a Material Breach of this
            Agreement.
          </p>
          <p className="text-base mt-4">
            The Therapist agrees that any content they publish, or provide to
            Sakina Platform for publication, will not include contact details or
            links to external websites. Clients are entitled to one free initial
            consultation, which the Therapist is obligated to conduct.
          </p>
          <p className="text-base mt-4">
            Sakina Platform reserve the right to suspend a therapist&apos;s
            account without notice if a Therapist has been asked to make changes
            to their Profile based on this Agreement but has not completed the
            changes requested within a reasonable timeframe.
          </p>
          <p className="text-base mt-4">
            The Therapist must at all times supply the Therapist Services on the
            Website at a fee which is the same or lower than that offered on the
            Therapist&apos;s own website or any other platforms where the
            Therapist offers their Services.
          </p>
          <p className="text-base mt-4">
            The Therapist agrees to charge the same fee for initial Sessions and
            follow-on Sessions unless there is an appropriate reason to charge a
            higher or lower fee, such as offering longer follow-on Sessions.
          </p>
          <p className="text-base mt-4">
            The Therapist agrees to not offer discounted or promotional rates
            for initial Sessions via the Sakina Platform to avoid any potential
            confusion regarding payments for follow-on Sessions.
          </p>
          <p className="text-base mt-4">
            The Therapist will adopt a standard set of Client Terms and
            Conditions. It is particularly drawn to Therapist’s attention that
            Clients, as a matter of law, will not be bound by any terms and
            conditions in respect of an Booking if those terms and conditions
            were not brought to the attention of the Client prior to their
            placing the Booking.
          </p>
          <p className="text-base mt-4">
            The Therapist is solely responsible for ensuring that the
            information on the Portal regarding time and date availability is
            kept completely up to date so that potential Clients are able to
            view the accurate time and date availability at the time of making a
            Booking.
          </p>
          <p className="text-base mt-4">
            Sakina Platform reserves the right to suspend a Therapist&apos;s
            account without notice if multiple initial or follow-on Sessions are
            cancelled or rearranged due to the Therapist&apos;s availability not
            being kept up to date; if multiple sessions are cancelled due to
            being unconfirmed by the therapist; if a therapist does not attend a
            session at the time agreed with a Client; if a Therapist does not
            ensure Clients are made aware of any need to cancel or reschedule
            Bookings at short notice due to unforeseen circumstances; or if a
            therapist is uncontactable or unresponsive to communications from
            Clients or the Platform.
          </p>
          <p className="text-base mt-4">
            If the Clients do not show up at the Session, if payment has been
            received, the Session will be treated by Sakina Platform as
            completed and Sakina Platform shall be entitled to receive
            Commission in respect of that Session.
          </p>
          <p className="text-base mt-4">
            The Therapist shall not solicit Clients derived from the Sakina
            Platform to make Bookings otherwise than through the Website.
          </p>
          <p className="text-base mt-4">
            Where a Client makes a Booking and the Therapist encourages that
            Client to cancel their Booking and make a separate Booking directly
            with the Therapist, the Therapist shall be in Material Breach of
            this Agreement.
          </p>
          <p className="text-base mt-4">
            If Sakina Platform has reasonable grounds to suspect that the
            Therapist has made or makes any direct or indirect attempt to avoid
            paying any Commission, for example without limitation, by
            fraudulently flagging a Session as a cancellation or non-chargeable,
            this shall be a Material Breach of this Agreement and shall give
            Sakina Platform the right, without limiting other remedies available
            to it, to withhold and retain any payments due to the Therapist
            under this Agreement.
          </p>

          <h2 className="text-lg font-bold mt-6">
            CLIENT SERVICE AND COMPLAINTS
          </h2>
          <p className="text-base mt-4">
            The Therapist shall use best endeavours to provide high quality
            Therapist Services to all Clients and shall at all times act in
            accordance with best practice established by all and any
            professional bodies of which the Therapist is a member or is
            affiliated with.
          </p>
          <p className="text-base mt-4">
            The Therapist will notify Sakina Platform if any investigations are
            initiated or complaints made against them or upheld by any
            professional bodies of which the Therapist is a member or is
            affiliated with. The Therapist will also be required to confirm this
            information on a yearly basis to ensure the information the Platform
            holds is still accurate.
          </p>
          <p className="text-base mt-4">
            The Therapist consents to have their performance measured and
            presented to prospective Clients (including but not limited to
            Client retention, Client reviews and responsiveness to new Bookings)
            for quality purposes.
          </p>
          <p className="text-base mt-4">
            Sakina Platform reserves the right to suspend a therapist&apos;s
            account without notice. Sakina will inform the Therapist via email
            or via their Dashboard if we take this action, and inform the
            Therapist of how to rectify the situation where appropriate,
            however, Sakina Platform does reserve the right to permanently
            remove a Therapist&apos;s profile if multiple instances of poor
            feedback are received, or if we cannot confidently guarantee a high
            level of quality in the services a Therapist offers to Clients.
          </p>
          <p className="text-base mt-4">
            The Therapist shall promptly deal with any enquiries, matters or
            issues relating to Sessions including dealing with Client
            complaints.
          </p>
          <p className="text-base mt-4">
            The Therapist shall be directly responsible to the Client for any
            failure to fulfil the Client’s expectations or for any other legal
            liability which arises in respect of the Therapist Services, except
            where such liability arises as a result of Sakina Platform’s
            negligence.
          </p>
          <p className="text-base mt-4">
            Sakina Platform shall refer any Client complaints it receives to the
            Therapist and the Therapist shall acknowledge all complaints, and
            shall respond to the relevant Client within 96 hours of the
            Therapist’s receipt of a complaint (whether the complaint has come
            directly from the Client or via Sakina Platform).
          </p>
          <p className="text-base mt-4">
            The Therapist shall make all efforts to reach a resolution to any
            complaints within 10 days and must notify Sakina Platform of any
            correspondence between the Therapist and the Client relating to the
            complaint and generally keep Sakina Platform apprised of its
            progress and the status of the complaint.
          </p>
          <p className="text-base mt-4">
            The Therapist acknowledges and accepts that the Website includes a
            reviewing platform, on which Clients may post publicly viewable
            reviews about their experiences with Sakina Platform and with the
            Therapist (particularly in relation to the Therapist Services)
            (“User Generated Content”). The Therapist should note that the
            review platform may not be opted out from and may from time to time
            contain negative reviews and/or feedback from Clients, which is
            outside Sakina Platform’s control. There may be an option for the
            Therapist, if they are the subject of any User Generated Content, to
            reply to reviews about them. However, any content the Therapist
            posts in response to User Generated Content must be polite and
            professional and non-threatening or confrontational, and it may be
            subject to review by Sakina Platform (and may be removed or amended
            in Sakina Platform’s sole discretion if Sakina Platform deems it
            reasonably necessary to do so). The Therapist shall have no right to
            any remedy (including without limitation, any right to terminate
            this Agreement) as a result of any User Generated Content naming or
            referring to the Therapist. However, if the Therapist, acting
            reasonably, feels that any User Generated Content is defamatory of
            the Therapist or any person or in some other way is a violation of
            any person’s legal rights, the Therapist may flag and report that
            User Generated Content to Sakina Platform. In such case, Sakina
            Platform shall review the same and in its sole discretion take any
            action it deems necessary or desirable (including, for example,
            removing or amending the relevant piece of User Generated Content).
            This is in addition to any legal rights the Therapist may have
            against the person or persons who created the User Generated
            Content.
          </p>
          <p className="text-base mt-4">
            Sakina Platform provides all clients with a complimentary 15-minute
            initial session, enabling them to determine if the therapist is a
            suitable match for their needs.
          </p>
          <h2 className="text-lg font-bold mt-6">CLIENT DATA</h2>
          <p className="text-base mt-4">
            For the purposes of this clause, “data controller”, “data
            processor”, “personal data”, “process” and “processing” shall have
            the meanings given to them in the DPA.
          </p>
          <p className="text-base mt-4">
            Sakina Platform and the Therapist acknowledge that, for the purposes
            of the DPA Sakina Platform is the data controller and the Therapist
            is the data processor of any Client personal data including special
            categories of personal data.
          </p>
          <p className="text-base mt-4">
            The Therapist may not use Client personal data collected through the
            Portal for any purpose other than fulfilment of the relevant
            Session.
          </p>
          <p className="text-base mt-4">
            The Therapist may collect Client personal data separately and
            directly by itself (for example, where Clients have booked Therapist
            Services otherwise than through use of the Sakina Platform Services
            and where the Therapist has separately obtained permission directly
            from the Client to use their data), in which case, in respect of
            that data, the Therapist shall be the data controller of that Client
            personal data for the purpose of the DPA.
          </p>
          <p className="text-base mt-4">
            When the Therapist is processing Client personal data as a data
            processor for Sakina Platform (e.g. personal data on the Portal) the
            Therapist shall:
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              process the personal data only in accordance with instructions
              from Sakina Platform (which may be specific instructions or
              instructions of a general nature);
            </li>
            <li>comply with all UK Data Protection Legislation;</li>
            <li>
              process the personal data only to the extent and in such manner as
              is necessary or as is required by law or by any regulatory body;
            </li>
            <li>
              promptly comply with any request from Sakina Platform requiring it
              to amend, transfer or delete the personal data;
            </li>
            <li>
              implement appropriate technical and organisational measures to
              protect the personal data against unauthorised or unlawful
              processing and against accidental loss, destruction, damage,
              alteration or disclosure;
            </li>
            <li>
              take all reasonable steps to ensure the reliability of its staff
              and agents who may have access to the personal data and ensure
              that such staff and agents (a) are informed of the confidential
              nature of the personal data; and (b) have undertaken training in
              the laws relating to handling personal data;
            </li>
            <li>
              not cause or permit the personal data to be published, disclosed
              or divulged, or transferred to a third party or to be transferred
              outside of the European Economic Area without the prior consent of
              Sakina Platform; and
            </li>
            <li>
              notify Sakina Platform within one (1) Business Days of any
              potential data breach or if it receives any complaint, notice or
              communication which relates directly or indirectly to the
              processing of the personal data or to either party&apos;s
              compliance with the DPA and the data protection principles set out
              therein, and it shall provide Sakina Platform with full
              cooperation and assistance in relation to any such complaint,
              notice or communication.
            </li>
          </ul>
          <h2 className="text-lg font-bold mt-6">
            THERAPIST WARRANTIES AND INDEMNITY
          </h2>
          <p className="text-base mt-4">
            The Therapist shall provide Sakina Platform with any Therapist
            Content it reasonably requires to be provided with in order to
            supply the Sakina Platform Services.
          </p>
          <p className="text-base mt-4">
            The Therapist warrants that all Therapist Content it supplies to
            Sakina Platform in connection with this Agreement and/or publishes
            (or provides to Sakina Platform for publication) on the Website will
            be accurate in all material respects and shall not infringe any
            other person’s rights (including Intellectual Property Rights) or be
            defamatory, unlawful, offensive, threatening, or pornographic or
            otherwise falling below general standards of taste and decency.
          </p>
          <p className="text-base mt-4">
            The Therapist hereby grants Sakina Platform the right:
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              to use and publish the Therapist Content in connection with the
              provision of Sakina Platform Services;
            </li>
            <li>
              to remove, edit, cut-down or otherwise amend Therapist Content
              published on any Pages, including without limitation where such
              Therapist Content does not, in Sakina Platform’s opinion comply
              with the warranties, or is otherwise in breach of the terms of
              this Agreement; and
            </li>
            <li>
              to make use of search engine optimisation services and other
              mechanisms that embody, incorporate or quote (in whole or part)
              the trading name of the Therapist or any brands used in connection
              with the Therapist Services.
            </li>
          </ul>
          <p className="text-base mt-4">
            The Therapist warrants, represents and undertakes that it shall
            comply with all applicable laws and advertising regulations in the
            marketing, sale and provision of the Therapist Services and shall
            obtain all licences, consents, authorities and insurance it is
            either necessary or reasonably prudent for the Therapist to obtain
            in respect of all its business activities and personnel (but
            especially in connection with the provision of Therapist Services).
          </p>
          <p className="text-base mt-4">
            Any breach of these warranties will be a Material Breach of this
            Agreement.
          </p>
          <p className="text-base mt-4">
            The Therapist hereby agrees to indemnify, keep indemnified and hold
            harmless Sakina Platform and its officers, directors and employees,
            from and against any and all claims, demands, obligations, actual or
            alleged causes of action and lawsuits and all damages, liabilities,
            fines, judgments, costs (including settlement costs), expenses
            associated therewith (including the payment of reasonable legal
            charges and disbursements) and losses (including but not limited to
            any direct, indirect or consequential losses, loss of profit, loss
            of reputation and all interest, penalties and legal costs
            (calculated on a full indemnity basis)) and all other reasonable
            professional costs and expenses arising out of or in connection with
            any breach by the Therapist of any term of this Agreement or arising
            out of any action brought by any third party relating to the
            Therapist Services provided (or not provided), or actions (or
            failure to act), of the Therapist or any person (other than Sakina
            Platform) acting on its behalf, including, without limitation any
            action brought in connection with any Data Protection Legislation,
            Therapist Content or a Client visit to the Therapist&apos;s venue.
          </p>
          <p className="text-base mt-4">
            The Therapist acknowledges that Sakina Platform enters into this
            Agreement for its own benefit but also as an agent for the benefit
            and on behalf of each of its officers, directors and employees (each
            an “Indemnified Third Party” and, collectively, the “Indemnified
            Third Parties”’) and that the rights in respect of indemnification
            set out shall be rights and benefits of each such Indemnified Third
            Party (as if, in each case, a party to this Agreement in its own
            right). Such rights shall be enforceable under this Agreement by
            Sakina Platform as agent for each such Indemnified Third Party.
            Notwithstanding the foregoing, the Therapist and Sakina Platform may
            agree in writing to amend any provision of this Agreement without
            the consent of any of the Indemnified Third Parties, even if that
            amendment affects or will affect the rights conferred on any
            Indemnified Third Party hereunder.
          </p>
          <p className="text-base mt-4">
            This Clause shall survive the termination or expiry of this
            Agreement.
          </p>
          <h2 className="text-lg font-bold mt-6">PAYMENT TERMS</h2>
          <p className="text-base mt-4">
            Sakina Platform has distinct payment structures based on client
            categories:
          </p>
          <ol className="list-decimal ml-5 pl-6 mt-4 text-base">
            <li>
              <span className="font-bold">Corporate Clients</span>: Sakina will
              charge £60 per session for corporate clients. The commission rate
              for Sakina Platform will be £6.00 per session, plus any applicable
              taxes. Full payment from companies may take between 7 to 30 days
              to be received, and efforts will be made to expedite this process
              as much as possible.
            </li>
            <li>
              <span className="font-bold">University Students</span>: For
              university students, the rate is £40 per session, which includes
              VAT or any equivalent tax. The commission rate for Sakina Platform
              will be £4.00 per session, plus any applicable taxes. These
              sessions must be prepaid by the students before booking.
            </li>
            <li>
              <span className="font-bold">General Access Sessions</span>: For
              general access clients, therapists must charge the standard rate
              as advertised on any other platforms, including their own
              websites. This rate should be equal to or less than the rate
              mentioned on these platforms. Sessions for general access clients
              require prepayment before bookings are confirmed.
            </li>
          </ol>
          <p className="text-base mt-4">
            This revised structure is intended to provide clarity and ensure
            fairness across different client groups, while also aligning with
            Sakina Platform&apos;s commitment to accessibility and professional
            service standards.
          </p>
          <p className="text-base mt-4">
            The Therapist will receive their income from Sessions within 5
            working days (after Sakina receives payment from its payment
            provider or a corporate client), or on the following Saturday,
            whichever is later.
          </p>
          <p className="text-base mt-4">
            These fees are subject to change, and any updates will be
            communicated with a 30-day notice period.
          </p>
          <p className="text-base mt-4">
            If the therapist has any concerns regarding any fees received or
            commission charged, the therapist should contact{" "}
            <Link href="mailto:team@sakina.io">team@sakina.io</Link>.
          </p>
          <h2 className="text-lg font-bold mt-6">TERM OF AGREEMENT</h2>
          <p className="text-base mt-4">
            This Agreement commences on the Effective Date and will continue in
            effect unless your account is terminated or the Therapist wishes to
            suspend or fully delete the profile.
          </p>
          <p className="text-base mt-4">
            Either party shall be entitled to terminate this Agreement with
            immediate effect by written notice to the other if:
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              the other party commits a Material Breach of any of the provisions
              of this Agreement and either that breach is not capable or, in the
              case of a breach capable of remedy, that party fails to remedy the
              same within 7 days after receipt of a written notice giving full
              particulars of the breach and requiring it to be remedied; or
            </li>
            <li>
              the other party is in persistent non-material breach (whether
              remediable or not) of any of the provisions of this Agreement;
            </li>
            <li>
              an encumbrancer takes possession or a receiver is appointed over
              any of the property or assets of that other party;
            </li>
            <li>
              that other party makes any voluntary arrangement with its
              creditors or becomes subject to an administration order;
            </li>
            <li>
              that other party goes into liquidation (except for the purposes of
              amalgamation or reconstruction and in such manner that the company
              resulting there from effectively agrees to be bound by or assume
              the obligations imposed on that other party under this Agreement);
            </li>
            <li>
              anything analogous to any of the foregoing under the law of any
              jurisdiction occurs in relation to that other party; or
            </li>
            <li>
              the other party ceases, or threatens to cease, to carry on
              business.
            </li>
          </ul>
          <p className="text-base mt-4">
            Where a party terminates this Agreement, such party shall have no
            liability to the other in respect of such termination. Termination
            of this Agreement, however arising, shall not affect any of the
            parties’ rights and remedies that have accrued as at termination.
          </p>
          <p className="text-base mt-4">
            If the Therapist wishes to remove themselves from the platform and
            there has not been a Material Breach, you the Therapist must
            request, in writing, for the profile to be either suspended or
            deleted. In this instance, work with any existing{" "}
            <Link href="https://sakina.io" target="_blank">
              sakina.io
            </Link>{" "}
            clients must be completed before fully deleting the Therapists
            account.
          </p>
          <h2 className="text-lg font-bold mt-6">CONFIDENTIALITY</h2>
          <p className="text-base mt-4">
            Each party acknowledges that, whether by virtue of and in the course
            of this Agreement or otherwise, it may receive or otherwise become
            aware of information relating to the other party, their marketing
            plans, their clients, Clients, businesses, business plans, finances,
            technology or affairs, which information is proprietary and
            confidential to the other party (“Confidential Information”).
          </p>
          <p className="text-base mt-4">
            Each party undertakes to maintain and procure the maintenance of the
            confidentiality of Confidential Information at all times and to keep
            and procure the keeping of all Confidential Information secure and
            protected against theft, damage, loss or unauthorised access, and
            not at any time, whether during the term of this Agreement or at any
            time thereafter, without the prior written consent of the owner of
            the Confidential Information, directly or indirectly, use, disclose,
            exploit, copy or modify any Confidential Information, or authorise
            or permit any third party to do the same, other than for the sole
            purpose of the performance of its rights and obligations hereunder.
          </p>
          <p className="text-base mt-4">
            The terms of and obligations imposed by this clause shall not apply
            to any Confidential Information which:
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              at the time of receipt by the recipient is in the public domain;
            </li>
            <li>
              subsequently comes into the public domain through no fault of the
              recipient, its officers, employees or agents;
            </li>
            <li>
              is lawfully received by the recipient from a third party on an
              unrestricted basis; or
            </li>
            <li>is already known to the recipient before receipt hereunder.</li>
          </ul>
          <p className="text-base mt-4">
            The recipient may disclose Confidential Information in confidence to
            a professional adviser of the recipient or if it is required to do
            so by law, regulation or order of a competent authority.
          </p>
          <p className="text-base mt-4">
            This Clause shall survive the termination or expiry of this
            Agreement.
          </p>
          <h2 className="text-lg font-bold mt-6">LIABILITY</h2>
          <p className="text-base mt-4">
            Sakina Platform’s maximum aggregate liability under or in connection
            with this Agreement, or any collateral contract, whether in
            contract, tort (including negligence) or otherwise, shall in no
            circumstances exceed the Commission due and payable to Sakina
            Platform hereunder on the date of the event giving rise to the
            relevant claim. Further, Sakina Platform shall not be liable for any
            loss of income or profits, loss of contracts, goodwill, use or data,
            or other intangible losses or for any indirect or consequential loss
            or damage of any kind howsoever arising and whether caused by tort
            (including negligence), breach of contract or otherwise (even if
            Sakina Platform has been advised by the Therapist of the possibility
            of such loss or damage).
          </p>
          <p className="text-base mt-4">
            Nothing in this Agreement shall exclude or in any way limit Sakina
            Platform’s liability for fraud or for death or personal injury
            caused by its negligence or for its wilful default or any other
            liability to the extent the same may not be excluded or limited as a
            matter of law.
          </p>
          <p className="text-base mt-4">
            This Clause shall survive the termination or expiry of this
            Agreement.
          </p>
          <h2 className="text-lg font-bold mt-6">MISCELLANEOUS</h2>
          <p className="text-base mt-4">
            All rights to the Website and the content on it (save for Therapist
            Content) (and all other Intellectual Property Rights belong to or
            licensed to Sakina Platform) remain vested in Sakina Platform at all
            times. Nothing in this Agreement shall give the Therapist any rights
            in respect of any such Intellectual Property Rights or of the
            goodwill associated therewith. In order to streamline the Website
            and the content on it (including the Therapist Content), Sakina
            Platform may, at its absolute discretion and from time to time,
            amend the format, content and style of venue page descriptions,
            photos and menus.
          </p>
          <p className="text-base mt-4">
            In the event of a change of control or senior management of the
            Therapist, the Therapist must bring the existence and terms of this
            Agreement to the new owner or manager’s attention and inform Sakina
            Platform of the relevant new personnel’s contact details.
          </p>
          <p className="text-base mt-4">
            Any notice, invoice or other communication which either party is
            required to serve on the other party shall be sufficiently served if
            sent to the other party at the address specified in this Agreement
            (or such other address as is notified to the other party in writing
            or by email). Notices sent by registered post or recorded delivery
            shall be deemed to be served three Business Days following the day
            of posting. In all other cases, notices are deemed to be served on
            the day when they are actually received.
          </p>
          <p className="text-base mt-4">
            We have the right to revise and amend these terms and conditions
            from time to time to reflect changes in market conditions affecting
            our business, changes in technology, changes in payment methods,
            changes in relevant laws and regulatory requirements and changes in
            our system&apos;s capabilities, so please review our terms
            regularly.
          </p>
          <p className="text-base mt-4">
            The relationship of the parties is that of independent contractors
            dealing at arm’s length. Except as otherwise stated in this
            Agreement, nothing in this Agreement shall constitute the parties as
            partners, joint venturers or co-owners.
          </p>
          <p className="text-base mt-4">
            Neither party may assign, transfer, charge, sub-contract or
            otherwise deal with any part or all of this Agreement without the
            prior written consent of the other party (not to be unreasonably
            withheld, conditioned or delayed).
          </p>
          <p className="text-base mt-4">
            A person who is not a party to this Agreement has no right to
            enforce any term of this Agreement.
          </p>
          <p className="text-base mt-4">
            The failure of either party to enforce or to exercise at any time or
            for any period of time any term of or any right pursuant to this
            Agreement does not constitute, and shall not be construed as, a
            waiver of such term or right and shall in no way affect that party’s
            right later to enforce or to exercise it.
          </p>
          <p className="text-base mt-4">
            If any term of this Agreement is found to be illegal, invalid or
            unenforceable under any applicable law, such term shall, insofar as
            it is severable from the remaining terms, be deemed omitted from
            this Agreement and shall in no way affect the legality, validity or
            enforceability of the remaining terms.
          </p>
          <p className="text-base mt-4">
            This Agreement contains all the terms agreed between the parties
            regarding its subject matter and supersedes any prior agreement,
            understanding or arrangement between the parties, whether oral or in
            writing.
          </p>
          <p className="text-base mt-4">
            This Agreement shall be governed and interpreted in accordance with
            the laws of England and Wales. The parties submit to the exclusive
            jurisdiction of the English courts to settle any dispute arising out
            of or in connection with this Agreement.
          </p>
          <p className="text-base mt-4">
            Sakina App Ltd | Flat 419, 20, Denison House Lanterns Way, London,
            United Kingdom, E14 9JJ
          </p>
          <p className="text-base mt-4">Company number: 14498156</p>
          <Box className="w-full flex justify-center mt-8">
            <Button variant="contained" onClick={agreeAction}>
              Agree
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TermsAndConditionsModal;
