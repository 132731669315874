import { Box, Button } from "@mui/material";
import React from "react";
import DayPicker from "../../../../User/Components/DayPicker";
import BookingDayPicker from "./BookingDayPicker";

function BookingSide({ therapistInfo }) {
  const [open, setOpen] = React.useState(false);
  const isDesktop = window.innerWidth >= 768;
  return (
    <>
      <div
        className="md:min-h-[686px] py-5 px-2.5 flex-1 flex justify-between items-center flex-col bg-white md:m-[5px] m-auto mt-4 self-stretch"
        style={{
          boxShadow:
            "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px",
          borderRadius: "23px",
          color: "grey",
          width: !isDesktop ? "calc(100vw - 32px)" : null,
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "98%",
          }}
        >
          <h3
            style={{
              fontSize: "17px",
              fontWeight: 600,
              color: "rgb(50, 51, 49)",
            }}
          >
            Pick your session
          </h3>
          <BookingDayPicker
            therapistInfo={therapistInfo}
            open={open}
            setOpen={setOpen}
          />
        </Box>
      </div>
    </>
  );
}

export default BookingSide;
