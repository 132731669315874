import React from "react";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
// import logo from "../../../../assets/images/sakina-blue.png";

const OnboardingLayout = () => {
	return (
		<div>
			<Box sx={{ background: "#F3F4F6", width: "100%", height: "6rem" }}>
				<Container style={{ height: "100%" }}>
					<Box
						sx={{
							pt: 3,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						{/* <img src={logo} alt="Sakina Logo" className="w-32" /> */}
						<div className="align-middle">
							{/* <h3 className="font-bold text-lg"> Welcome to Sakina! 🎉</h3> */}
						</div>
					</Box>
				</Container>
			</Box>
			<Outlet />
		</div>
	);
};

export default OnboardingLayout;