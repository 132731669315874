import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

export default function CustomDatePicker({ timeslots, setTime }) {
  const [selectedHour, setSelectedHour] = useState<string | null>("");
  const [selectedMinute, setSelectedMinute] = useState<string | null>("");

  const extractHour = (time) => dayjs(time).hour().toString().padStart(2, "0");
  const extractMinute = (time) => dayjs(time).minute().toString().padStart(2, "0");

  const hours = Array.from(
    new Set(timeslots.map((slot) => extractHour(slot.start_time)))
  );
  
  const hoursToFull = (minute) => {
    return timeslots.filter(
      (slot) =>
        extractHour(slot.start_time) === selectedHour &&
        extractMinute(slot.start_time) === minute
    )![0].start_time;
  };

  const getMinutesForHour = (hour) => {
    return timeslots
      .filter((slot) => extractHour(slot.start_time) === hour)
      .map((slot) => extractMinute(slot.start_time));
  };

  const handleHourChange = (event) => {
    setSelectedHour(event.target.value);
    setTime("");
    setSelectedMinute(""); // Reset minute selection
  };

  const handleMinuteChange = (event) => {
    setTime(hoursToFull(event.target.value));
    setSelectedMinute(event.target.value);
  };

  return (
    <div className="flex space-x-4">
      <FormControl variant="standard" className="w-32">
        <InputLabel id="hour-label">Hour</InputLabel>
        <Select
          labelId="hour-label"
          value={selectedHour}
          onChange={handleHourChange}
        >
          {hours
            ? hours.map((hour: string, index) => (
                <MenuItem key={index} value={hour}>
                  {hour}
                </MenuItem>
              ))
            : null}
        </Select>
      </FormControl>

      <FormControl variant="standard" className="w-32">
        <InputLabel id="minute-label">Minute</InputLabel>
        <Select
          labelId="minute-label"
          value={selectedMinute}
          onChange={handleMinuteChange}
          disabled={selectedHour === null}
        >
          {selectedHour &&
            getMinutesForHour(selectedHour).map((minute) => (
              <MenuItem key={minute} value={minute}>
                {minute}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
