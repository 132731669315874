//@ts-nocheck
//@ts-ignore
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import InputValidated from "../Components/InputValidated";
import {
  TextField,
  createTheme,
  ThemeProvider,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material/";
import Checkbox from "../Components/Checkbox";
import TextAreaValidated from "../Components/TextAreaValidated";
import { useNavigate } from "react-router-dom";
import { useTherapistInfo, useStoreUser } from "../../../../Zustand";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import ApiRequests from "../../../api/ApiRequests";
import dayjs from "dayjs";
import Toast from "../../helpers/Toast";
import TagName from "../Components/Onboarding/TagName";
import { useQuery } from "@tanstack/react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import WorkingDays from "../Components/WorkingDays";
import { isEmpty } from "lodash";
export default function Onboarding({ hidden, prevPage, nextPage, text }) {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();

  const { getLanguageList, getTherapistInfo } = ApiRequests();

  const { info, setInfo } = useTherapistInfo();
  const [selectedLanguages, setSelectedLanguages] = useState(setSelectedLang());
  const [languages, setLanguages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("Please fill all fields");
  const [sakinaTagError, setSakinaTagError] = useState(false);
  const { data: langData, isFetched } = useQuery({
    queryKey: ["languages-data"],
    queryFn: () => getLanguageList(),
  });
  const dayHours = Array.from({ length: 25 });
  const [days, setDays] = useState(getDate("day"));
  const [hours, setHours] = useState(getDate("hour"));

  const handleDaysChange = (event) => {
    setDays(event.target.value);
  };

  const handleHoursChange = (event) => {
    setHours(event.target.value);
  };

  const fillStatesAdvanceBookingHours = (hours) => {
    let days = Math.floor(hours/24);
    let newHours = hours - days * 24;
    setHours(newHours);
    setDays(days);
  }

  function getDate(type: string) {
    if (info?.minimum_advance_booking_hours) {
      switch (type) {
        case "day": {
          return Math.floor(info.minimum_advance_booking_hours/24);
        }
        default: {
          return info.minimum_advance_booking_hours%24;
        }
      }
    }
    return 0;
  }

  function setSelectedLang() {
    if (info?.languages_id) {
      return info.languages_id
    }
    return [];
  }

  useEffect(() => {
	  setInfo({minimum_advance_booking_hours: (Number(days) * 24 + Number(hours))})
  }, [days, hours]);

  useEffect(() => {
    if (isFetched) {
      setLanguages(langData.data.languages);
    }
  }, [isFetched]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTherapistInfo();
        const {
          name,
          surname,
          gender,
          phone_number,
          date_of_birth,
          address: { city, country_code },
          full_years_of_experience,
          total_therapy_session_count,
          title,
          about_me,
          higher_education,
          professional_achievements,
          memberships,
          session_cost: {
            individual_cost,
            individual_cost_duration_minutes,
            couple_cost,
            couple_cost_duration_minutes,
            currency,
          },
          bank_account: { account_holder_name, account_number, sort_code },
          languages: selectedLanguages,
          sakina_tag,
          minimum_advance_booking_hours,
          working_schedules,
        } = response.data.therapist;
        if (selectedLanguages?.length) {
          const arr = [];
          selectedLanguages.forEach((element) => {
            arr.push(element.id);
          });
          setSelectedLanguages((prev) => [...prev, ...arr]);
        }
        if (minimum_advance_booking_hours) {
          fillStatesAdvanceBookingHours(minimum_advance_booking_hours);	
        }
        setInfo({
          ...info,
          name,
          surname,
          gender,
          phone_number,
          date_of_birth: dayjs(date_of_birth).format("YYYY-MM-DD"),
          city,
          country_code,
          full_years_of_practice: full_years_of_experience,
          total_therapy_session_count,
          title,
          about_me,
          higher_education,
          professional_achievements,
          memberships,
          session_cost: {
            individual_cost,
            individual_cost_duration_minutes,
            couple_cost,
            couple_cost_duration_minutes,
            currency,
          },
          bank_account: { account_holder_name, account_number, sort_code },
          sakina_tag,
          minimum_advance_booking_hours,
          working_schedules,
        });
        // You can set the response data to the state or perform any other necessary actions here
      } catch (error) {
        // Handle the error if necessary
        console.log("Error:", error.message);
      }
    };
    fetchData(); // Call the async function
  }, []);
  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            // Your custom styles here
            backgroundColor: "rgb(249 250 251)",
            // ... other styles
          },
        },
      },
    },
  });

  useEffect(() => {
    setInfo({ languages_id: selectedLanguages });
  }, [selectedLanguages]);

  // Change the input values
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log({ name, value });

    if (name === "languages_id") {
      setSelectedLanguages((prevSelectedLanguages) => {
        if (prevSelectedLanguages.includes(value)) {
          // If the language is already selected, remove it from the list
          return prevSelectedLanguages.filter((language) => language !== value);
        } else {
          // If the language is not selected, add it to the list
          return [...prevSelectedLanguages, value];
        }
      });
      // since selectedLanguages state hasn't been set yet, we call setInfo in useEffect
    } else {
      let newValue = name === "gender" ? Number(value) : value;
      if (
        name === "full_years_of_practice" ||
        name === "total_therapy_session_count"
      ) {
        if (value) {
          console.log(value, Number(value), isNaN(Number(value)));

          if (isNaN(Number(value)) || Number(value) < 0) {
            newValue = 0;
          } else {
            newValue = Number(value);
          }
        }
      }
      setInfo({ [name]: newValue });
    }
  };
  const setSakinaTag = (value, hasError) => {
    setSakinaTagError(hasError);
    setInfo({ sakina_tag: value });
  };
  const formOnSubmit = (e) => {
    e.preventDefault();
    // For now it is hardcoded
    // Later this page should be altered more dynamically
    console.log(sakinaTagError);
    if (sakinaTagError) {
      setMessage(
        "There's an error in sakina tag, if you want you can leave it blank"
      );
      setShowToast(true);
      return;
    }
    if (
      !info.name ||
      !info.surname ||
      !info.gender ||
      !info.phone_number ||
      !info.date_of_birth ||
      !info.city ||
      (info.full_years_of_practice != 0 && !info.full_years_of_practice) ||
      (info.total_therapy_session_count != 0 &&
        !info.total_therapy_session_count) ||
      !info.title ||
      !info.languages_id?.length ||
      !info.about_me ||
      !info.memberships ||
      isEmpty(info.working_schedules)
    ) {
      setMessage("Please fill all fields");
      setShowToast(true);
      return;
    }
    navigate(nextPage);
  };
  console.log("SETINFO: ", info);

  return (
    <div style={{ overflow: "auto", padding: "0 30px 30px" }}>
      <Toast
        message={message}
        show={showToast}
        onClose={() => setShowToast(false)}
        background="#dc3545"
      />
      <div
        className={`m-auto max-w-lg  mt-12 text-center text-stone-700 ${
          hidden && ""
        }`}
      >
        <h4 className={`m-auto max-w-lg text-4xl mb-3 font-semibold `}>
          Let's get you started!
        </h4>
        <p>
          Please feel in the details to create your profile and start having
          clients{" "}
        </p>
        <p>
          If you have any questions please contact us as{" "}
          <span className="text-blue-800">therapist@sakina.io</span> or
          call/WhatsApp us at{" "}
          <span className="text-blue-800">+447884375315</span>{" "}
        </p>
      </div>
      {/* FIXME: Check why errors do not appear and try to fix */}
      <form
        onSubmit={formOnSubmit}
        className="w-full max-w-lg mt-12 flex flex-col mx-auto"
      >
        <div className="flex  space-x-3 mt-3 ">
          <div className="w-full md:w-1/2">
            <InputValidated
              id="name"
              name="name"
              label="Name*"
              type="text"
              placeholder="Mariam"
              register={register("name", { required: true, maxLength: 30 })}
              error={errors.name}
              maxLength="30"
              onChange={handleInputChange}
              value={info?.name}
            />
          </div>
          <div className="w-full md:w-1/2">
            <InputValidated
              id="surname"
              name="surname"
              label="surname*"
              type="text"
              placeholder="Muhammad"
              register={register("surname", { required: true, maxLength: 30 })}
              error={errors.surname}
              maxLength="30"
              onChange={handleInputChange}
              value={info?.surname}
            />
          </div>
        </div>
        <TagName
          name={info?.name}
          surname={info?.surname}
          sakinaTg={info?.sakina_tag}
          setSakinaTag={setSakinaTag}
        />
        <FormControl className="!mb-4 !mt-2">
          <FormLabel
            id="gender"
            sx={{
              font: "inherit",
              fontSize: "0.75rem",
              color: "inherit",
              fontWeight: "700",
            }}
          >
            GENDER*
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender"
            name="gender"
            id="gender"
            value={info?.gender}
            onChange={handleInputChange}
          >
            <FormControlLabel value="2" control={<Radio />} label="Female" />
            <FormControlLabel value="1" control={<Radio />} label="Male" />
          </RadioGroup>
        </FormControl>
        <InputValidated
          id="phone_number"
          name="phone_number"
          type="tel"
          label="phone number*"
          placeholder="Enter with country code"
          register={register("phone_number", { required: true, maxLength: 30 })}
          error={errors.phone_number}
          maxLength="30"
          onChange={handleInputChange}
          value={info.phone_number}
        />
        <ThemeProvider theme={theme}>
          <Typography
            variant="p"
            className="!mb-2 !text-xs !font-bold !uppercase !text-[#374151]"
          >
            Date of Birth*
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label=""
              name="date_of_birth"
              id="date_of_birth"
              value={info.date_of_birth}
              inputFormat="DD.MM.YYYY"
              placeholder=""
              maxDate={dayjs()}
              onChange={(newValue: DayJs | null) => {
                const formattedDate = newValue
                  ? dayjs(newValue).format("YYYY-MM-DD")
                  : "";
                const event = {
                  target: {
                    name: "date_of_birth",
                    value: formattedDate,
                  },
                };
                handleInputChange(event);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.date_of_birth}
                  helperText={
                    errors.date_of_birth ? "This field is required." : ""
                  }
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "4px",
                      borderColor: "rgb(229 231 235)",
                    },
                    "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "0.8px solid #000",
                      },
                    "& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #e5e7eb",
                      },
                    "& .MuiInputBase-input": {
                      color: "rgb(55 65 81)",
                      fontSize: "14px",
                      paddingBottom: "12px",
                      paddingTop: "12px",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "none", // Normal state border
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none", // Focused state border
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none", // Hover state border
                    },
                    "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                      // Adjust the icon styles if necessary
                    },
                  }}
                />
              )}
              sx={{}}
            />
          </LocalizationProvider>
        </ThemeProvider>
        {/* <InputValidated
					id="date_of_birth"
					name="date_of_birth"
					type="date"
					label="Date of Birth*"
					placeholder=""
					register={register("date_of_birth", {
						required: true,
						maxLength: 30,
					})}
					maxLength="30"
					error={errors.date_of_birth}
					onChange={handleInputChange}
					value={info.date_of_birth}
				/> */}

        <div className="flex  space-x-3 mt-3 ">
          <div className="w-full md:w-1/2">
            <InputValidated
              id="city"
              name="city"
              type="text"
              label="City*"
              placeholder="London"
              register={register("city", { required: true, maxLength: 30 })}
              error={errors.city}
              maxLength="30"
              onChange={handleInputChange}
              value={info.city}
            />
          </div>
          <div className="w-full md:w-1/2 ">
            <label className="input-label" htmlFor="country_code">
              Country*
            </label>
            <div className="relative">
              <select
                className="input"
                id="country_code"
                name="country_code"
                onChange={handleInputChange}
              >
                <option>GB</option>
                <option>US</option>
                <option>DE</option>
                <option>AE</option>
                <option>PK</option>
                <option>NO</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <InputValidated
          id="full_years_of_practice"
          type="number"
          name="full_years_of_practice"
          label="Full Years of Practice*"
          placeholder="Enter the number of years of active practice"
          register={register("full_years_of_practice", {
            required: true,
            maxLength: 30,
          })}
          maxLength="30"
          error={errors.full_years_of_practice}
          onChange={handleInputChange}
          value={info.full_years_of_practice}
        />
        <InputValidated
          id="total_therapy_session_count"
          type="number"
          name="total_therapy_session_count"
          label="Number of sessions had throughout professional carreer*"
          placeholder="Enter the number of sessions had throughout professional carreer"
          register={register("total_therapy_session_count", {
            required: true,
            min: {
              value: 1,
              message:
                "The value entered does not meet the minimum requirements",
            },
          })}
          error={errors.total_therapy_session_count}
          onChange={handleInputChange}
          value={info.total_therapy_session_count}
        />
        <p className="text-gray-600 text-xs italic mb-6 -mt-4">
          It can be to the nearest 100 i.e. 200, 1500, 5000
        </p>

        <div className="w-full mb-3">
          <InputValidated
            id="title"
            name="title"
            type="text"
            label="title"
            placeholder="Psychologist, Psychotherapist, Councelor, Life Coach"
            register={register("title", { required: true, maxLength: 30 })}
            error={errors.title}
            maxLength="200"
            onChange={handleInputChange}
            value={info.title}
          />
        </div>

        <div className=" w-full mb-3">
          <label
            className="input-label__secondary"
            htmlFor="languages_id"
            style={{ fontSize: "0.75rem", color: "inherit", fontWeight: "700" }}
          >
            LANGUAGES YOU HOLD SESSIONS IN*
          </label>
          <div className="grid grid-cols-3">
            {isFetched && languages
              ? languages.map((language) => (
                  <Checkbox
                    item={language.id}
                    key={language.id}
                    name="languages_id"
                    languageName={language.name}
                    onChange={handleInputChange}
                    checked={
                      selectedLanguages.includes(language.id) ||
                      info.languages_id.includes(language.id)
                    }
                  />
                ))
              : null}
          </div>
        </div>

        <TextAreaValidated
          id="about_me"
          name="about_me"
          label="About Me*"
          placeholder=""
          value={info.about_me}
          onChange={handleInputChange}
          register={register("about_me", { required: true })}
          error={errors.about_me}
          maxLength={10000}
          watch={watch}
        />
        <TextAreaValidated
          label="Higher education*"
          id="higher_education"
          name="higher_education"
          placeholder=""
          value={info.higher_education}
          onChange={handleInputChange}
          register={register("higher_education", { required: true })}
          error={errors.higher_education}
          maxLength={10000}
          watch={watch}
        />
        <TextAreaValidated
          label="Professional qualification and courses*"
          id="professional_achievements"
          name="professional_achievements"
          placeholder=""
          value={info.professional_achievements}
          onChange={handleInputChange}
          register={register("professional_achievements", { required: true })}
          error={errors.professional_achievements}
          maxLength={10000}
          watch={watch}
        />

        <TextAreaValidated
          label="Memberships*"
          id="memberships"
          name="memberships"
          placeholder=""
          value={info.memberships}
          onChange={handleInputChange}
          // register={register("memberships", { required: true })}
          // error={errors.memberships}
          maxLength={10000}
          watch={watch}
        />
        {/* <InputValidated
					id="minAdvanceBookTime"
					type="number"
					name="full_years_of_practice"
					label="Full Years of Practice*"
					placeholder="Enter the number of years of active practice"
					register={register("full_years_of_practice", {
						required: true,
						maxLength: 30,
					})}
					maxLength="30"
					error={errors.full_years_of_practice}
					onChange={handleInputChange}
					value={
						info.full_years_of_practice
					}
				/> */}
        <Typography
          variant="p"
          className="!mb-2 !text-xs !font-bold uppercase text-[rgb(55,65,81)]"
        >
          Advance book time
        </Typography>
        <div className="flex space-x-4">
          <FormControl variant="standard" className="w-32">
            <Typography className="!relative !text-xs">Days</Typography>
            <InputValidated
              labelId="days-label"
              type="number"
              inputProps={{ min: 0 }}
              value={days}
              onChange={handleDaysChange}
            />
          </FormControl>
          <FormControl variant="standard" className="w-32 flex gap-2">
            <Typography className="!relative !text-xs">Hours</Typography>
            <Select
              value={hours}
              labelId="hours-label"
              className="h-[43.1px]"
              onChange={handleHoursChange}
            >
				{
					dayHours.map((_,index) => {
						return <MenuItem value={index}>{index}</MenuItem>
					})
				}
            </Select>
          </FormControl>
        </div>

        <Typography
          variant="p"
          className="mb-0 !text-xs !font-bold uppercase text-[rgb(55,65,81)]"
        >
          Working hours* <i className="normal-case">(In your device's local time)</i>
        </Typography>
        <WorkingDays info={info} setInfo={setInfo} />
        {/* <h3 className="input-label">Supporting Documents*</h3>
				<UploadFiles
					text="Please attach supporting documents"
					fileTypes={{ "file/*": [".pdf", ".jpeg", ".jpg", ".png"] }}
					myFiles={myFiles}
					setMyFiles={setMyFiles}
				/> */}
        {/* <Link to={nextPage}> */}
        <button className="btn-primary mt-3" type="submit">
          {text}
        </button>
        {/* </Link> */}
      </form>
    </div>
  );
}