const images = {
	logo: require("../images/sakina-blue.png").default,
	uploadIc: require("../images/upload-file.svg").default,
	closeIc: require("../images/close-button.svg").default,
	viewIc: require("../images/view-icon.png").default,
	workingHours1: require("../images/CalendarAvailability/WorkingHours/Screenshot 1. Working Hours.png"),
	workingHours2: require("../images/CalendarAvailability/WorkingHours/Screenshot 2. Working Hours.png"),
	workingHours3: require("../images/CalendarAvailability/WorkingHours/Screenshot 3. Working Hours.png"),
	workingHours4: require("../images/CalendarAvailability/WorkingHours/Screenshot 4. Working Hours.png"),
	lunchTimes: require("../images/CalendarAvailability/WorkingHours/Screenshot 5. Lunch Times.png"),
	holiday1: require("../images/CalendarAvailability/WorkingHours/Screenshot 6. Holiday.png"),
	holiday2: require("../images/CalendarAvailability/WorkingHours/Screenshot 7. Holiday.png"),
}

export default images
