import { Box, Button } from "@mui/material"
import axios from "axios"
import { useStoreUser, useTokenStore } from "../../../../Zustand"
import { createAxios } from "../../helpers/createAxios"
import { useNavigate } from "react-router-dom"
import SyncIcon from '@mui/icons-material/Sync';
function SyncCalendar() {
	const { userInfo, setUserInfo } = useStoreUser()
	const { token } = useTokenStore()
	console.log("email", userInfo)
	const Axios = createAxios(token?.access_token)
	const navigate = useNavigate();
	async function SyncCalendarButton() {
		try {
			const data = await Axios.get(`/therapist`)
			const response = await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/calendar/auth-url`,
				{
					email: data.data.therapist.email,
				},
				{
					headers: {
						"Content-Type": "application/json",
						accept: "application/json",
						authorization: "Bearer " + token.access_token,
					},
				}
			)

			window.open(response.data.url)
			
			// console.log("SyncCalendar signin response.data :>> ", response.data.url)
		} catch (error) {
			// console.log("SyncCalendar ::>>", error)
		}
	}

	return (
		<Button
			variant="contained"
			className="!text-xs mb-2.5 none"
			onClick={SyncCalendarButton}
		>
			<SyncIcon className="mr-2"/>
			Sync Calendar
		</Button>
	)
}

export default SyncCalendar
