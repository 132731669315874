import { Box, Grid } from "@mui/material"
import SideMenuMobile from "../../helpers/sideMenuMobile";
function Navbar(props) {
	const defaultTitle = ""
	const {title = defaultTitle} = props;
	return (
		<Grid
			item
			xs={12}
			className={`text-white md:!hidden md:px-2.5 p-4 pb-0 rounded-[5px] w-full items-center mb-[14px] !flex justify-between`}
		>	
			<Box className="w-full md:w-auto flex items-center gap-2">
				<SideMenuMobile />
				<h3 className="text-center flex-1 md:flex-none md:text-[25px] text-[22px] text-[#32325d]">
					{title}
				</h3>
			</Box>
		</Grid>
	)
}

export default Navbar
