import { useEffect, useState } from "react";
import ApiRequests from "../../../../api/ApiRequests";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";

const TagName = ({ name, surname, sakinaTg, setSakinaTag }) => {
  const { getTagNames } = ApiRequests();
  
  const checkTag = (value) => {
    return value && value.toString().indexOf("@") > -1 ? value.toString().substring(1) : value;
  };
  const [inputValue, setInputValue] = useState("" || checkTag(sakinaTg));
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastFetched, setLastFetched] = useState({ name: "", surname: "" });
  const regex = /^[a-z0-9_-]+$/;
  const handleFocus = async () => {
    // Trigger the API call when the input is focused
    if ((name !== lastFetched.name || surname !== lastFetched.surname) && name !== '' && surname !== '') {
      const fetchedOptions = await fetchOptions();
      setOptions(fetchedOptions);
      setLastFetched({ name, surname }); // Update last fetched state
    }
    setShowOptions(true); // Show the options
  };

  const handleBlur = (e) => {
    let hasError = false;
    if (e.target.value && !e.target.value.match(regex)) hasError = true;
    setSakinaTag(`@${inputValue}`, hasError);
    setShowOptions(false);
  };
  useEffect(() => {
    if (inputValue && !inputValue.match(regex)) {
      setErrorMessage(
        "Please ensure your input contains only lowercase letters, numbers, underscores, or hyphens with no spaces."
      );
    } else {
      setErrorMessage("");
    }
  }, [inputValue]);
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (option) => {
    setInputValue(checkTag(option)); // Set input value to the selected option
    setShowOptions(false); // Hide the options
  };

  const fetchOptions = async () => {
    console.log("name1, surname1", name, surname);
    try {
      const response = await getTagNames(name, surname, 5);
      console.log("name2, surname2", name, surname, response.data);
      
      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  return (
    <div className="relative mb-1">
      <label htmlFor="sakina_tag" className="input-label">
        Tag name
      </label>
      <TextField
        value={inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id="sakina_tag"
        name="sakina_tag"
        placeholder="Type anything..."
        className="!w-full !mb-0 py-3 input"
        required={inputValue ? true : false}
        InputProps={{
          startAdornment: <InputAdornment position="start">@</InputAdornment>,
          classes: {
            input: "input !py-3",
          },
        }}
        helperText={errorMessage}
        sx={{
          // border on focus
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-root.Mui-hover":
            {
              border: "0.8px solid #000",
            },
          // border not focused
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #e5e7eb",
          },
        }}
      />
      {showOptions && (
        <ul className="absolute w-full z-10 bg-white shadow-md max-h-60 overflow-auto">
          {options.map((option, index) => (
            <li
              key={index}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onMouseDown={() => handleOptionClick(option)} // Use onMouseDown instead of onClick to fire before onBlur
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TagName;
