// @ts-nocheck
import React, { useState, useEffect } from "react"
import { Box } from "@mui/system"
import { Link } from "react-router-dom"
import HomeIcon from "@mui/icons-material/Home"
import ShieldMoonIcon from "@mui/icons-material/ShieldMoon"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { NavLink } from "react-router-dom"
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined"
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined"
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined"
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined"
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined"
import { signOut } from "firebase/auth"
import { auth } from "../../../../Firebase"
import { useCounterStore, useSidebarStore, useStoreTemporary, useTokenStore } from "../../../../Zustand"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import VideoChatIcon from "@mui/icons-material/VideoChat"
import PersonIcon from "@mui/icons-material/Person"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import SettingsIcon from "@mui/icons-material/Settings"

import {
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material"
import LogoutIcon from "@mui/icons-material/Logout"
// import images from "../../../../assets/images/images"
import { Button, Popover, Typography } from "@mui/material"
import Modal from "@mui/material/Modal"
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined"
import RedoIcon from "@mui/icons-material/Redo"
import ClearIcon from "@mui/icons-material/Clear"
import Tooltip from "@mui/material/Tooltip"
import ButtonWithPopover from "../Components/ButtonWithPopover"

const LayoutTherapist = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const [hide, setHide] = useState(true)
	const [isShown, setIsShown] = useState(false)
	const [selectedItemIndex, setSelectedItemIndex] = useState(0)
	const [open, setOpen] = useState(false)
	const { setToken } = useTokenStore()
	const { reset } = useCounterStore();
	const { sidebarOpen, setSidebarState } = useSidebarStore();

	const handleClick = (e) => {
		e.stopPropagation()
		console.log("1")
		setOpen(true)
	}

	const handleClose = (e) => {
		e.stopPropagation()
		closeSideMenu();
		console.log("2")
		setOpen(false)
	}

	const handleLogout = () => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				navigate("/auth/therapist/signin")
				setToken({})
				console.log("Signed out successfully")
			})
			.catch((error) => {
				// An error happened.
			})
	}
	
	const closeSideMenu = () => {
		setSidebarState(false);
	};

	useEffect(() => {
		reset();
	}, []);

	return (
		<Box style={{ display: "flex", height: "100vh" }}>
			{
				sidebarOpen ? 
					<div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-[11]" onClick={()=>setSidebarState(false)}></div>
				: null
			}
			<Box
				className={`
					${hide ? "md:w-[300px] md:p-4" : "md:w-[85px] md:p-4"}
					${sidebarOpen ? "w-[250px] p-4" : "w-[0px]"}
					fixed bg-white
					!z-20 flex flex-col
					md:relative h-[100vh]
				`}
				style={{
					transition: "width 0.5s ease-out, padding 0.3s ease-in-out",
					overflowY: "auto",
					overflowX: "hidden",
				}}
				onMouseEnter={() => setIsShown(true)}
				onMouseLeave={() => setIsShown(false)}
			>
				<Link to="/therapist" style={{ display: "flex", marginLeft: !hide && 10 }} onClick={closeSideMenu}>
					<img
						className={`w-36 items-center align-middle ${hide ? "ml-4" : "ml-0"
							} mt-4`}
						src={require("../../../../assets/images/sakina-blue.png")}
						alt=""
					/>
				</Link>

				<IconButton
					className="!hidden md:!block"
					onClick={(e) => {
						e.stopPropagation()
						console.log("first")
						setHide(!hide)
					}}
					style={{
						zIndex: 9999,
						position: "absolute",
						top: hide ? 15 : 60,
						right: hide ? 2 : 22,
						color: "#BAB9CC",
						// display: isShown ? "flex" : "none",
					}}
				>
					{hide ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
				</IconButton>

				<Box style={{ height: "100%" }}>
					<List
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "column",
							paddingTop: 66,
						}}
					>
						<Box>
							{sidebar.map((item, index) => (
								<Link key={index} to={item.link} onClick={closeSideMenu}>
									<ListItem disablePadding>
										<ListItemButton
											className="onHover"
											style={{
												background:
													location.pathname === item.link
														? "rgb(59 130 246)"
														: index === selectedItemIndex
															? "#E5E7EB"
															: "inherit",
												color:
													location.pathname === item.link
														? "white"
														: index === selectedItemIndex
															? "black"
															: "#BAB9CC",
												borderRadius: 5,
												marginBottom: 5,
											}}
											onMouseEnter={() => setSelectedItemIndex(index)}
											onMouseLeave={() =>
												setSelectedItemIndex(
													selectedItemIndex === index ? selectedItemIndex : -1
												)
											}
										>
											<ListItemIcon style={{ color: "inherit" }}>{item.icon}</ListItemIcon>
											<ListItemText primary={item.name} />
										</ListItemButton>
									</ListItem>
								</Link>
							))}
						</Box>

						<Box>
							<ListItem disablePadding>
								<ListItemButton
									onClick={handleClick}
									className="onHover"
									style={{
										background:
											selectedItemIndex === sidebar.length ? "#E5E7EB" : "inherit",
										color: selectedItemIndex === sidebar.length ? "black" : "#BAB9CC",
										borderRadius: 5,
										marginBottom: 5,
									}}
									onMouseEnter={() => setSelectedItemIndex(sidebar.length)}
									onMouseLeave={() =>
										setSelectedItemIndex(
											selectedItemIndex === sidebar.length ? selectedItemIndex : -1
										)
									}
								>
									<ListItemIcon style={{ color: "inherit" }}>
										<LogoutIcon />
									</ListItemIcon>
									<ListItemText primary="Log out" />
									{open && (
										<ButtonWithPopover
											open={open}
											onClose={handleClose}
											handleLogout={handleLogout}
											title="Log out"
											text="Are you sure you want to log out?"
										/>
									)}
								</ListItemButton>
							</ListItem>

							<ListItem disablePadding>
								<ListItemButton
									className="onHover"
									onClick={(e) => {
										e.preventDefault()
										closeSideMenu();
										navigate("/therapist/therapist-settings")
									}}
									style={{
										background:
											selectedItemIndex === sidebar.length + 1 ? "#E5E7EB" : "inherit",
										color: selectedItemIndex === sidebar.length + 1 ? "black" : "#BAB9CC",
										borderRadius: 5,
										marginBottom: 5,
									}}
									onMouseEnter={() => setSelectedItemIndex(sidebar.length + 1)}
									onMouseLeave={() =>
										setSelectedItemIndex(
											selectedItemIndex === sidebar.length + 1 ? selectedItemIndex : -1
										)
									}
								>
									<ListItemIcon style={{ color: "inherit" }}>
										<SettingsIcon />
									</ListItemIcon>
									<ListItemText primary="Settings" />
								</ListItemButton>
							</ListItem>
						</Box>
					</List>
				</Box>
			</Box>
			<Box style={{ flex: "1", overflow: "auto" }}>
				<Outlet />
			</Box>
		</Box>
	)
}

const sidebar = [
	// {
	// 	name: "Dashboard",
	// 	link: "/therapist",
	// 	icon: <DashboardOutlinedIcon />,
	// },
	{
		name: "Calendar",
		link: "/therapist/",
		icon: <CalendarMonthOutlinedIcon />,
	},
	{
		name: "Customers",
		link: "/therapist/customers",
		icon: <GroupsOutlinedIcon />,
	},
	{
		name: "Payments",
		link: "/therapist/payments",
		icon: <PaymentOutlinedIcon />,
	},
]

export default LayoutTherapist
