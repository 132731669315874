import React, { useState, useEffect } from 'react';

function JsonDisplayComponent() {
    const [jsonData, setJsonData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch the JSON from a specified path
        fetch('/public/.well-known/microsoft-identity-association.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setJsonData(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <pre>{JSON.stringify(jsonData, null, 2)}</pre>
        </div>
    );
}

export default JsonDisplayComponent;
