import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import LoadingPage from "../../../../components/Cards/LoadingPage"

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,

	bgcolor: "background.paper",
	border: "0px solid #000",
	boxShadow: 24,
	p: 4,
}

export default function CancelSession({ mutate, selectedEvent, isLoad }) {
	const [modalId, setModalId] = React.useState("")
	
	const handleOpen = (id) => setModalId(id)
	const handleClose = () => setModalId("")
	
	if (isLoad) return <LoadingPage />
	return (
		<div>
			<div className="flex">
				<Tooltip title="Cancel this session">
					<IconButton>
						<ClearIcon onClick={() => handleOpen("cancel")} />
					</IconButton>
				</Tooltip>
				<Modal
					open={modalId === "cancel"}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Do you want to cancel this session?
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							If you cancel this event then the customer will get <span className="font-bold">full refund</span>  <br /> If
							the event it marked as{" "}
							<span className="opacity-90 text-white text-xs font-bold p-2 rounded bg-green-400">
								{" "}
								Other Calendar Event{" "}
							</span>
							, then by proceeding you will remove it from this calendar
						</Typography>
						<div className="mt-3 pt-3">
							<button className="btn-secondary" onClick={handleClose}>
								Cancel
							</button>
							<button
								className="btn-primary"
								onClick={() => {
									mutate(selectedEvent.id);
									handleClose()
								}}
							>
								Proceed
							</button>
						</div>
					</Box>
				</Modal>
			</div>
		</div>
	)
}
