//@ts-nocheck

import React, { useState, useEffect } from "react"

export default function TextAreaValidated({
  id,
  onChange,
  name,
  label,
  register,
  error,
  maxLength,
  watch,
  placeholder,
  value
}) {
  const [characterCount, setCharacterCount] = useState(0)
  const textareaValue = watch(name, "")

  useEffect(() => {
    setCharacterCount(textareaValue.length)
  }, [textareaValue])

  const handleInput = (e) => {
    const textarea = e.target as HTMLTextAreaElement
    textarea.rows = 1
    textarea.rows = Math.ceil(textarea.scrollHeight / 20)
  }

  return (
    <div className="mb-4">
      <label htmlFor={id} className="input-label">
        {label}
      </label>
      <textarea
        name={name}
        rows={4}
        className="input"
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        value={value}
        onInput={handleInput}
        style={{ resize: 'none' }}
        {...(register, { value: value })} // Spread the register object for form validation

        // {...register}
      />
      <p className="text-gray-600 text-xs italic">
        Max length {maxLength} characters
        {/* Characters left: {maxLength - characterCount}/{maxLength} */}
      </p>
      {error && error.type === "required" && (
        <p className={` text-red-500 text-xs mt-1 ${error ? "animate-shake" : ""}`}>{label} is required</p>
      )}
    </div>
  )
}
