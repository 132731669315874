import { Box } from "@mui/material";
import React from "react";

const StatisticsCard = ({ icon, title, number, text, iconColor, iconBg }) => {
	const isDesktop = window.innerWidth >= 768;
	return (
		<div>
			<div className="h-full max-w-sm min-w-[127.68px] cursor-pointer p-2 pr-3 md:pr-6 md:pl-2 md:py-1 rounded-lg shadow-xs overflow-hidden border shadow-md   ">
				<div className="md:p-4 flex items-start md:items-center flex-col md:flex-row">
					<div className="flex items-center gap-2">
						<Box 
							className={`min-w-[28px] w-7 h-7 md:min-w-[40px] md:w-10 md:h-10 flex items-center justify-center md:p-3 rounded-[50%] md:rounded-full ${iconColor} ${iconBg}`}
							sx={{
								"& svg": {
									width: isDesktop ? "24px" : "18px"
								}
							}}
						>
							{icon}
						</Box>
						<p className="md:mb-2 text-sm font-medium text-gray-600 block md:hidden">{title}</p>
					</div>
					<div className="md:ml-3 mt-1 md:mt-0">
						<p className="mb-2 text-sm font-medium text-gray-600 hidden md:block">{title}</p>
						<p className="text-base md:text-lg font-semibold text-gray-700">{number}</p>
						<p className="text-gray-400 mt-2 text-xs">{text}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StatisticsCard;
