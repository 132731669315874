import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Alert,
  InputAdornment,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import WelcomeMessage from "../../sakinah/User/Components/WelcomeMessage";
import ApiRequests from "../../api/ApiRequests";
import { useToastStore, useTokenStore } from "../../../Zustand";
import SnackbarX from "../../sakinah/User/Components/SnackbarX";
import Toast from "../../sakinah/helpers/Toast";
// ... any other required imports ...

function TherapistSetNewPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { openToast } = useToastStore();
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { setNewPassword } = ApiRequests();
  const navigate = useNavigate();
  const { token, setToken } = useTokenStore();

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      setIsError(true);
      return;
    }
    const info = {
      password: password,
      token: queryParams.get("reset-token"),
      type: 2,
    };
    console.log(info);

    // Call API or perform necessary actions to set the new password.
    setIsLoading(true);
    setNewPassword(info)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          setMessage(`Successfully set`);
          setIsError(false);
          openToast("Password reset successful!");
          navigate("/auth/therapist/signin");
          return;
        }
        setMessage(`Problem occurred`);
        setIsError(true);
      })
      .catch((error) => {
        console.log("error: errr: ", error);
        const errorMessage = error.response?.data?.message;
        setIsError(true);
        setMessage(`${errorMessage}`);
      })
      .finally(() => {
        setIsLoading(false); // Deactivate loading state
      });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CssBaseline />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <WelcomeMessage />
          </Grid>
          <Grid container item xs={12} md={6}>
            <Box
              component="form"
              onSubmit={handlePasswordSubmit}
              noValidate
              className="flex flex-col items-center justify-center max-w-full min-w-[400px] bg-[#5f6ac40f] rounded-lg p-5 pt-[30px] m-[10px]"
            >
              <CssBaseline />
              <Typography component="h1" variant="h5" mb="10px">
                Set New Password
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="New Password"
                name="password"
                value={password}
                type={showNewPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                type={showConfirmPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {message && (
                <Alert severity={isError ? "error" : "success"}>
                  {message}
                </Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Set Password"}
              </Button>
              <Link to="/auth/therapist/signin">
                <Button fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
                  Go to Login
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TherapistSetNewPassword;
