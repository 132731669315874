import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useState } from "react"


const queryClient = new QueryClient()

// const [queryClient] = React.useState(() => new QueryClient())


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<App />
		</QueryClientProvider>
		{/* <ReactQueryDevtools initialIsOpen={true} /> */}
	</BrowserRouter>
)

reportWebVitals()
