import React, { useEffect, useState } from "react"
import CancelSession from "../CancelSession"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined"
import { useQuery } from "@tanstack/react-query"
import ApiRequests from "../../../../api/ApiRequests"
import { useParams } from "react-router-dom"
import { useStoreUser } from "../../../../../Zustand"
import dayjs from "dayjs"
import { Box, Typography } from "@mui/material"

const TableCustomerProfile = ({ client }) => {
	const { userInfo, setUserInfo } = useStoreUser()
	const therapistId = userInfo?.data?.therapist?.id
	const { id } = useParams()
	const tableHead = ["Date", "Time", "Status", "Action"]

	const { getBookingList } = ApiRequests()
	const [eventsByUser, setEventsByUser] = useState([])

	const {
		data: bookingData,
		isError,
		isLoading,
	} = useQuery({
		queryKey: ["bookings-data-by-patient"],
		queryFn: () => getBookingList(),
	})

	useEffect(() => {
		if (bookingData?.data?.bookings) {
			setEventsByUser(
				bookingData?.data?.bookings?.filter(
					(event) => event?.patient.id === id && event?.therapist_id === therapistId
				)
			)
		}
	}, [bookingData, id])
	if (isError) return <h1>Error</h1>
	if (isLoading) return <h1>Loading...</h1>

	const statusTextMapping = {
		succeeded_payment_checkout: "Paid",
		refund_completed: "Refund completed",
		free_trial: "Free Trial",
		cancelled: "Cancelled",
	}

	console.log("eventsByUser", eventsByUser)
	console.log("eventsByUser", eventsByUser)

	const sortedEvents = [...eventsByUser]
		.filter((event) =>
			[
				"free_trial",
				"refund_completed",
				"cancelled",
				"succeeded_payment_checkout",
			].includes(event.status)
		)
		.sort((a, b) => dayjs(b.start_time).unix() - dayjs(a.start_time).unix())

	return (
		<div className="mt-4 max-w-full w-full md:w-auto md:min-w-[512px]">
			<h2 className="text-lg font-semibold p-4">Sessions</h2>
			<div className="w-full overflow-auto shadow-md rounded-lg">
				<table className="w-full text-sm text-left text-gray-500">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50">
						<tr>
							{tableHead.map((title, index) => (
								<th key={`sessions_${index}`} scope="col" className="px-4 py-3">
									{title}
								</th>
							))}
						</tr>
					</thead>
					{!sortedEvents.length ? (
						<Typography className="p-4 w-max">No sessions</Typography>
					) : (
						<tbody>
							{sortedEvents.map((session) => (
								<tr key={session.id} className="bg-white border-b">
									<td className="p-4">
										<Box className="flex items-center gap-1 w-max">
											<CalendarMonthOutlinedIcon style={{ color: "inherit" }} />
											{dayjs(session.start_time).format("DD MMM YYYY")}
										</Box>
									</td>
									<td className="p-4">
										<Box className="flex items-center gap-1 w-max">
											<ScheduleOutlinedIcon style={{ color: "inherit" }} />
											{`${dayjs(session.start_time).format("HH:mm")} - ${dayjs(
												session.end_time
												).format("HH:mm")}`}{" "}
										</Box>
									</td>
									<td className="p-4">
										<Box className="flex items-center gap-1 w-max">
											<ScheduleOutlinedIcon style={{ color: "inherit" }} />
											{statusTextMapping[session.status] || session.status}
										</Box>
									</td>
									<td className="p-4">
										<CancelSession isLoad={null} mutate={null} selectedEvent={null} />
									</td>
								</tr>
							))}
						</tbody> 
					)}
				</table>
			</div>
		</div>
	)
}

export default TableCustomerProfile
