// @ts-nocheck
import React, { useEffect, useState } from "react"
import data from "../Components/Data/DummyData"
import AvatarCustomers from "../Components/AvatarCustomers"
import TableCustomerProfile from "../Components/Tables/TableCustomerProfile"
import TabsComponent from "../Components/TabCustomer"
import clientData from "../Components/Data/ClientDetails"
import sessionData from "../Components/Data/SessionsData"
import notesData from "../Components/Data/NotesData"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import ApiRequests from "../../../api/ApiRequests"
import LoadingComponent from "../../helpers/Loading"
import ErrorComponent from "../../helpers/Error"
import Navbar from "../Components/Navbar"

const CustomerProfile = () => {
	const { id } = useParams()

	const { getPatientInfoById } = ApiRequests()

	const {
		data: patientInfoById,
		isError,
		isLoading,
	} = useQuery({
		queryKey: ["patient-info-by-id"],
		queryFn: () => getPatientInfoById(id),
	})

	const patientData = patientInfoById?.data
	if (isError) return <ErrorComponent errorMessage="Error occurred" />
	if (isLoading) return <LoadingComponent />

	return (
		<>
			<Navbar title={`${patientData?.patient.name} ${patientData?.patient.surname}`} />
			<div className="md:p-12 md:w-11/12 m-auto">
				<div className="flex flex-col text-3xl md:text-5xl items-center p-4">
					{/* <AvatarCustomers name={patientData?.name} /> {} */}
					<span className="md:block hidden font-normal cursor-pointer text-stone-900">
						{patientData?.patient.name} {patientData?.patient.surname}
					</span>
					<div className="w-full md:px-12 md:mt-12 md:mb-6 md:w-11/12">
						<TabsComponent
							color="blue"
							tab1="Profile"
							tab2=""
							tab3=""
							tab4=""
							client={patientData}
						/>
					</div>

					<TableCustomerProfile client={patientData} />
				</div>
			</div>
		</>
	)
}

export default CustomerProfile
