import * as React from "react"
import Box from "@mui/material/Box"
import FormLabel from "@mui/material/FormLabel"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

interface Data {
	data: any
	title: string
	preferences: string[]
	setPreferences: (preferences: string[]) => void
}

export default function CheckboxesGroup({ data, title, setPreferences, preferences }: Data) {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!preferences.includes(event.target.name)) {
			return setPreferences([...preferences, event.target.name])
		} else {
			return setPreferences(preferences.filter((p) => p !== event.target.name))
		}
	}

	return (
		<Box sx={{ display: "flex" }}>
			<FormControl sx={{ m: 3 }} className="sm:!m-6 !m-0" component="fieldset" variant="standard">
				<FormLabel component="legend" style={{ height: 55 }} className="sm:!h-14 !h-7">
					{title}
				</FormLabel>
				<FormGroup>
					{data?.map((item) => (
						<FormControlLabel key={`chbxg_${item.id}`} control={<Checkbox checked={preferences.includes(item.id) ? true : false} onChange={handleChange} name={item.id} />} label={item.name} />
					))}
				</FormGroup>
			</FormControl>
		</Box>
	)
}
