//@ts-nocheck
import React from "react"
export default function InputValidated({
	type,
	name,
	id,
	label,
	register,
	error,
	onChange,
	value,
	placeholder,
	...rest
}) {
	const today = new Date().toISOString().split('T')[0];
	return (
		<div className="mb-4">
			<label htmlFor={id} className="input-label">
				{label}
			</label>
			<input
				className="input"
				type={type}
				name={name}
				id={id}
				max={today}
				placeholder={placeholder}
				// value={value}
				onChange={onChange} // Pass the onChange event here
				{...(register, { value: value })} // Spread the register object for form validation
				{...rest} // Spread any additional props
			/>{" "}
			{error && error.type === "required" && (
				<p className={` text-red-500 text-xs mt-1 ${error ? "animate-shake" : ""}`}>
					{label} is required
				</p>
			)}
			{error && error.type === "maxLength" && (
				<p className={` text-red-500 text-xs mt-1 ${error ? "animate-shake" : ""}`}>
					Max length exceeded
				</p>
			)}
		</div>
	)
}
