import React, { useEffect, useRef, useState } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useCounterStore, useTokenStore } from "../../../../../Zustand"
import { createAxios } from "../../../helpers/createAxios"

function NysonCallback(props) {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const urlParams = new URLSearchParams(window.location.search)
	const code = urlParams.get("code")
	const { token } = useTokenStore();
	const { count, increment } = useCounterStore();
	async function authCompleted() {
		console.log("CALL TO API");
		
		const Axios = createAxios(token?.access_token)
		try {
			const response = await Axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/calendar/auth-completed`,
				{
					authorization_code: code,
				}
			)
			if (window.innerWidth < 768) {
				navigate("/onproccess");
			} else {
				navigate("/therapist")
			}
		} catch (error) {
			console.log("error 17", error)
		} finally {
			setLoading(false);
		};
	}

	useEffect(() => {
		if (code) {
			if (count == 0) {
				increment();
				authCompleted();
			}
		} else {
			console.log("No verification token found")
		}
	}, [])

	if (!code) return (<Layout>Verificatition token not found</Layout>)
	if (loading) return (<Layout><CircularProgress /></Layout>)
	return (<Layout>Something went wrong.</Layout>)
}
const Layout = ({children}) => {
	return (
	  <Box className="items-center w-screen h-screen flex justify-center">
		{children}
	  </Box>
	)
}
export default NysonCallback
