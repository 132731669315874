import React from "react";
import StatisticsCard from "../Components/StatisticsCard";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SouthIcon from "@mui/icons-material/South";
import TabPayments from "../Components/TabPayments";
import Tabs from "../Components/TabCustomer";
import TableCustomerProfile from "../Components/Tables/TableCustomerProfile";
import data from "../Components/Data/DummyData";
import TableSales from "../Components/Tables/TableSales";
import Navbar from "../Components/Navbar";

const tableHead = ["Date", "Time", "Status", "Paid", "Action"];

const PaymentsTherapist = () => {
	return (
		<>
			<Navbar title="Payments"/>
			<div className="p-4 md:m-5">
				<div className="flex gap-2.5 md:gap-6 overflow-auto pb-2 items-stretch">
					<StatisticsCard
						icon={<CurrencyPoundIcon />}
						iconColor="text-green-500"
						iconBg="bg-green-100"
						title="Income"
						number="Coming Soon"
						text="this month"
					/>
					<StatisticsCard
						icon={<AccountBalanceWalletIcon />}
						iconColor="text-sky-600"
						iconBg="bg-sky-100"
						title="Balance"
						number="Coming Soon"
						text="available"
					/>
					<StatisticsCard
						icon={<SouthIcon />}
						iconColor="text-yellow-600"
						iconBg="bg-yellow-100"
						title="Withdrawn"
						number="Coming Soon"
						text="this month"
					/>
				</div>
				{/* <Tabs /> */}
				<TabPayments
					color="blue"
					tab1="Sales History"
					tab2="Payout History"
					tab3=""
					tab4=""
				/>
			</div>
		</>
	);
};

export default PaymentsTherapist;
