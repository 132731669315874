import { Box, Button, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import Navbar from "./Navbar"

function SuccessEvent(props) {
	return (
		<div>
			<Navbar />
			<Box
				sx={{
					background: "rgb(95, 106, 196)",
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignItems: "center",
					borderRadius: 2,
					minHeight: "250px",
					p: 1,
					m: 1,
				}}
			>
				<Typography variant="h4" sx={{ color: "white" }}>
					🎉 Your session has been successfully booked!
				</Typography>
				<Link to="/">
					<Button sx={{ m: 2, mt: 5 }} variant="contained">
						Go to Dashboard
					</Button>
				</Link>
			</Box>
		</div>
	)
}

export default SuccessEvent
