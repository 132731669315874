import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useSidebarStore } from "../../../Zustand";

export default function SideMenuMobile() {
  const { sidebarOpen, setSidebarState } = useSidebarStore();
    console.log("sidebarOpensidebarOpen>>>", sidebarOpen);
    
  return (
    <MenuRoundedIcon
      className="block md:hidden min-w-[24px] w-6 h-6 text-black"
      onClick={() => setSidebarState(true)}
    />
  );
}
