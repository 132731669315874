import { Box, Button } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import SnackbarX from "./SnackbarX"
import dayjs from "dayjs"
import TherapySession from "./TherapySession"
import ApiRequests from "../../../api/ApiRequests"
import { useQuery } from "@tanstack/react-query"
import LoadingPage from "../../../../components/Cards/LoadingPage"

function NoUpcomingSession(props) {
	const [open, setOpen] = React.useState(false)
	const { getBookingList } = ApiRequests()

	const { data, isLoading, isError } = useQuery(["bookingList"], () =>
		getBookingList()
	)

	const upSessions = data?.data?.bookings
	const sidebarWidth = window.innerWidth >= 767 ? 280 : 32

	if (isLoading) return <LoadingPage />
	if (isError) return <div>Something went wrong...</div>

	return (
		<Box>
			<SnackbarX
				open={open}
				setOpen={setOpen}
				backgroundColor="#32a676"
				message="You have successfully cancelled your sessions!"
			/>

			{upSessions?.filter(
				(session) => dayjs(session.start_time).unix() > dayjs().unix()
			)?.length === 0 ? (
				<div
					style={{
						boxShadow:
							"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
						padding: "20px 20px 30px",
						flex: 2,
						margin: 10,
						borderRadius: 8,
						color: "white",
						maxHeight: 220,
						display: "flex",
						justifyContent: "space-between",
						flexDirection: "column",
						background:
							upSessions?.length === 0 ? "white" : "rgb(95 106 196 / 5%)",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "column",
							height: "100%",
						}}
					>
						<h3
							style={{
								color: "#5f6ac4",
								fontWeight: 600,
								fontSize: 22,
								padding: 0,
								marginBottom: 20,
							}}
						>
							No Upcoming Sessions!
						</h3>
						<p style={{ color: "grey", marginBottom: 15 }}>
							Take the first step towards feeling better by booking your first
							therapy session today.
						</p>
						<Link to="/therapist-list">
							<Button
								variant="contained"
								style={{ background: "#e26d80", width: 200 }}
							>
								Book A Session!
							</Button>
						</Link>
					</div>
				</div>
			) : (
				<>
					<h3 className="font-semibold mt-4 ml-0 text-[19px] md:mt-0 md:ml-2.5 text-[#5f616a]"
					>
						Upcoming Sessions
					</h3>
					<div className={`flex overflow-x-auto overflow-y-hidden box-border`} style={{ width: `calc(100vw - ${sidebarWidth}px)` }}>
						{upSessions?.length > 0 &&
							upSessions
								.filter(
									(session) => dayjs(session.start_time).unix() > dayjs().unix()
								)
								?.sort(
									(a, b) =>
										dayjs(a.start_time).unix() - dayjs(b.start_time).unix()
								)
								.map((session, index) => (
									<TherapySession key={`${session.id}_upcomingSession`} session={session} upSessions={upSessions} />
								))}
					</div>
				</>
			)}
		</Box>
	)
}

export default NoUpcomingSession
