import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import photo from "../../../assets/images/business-account.png"
import { useStoreTemporary, useStoreUser, useTokenStore } from "../../../Zustand"
import axios from "axios"
import Avatar from "@mui/material/Avatar"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import Toast from "../helpers/Toast"
import { MouseEvent, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ApiRequests from "../../api/ApiRequests"

export default function UserInfo() {
	const navigate = useNavigate()
	const { token } = useTokenStore()
	const { setOnboardingComplete } = useStoreTemporary()
	const [showProfile, setShowProfile] = useState(true)
	const [name, setName] = useState("")
	const [surname, setSurname] = useState("")
	const [phone, setPhone] = useState("")
	const [gender, setGender] = useState(null)
	const [showToast, setShowToast] = useState(false)
	const [message, setMessage] = useState("")
	const theme = useTheme();
	const { setUserInfo } = useStoreUser()

	const handleChangeGender = (
		event: MouseEvent<HTMLElement>,
		newAlignment: number
	) => {
		setGender(newAlignment)
	}

	const { getPatientInfo } = ApiRequests()
	async function completeOnboarding() {
		// Check if all the fields are completed
		if (!name || !surname || !phone) {
			setMessage("Please fill all fields")
			setShowToast(true)
			return // Stop the onboarding process if fields are missing
		} else if (!gender) {
			setMessage("Please choose a gender")
			setShowToast(true)
			return // Stop the onboarding process if gender is not selected
		}
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/onboarding/complete-patient`,
				{
					gender,
					name,
					surname,
					date_of_birth: "1994-10-30",
					mobile_number: phone,
				},
				{
					headers: {
						"Content-Type": "application/json",
						accept: "application/json",
						authorization: "Bearer " + token.access_token,
					},
				}
			)
			setOnboardingComplete()
			navigate("/")
		} catch (error) {
			setMessage(error.response?.data[0]?.message || (typeof error.response?.data == "string" ? error.response?.data : ""));
			setShowToast(true);
			setShowProfile(false)
			console.log("error onboarding", error)
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getPatientInfo()
				console.log("Response: getPatientInfo", response);
				setUserInfo(response)
				if (response?.data?.onboarding_completed) {
					navigate("/")
				}
			} catch (error) {
				console.log("Error:", error.message)

				// Handle error appropriately
			}
		}

		fetchData()
	}, []) // dependency array should be empty to mimic componentDidMount

	// Handle register
	const handleSubmit = (e) => {
		e.preventDefault()
		completeOnboarding()
	}

	return (
		<Box className="flex items-center justify-center md:justify-between p-4 !bg-white sm:!m-5 sm:!p-10">
			<Toast
				message={message}
				show={showToast}
				onClose={() => setShowToast(false)}
				background="#dc3545"
			/>
			<CssBaseline />
			{showProfile ? (
				<Box className="flex sm:flex-row flex-col">
					<Box className="flex flex-col items-start max-w-[650px] bg-[#5f6ac40f] rounded-lg p-4 md:m-2.5 sm:p-10 sm:pt-[30px]">
						<p className="flex items-start font-semibold text-[22px] md:my-2.5 m-0 mb-2">
							Profile details
						</p>
						<p>Enter details to set up your account (*Use any name)</p>
						<Box
							component="form"
							noValidate
							onSubmit={handleSubmit}
							className="sm:mt-10"
							sx={{ maxWidth: 500 }}
						>
							<Grid container rowSpacing={2} className="!w-full !m-0">
								<Grid item xs={12}>
									<TextField
										name="firstName"
										required
										fullWidth
										id="firstName"
										label="Name"
										autoFocus
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										name="surname"
										required
										fullWidth
										id="surname"
										label="Surname"
										value={surname}
										onChange={(e) => setSurname(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										type="tel"
										required
										fullWidth
										label="Mobile number"
										inputProps={{
											inputMode: "tel",
										}}
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
									/>
									<p className="text-gray-600 text-xs italic">
										Please include country code with + i.e. +447843221199
									</p>
								</Grid>
								<Grid item xs={12}>
									<ToggleButtonGroup
										color="primary"
										value={gender}
										exclusive
										onChange={handleChangeGender}
										aria-label="Platform"
										style={{ borderColor: "#e5e7eb" }}
										className="sm:h-14 w-full" // Set the full width and height
									>
										<ToggleButton
											value={1}
											className="flex flex-1 sm:w-[250px] "
											style={{ borderColor: "#e5e7eb" }}
										>
											Male
										</ToggleButton>
										<ToggleButton
											value={2}
											style={{ borderColor: "#e5e7eb" }}
											className="flex flex-1 sm:w-[250px]"
										>
											Female
										</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								className="sm:!mt-8 !mt-6 !mb-2 !bg-[#e26d80]"
							>
								Next
							</Button>
						</Box>
					</Box>

					<img
						src={photo}
						className="max-w-full h-auto sm:w-[450px] hidden md:block"
						alt="profile"
					/>
					{/* <img src={photo} style={{ width: 450 }} alt="profile" /> */}
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<div
						style={{
							boxShadow: "none",
							background: "rgb(95, 106, 196)",
							flex: 2,
							borderRadius: 8,
							width: "100%",
							display: "flex",
							color: "white",
							justifyContent: "start",
							alignItems: "center",
							flexDirection: "row",
							marginBottom: 20,
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: "transparent" }}>
							<AcUnitIcon />
						</Avatar>
						<h2 style={{ fontWeight: 700, fontSize: 20 }}>
							What would you like to discuss with your therapist?
						</h2>
					</div>

					<Box sx={{ display: "flex" }}>
						<Button
							variant="contained"
							onClick={() => setShowProfile(true)}
							style={{
								margin: 20,
								background: "rgb(226, 109, 128)",
								boxShadow: "none",
								padding: "5px 20px",
							}}
						>
							Back
						</Button>
						<Button
							variant="contained"
							onClick={handleSubmit}
							style={{
								margin: 20,
								background: "rgb(226, 109, 128)",
								boxShadow: "none",
								padding: "5px 20px",
							}}
						>
							Save
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	)
}
