import { Box, Container } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import { useStoreUser } from "../../../Zustand";
import { useEffect } from "react";

const theme = createTheme();

function AuthTherapist(props: any) {
  const { setUserInfo } = useStoreUser();
  useEffect(() => {
    setUserInfo({});
  }, [])
  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100%",
        background: "#e0e0e0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="xs"
          className="md:!p-6 md:min-h-max md:rounded-[5px] bg-white min-h-[100vh] !pt-5"
     >
          <Outlet />
        </Container>
      </ThemeProvider>
    </Box>
  );
}

export default AuthTherapist;
