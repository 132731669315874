import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup"; // For validation schema
import { TextField, Button, Grid, Box } from "@mui/material";
import BookingInfoList from "./components/BookInfoList";
import { useExternalBookStore, useTokenStore } from "../../../../../Zustand";

const ExternalBook = () => {
  const phoneRegex = /^(\+\d{1,3})?,?\s?\d{8,13}$/;
  const { bookInfo, setBookInfo } = useExternalBookStore();
  // Form validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegex, "Phone number is not valid")
      .required("Phone is required"),
  });

  // Formik hook
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // API call here
      console.log(values);
    },
  });

  //   async function payForSession(time) {
  //     setLoading(true);
  //     const duration = isForCouples ? 90 : 50; // Determine duration based on session type

  //     try {
  //       const { data } = await Axios.post(
  //         `${process.env.REACT_APP_API_ENDPOINT}/reservation`,
  //         {
  //           start_time: dayjs(time)?.toISOString(),
  //           end_time: dayjs(time).add(duration, "minute")?.toISOString(), // <-- changed here
  //           therapist_id: therapistInfo.id,
  //           is_session_for_couple: isForCouples,
  //           is_free_trial: isFreeTrial,
  //         }
  //       );
  //       console.log("PayForSession response", data);
  //       setLoading(false);
  //       queryClient.invalidateQueries(["availability"]);
  //       window.open(data.checkout_session_url);
  //     } catch (error) {
  //       console.log("PayForSession error", error);
  //       setLoading(false);
  //       setError(error.response.data.message);
  //     }
  //   }
  console.log("bookInfobookInfobookInfo: ", bookInfo);
  useEffect(() => {
    return () => {
      // setBookInfo({});
    };
  }, []);
  if (!bookInfo.therapistInfo) {
    return (
      <Grid
        container
        className="bg-[#e0e0e0] min-h-full min-w-full flex justify-center items-center"
      >
        <span className="text-3xl">An error occurred...</span>
      </Grid>
    );
  }
  return (
    <Grid
      container
      className="bg-[#e0e0e0] min-h-full min-w-full flex justify-center items-center"
    >
      <Box className="flex flex-col md:flex-row justify-center w-min">
        <BookingInfoList />
        <form
          onSubmit={formik.handleSubmit}
          className="space-y-4 bg-white pt-6 pb-4 px-4 rounded-b-lg md:rounded-r-lg max-w-[35%] min-w-[312px]"
        >
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            id="email"
            name="email"
            inputMode="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            label="Phone"
            variant="outlined"
            fullWidth
            id="phone"
            name="phone"
            type="phone"
            inputMode="tel"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth className="!bg-[#4594d3] !shadow-none">
            Book
          </Button>
        </form>
      </Box>
    </Grid>
  );
};

export default ExternalBook;
