import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import photo from "../../../assets/images/business-account.png"
import ApiRequests from "../../api/ApiRequests"
import { useQuery } from "@tanstack/react-query"
import LoadingPage from "../../../components/Cards/LoadingPage"
import dayjs from "dayjs"
import Navbar from "./Navbar"
import { SETTINGS_TITLE } from "../helpers/constants"

export default function MySettings() {
	const { getPatientInfo } = ApiRequests()

	const { data, isLoading, isError } = useQuery(
		["patient-my-info"],
		getPatientInfo
	)

	const patient = data?.data?.patient

	const date1 = dayjs(patient?.date_of_birth)
	const age = date1.diff("2018-06-05", "year")

	if (isLoading) return <LoadingPage />
	if (isError) return (
		<>
			<Navbar title={SETTINGS_TITLE} />
			<h2>Error...</h2>
		</>
	)

	return (
		<>
			<CssBaseline />
			<Navbar title={SETTINGS_TITLE} />
			<Box
				className="md:my-5 md:mx-10 items-center"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<p
					className="w-max md:w-full"
					style={{
						display: "flex",
						alignItems: "center",
						fontWeight: 600,
						fontSize: 22,
						margin: "10px 0px",
					}}
				>
					Profile details
				</p>
				{/* <p className="w-max md:w-full">Change your basix account settings here</p> */}

				<Box className="flex flex-wrap-reverse md:flex-nowrap w-full justify-center md:justify-between">
					<Box
						component="form"
						noValidate
						// onSubmit={handleSubmit}
						sx={{ mt: 5, maxWidth: 500 }}
					>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<TextField
									aria-readonly
									name="firstName"
									required
									fullWidth
									id="firstName"
									label="Name"
									autoFocus
									InputProps={{
										readOnly: true,
									}}
									value={patient?.name}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									required
									type="number"
									fullWidth
									label="Age"
									InputProps={{
										readOnly: true,
									}}
									value={age}
								/>
							</Grid>
							<Grid item xs={6}>
								<ToggleButtonGroup
									color="primary"
									value={patient?.gender === 1 ? "male" : "female"}
									exclusive
									aria-label="Platform"
									style={{ borderColor: "#e5e7eb", height: 56 }}
								>
									<ToggleButton
										value="male"
										style={{
											display: "flex",
											flex: 1,
											width: 117,
											borderColor: "#e5e7eb",
										}}
									>
										Male
									</ToggleButton>
									<ToggleButton
										value="female"
										style={{
											display: "flex",
											flex: 1,
											width: 117,
											borderColor: "#e5e7eb",
										}}
									>
										Female
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
					</Box>
					<img src={photo} style={{ width: 400 }} alt="profile" />
				</Box>
			</Box>
		</>
	)
}
