import { Box, CircularProgress } from "@mui/material"
import { Navigate, Outlet } from "react-router-dom"
import dayjs from "dayjs"
import { useRef } from "react"
var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)

function ProtectedRoute({ user, setUserInfo, token, loading }) {
	
	const setUserInfoCount = useRef(0);
	const token_expiry_time = dayjs(new Date(token?.expires_on))
		.utc()
		.local()
		.format()

	const current_time = dayjs(new Date()).utc().local().format()
	
	if (user?.data && ("therapist" in user.data)) {
		return <Navigate to="/therapist" />
	}
	if (
		current_time > token_expiry_time ||
		token_expiry_time === "Invalid Date" || 
		!token?.access_token || !user?.data
	) {
		if (setUserInfoCount.current < 1) {
			setUserInfo({});
			setUserInfoCount.current++;
		}
		return <Navigate to="/auth/login" />
	}

	if (loading) {
		return (
			<Box
				sx={{
					height: "100vh",
					width: "100vw",
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				<Box>
					<CircularProgress />
				</Box>
			</Box>
		)
	}
	
	if(user?.data && !user.data.onboarding_completed) return <Navigate to="/user-info" />

	return <Outlet />;
}

export default ProtectedRoute
