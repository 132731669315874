import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
const PrivacyPolicyModal = ({ handleClose, open, agreeAction }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: windowWidth > 432 ? 400 : "calc(100vw - 32px)",
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="focus:border-none"
    >
      <Box sx={style} className="rounded-lg overflow-hidden focus:outline-none">
        <Box className="h-[90vh] overflow-y-scroll p-8">
          <h1 className="text-2xl font-bold">Privacy Policy</h1>
          <p className="text-base mt-4">Sakina Privacy Policy</p>
          <p className="text-base mt-2 mb-6">Updated 3 December, 2023</p>
          <hr className="border-t-[2px]" />

          <h2 className="text-lg font-bold mt-6">Introduction</h2>
          <p className="text-base mt-4">
            Sakina is devoted to safeguarding the privacy and security of
            personal information belonging to our therapists, partner companies,
            individual users, and consultants. This Privacy Policy sets forth
            our commitment to responsible data management in accordance with the
            UK General Data Protection Regulation (GDPR) and other relevant data
            protection laws.
          </p>

          <h2 className="text-lg font-bold mt-6">Scope and Applicability</h2>
          <p className="text-base mt-4">
            This policy applies to all personal data processed by Sakina,
            including data collected through our website, mobile applications,
            and other platforms. It covers data subjects including therapists,
            employees of partner companies, individual users seeking mental
            health services, and consultants engaged with Sakina.
          </p>

          <h2 className="text-lg font-bold mt-6">
            Data Collection Purposes and Methods
          </h2>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              <span className="font-bold">Therapists</span>: We collect
              professional details, including qualifications, insurance
              information, and availability for scheduling purposes. Background
              and DBS checks are conducted in compliance with legal
              requirements.
            </li>
            <li>
              <span className="font-bold">Partner Companies</span>: Data such as
              feedback, usage statistics, and engagement levels are collected to
              customize and enhance our services. This helps us align our
              offerings with the specific needs of each company.
            </li>
            <li>
              <span className="font-bold">Individual Users</span>: We gather
              data like session attendance, topics discussed, and outcomes to
              track progress and improve user experience. This data is pivotal
              in providing personalized care and assessing the effectiveness of
              therapy sessions.
            </li>
            <li>
              <span className="font-bold">Consultants</span>: We collect
              relevant personal and professional information for effective
              engagement and service delivery.
            </li>
          </ul>
          <h2 className="text-lg font-bold mt-6">
            Calendar Synchronization with Third-Party Service Providers
          </h2>
          <h2 className="text-lg font-bold mt-6">
            Google API Services User Data Policy
          </h2>
          <p className="text-base mt-4">
            Our platform integrates with third-party service providers for
            calendar synchronization, enabling efficient management of bookings
            and availability. This feature helps therapists maintain their
            schedules and assists users in finding convenient session times.
          </p>
          <p className="text-base mt-4">
            Sakina App Ltd use and transfer of information received from Google
            APIs to any other app will adhere to{" "}
            <Link
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
            >
              Google API Services User Data Policy
            </Link>
            , including the Limited Use requirements
          </p>
          <h2 className="text-lg font-bold mt-6">
            Data Storage, Security, and International Transfers
          </h2>
          <p className="text-base mt-4">
            We store personal data on secure, GDPR-compliant servers. Data
            security is a top priority, and we implement measures like
            encryption, access controls, and regular security assessments. For
            international data transfers, we adhere to legal frameworks ensuring
            the protection and lawful handling of data across borders.
          </p>
          <h2 className="text-lg font-bold mt-6">Data Retention Policy</h2>
          <p className="text-base mt-4">
            Our data retention practices are guided by legal requirements and
            operational needs:
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              <span className="font-bold">Therapists and Consultants</span>:
              Data is retained for 6 years after the end of the service
              relationship.
            </li>
            <li>
              <span className="font-bold">Partner Companies</span>: Data is kept
              for the duration of the partnership plus an additional 2 years.
            </li>
            <li>
              <span className="font-bold">Individual Users</span>: Data is
              retained for 7 years following the last session.
            </li>
            <li>
              <span className="font-bold">Data Disposal</span>: Post-retention,
              data is securely deleted or anonymized.
            </li>
          </ul>

          <h2 className="text-lg font-bold mt-6">
            Individual Rights Under GDPR
          </h2>
          <p className="text-base mt-4">
            Individuals have several rights under the GDPR:
          </p>
          <ul className="list-disc ml-5 pl-6 mt-4 text-base">
            <li>
              <span className="font-bold">
                Right to Access, Rectify, and Erase Data
              </span>
              : Individuals can request access to, correction of, or deletion of
              their personal data.
            </li>
            <li>
              <span className="font-bold">
                Right to Restrict Processing and Data Portability
              </span>
              : Individuals can limit how their data is processed and request
              the transfer of their data to another entity.
            </li>
            <li>
              <span className="font-bold">
                Right to Object and Withdraw Consent
              </span>
              : Individuals can object to certain types of data processing and
              withdraw previously given consent.
            </li>
          </ul>

          <h2 className="text-lg font-bold mt-6">
            Cookies and Online Tracking
          </h2>
          <p className="text-base mt-4">
            Our digital platforms use cookies and similar technologies for
            functionality, analytics, and personalized user experiences. Users
            have control over their cookie preferences and consent.
          </p>
          <h2 className="text-lg font-bold mt-6">Complaints Procedure</h2>
          <p className="text-base mt-4">
            We encourage individuals to contact us directly with any data
            protection concerns. If issues are not satisfactorily resolved, they
            can be escalated to the UK Information Commissioner&apos;s Office
            (ICO).
          </p>
          <h2 className="text-lg font-bold mt-6">
            Regular Policy Review and Updates
          </h2>
          <p className="text-base mt-4">
            This Privacy Policy will be regularly reviewed and updated to
            reflect changes in our practices, legal requirements, and user
            feedback. Significant changes will be communicated to our users.
          </p>
          <h2 className="text-lg font-bold mt-6">Training and Awareness</h2>
          <p className="text-base mt-4">
            Sakina commits to regular training for staff members on data
            protection and privacy to ensure compliance with this policy and
            relevant laws.
          </p>
          <h2 className="text-lg font-bold mt-6">
            Contact Information for Privacy Matters
          </h2>
          <p className="text-base mt-4">
            For any inquiries or concerns regarding data privacy, users can
            contact Sakina&apos;s designated Data Protection Officer.
          </p>
          <h2 className="text-lg font-bold mt-6">Children&apos;s Privacy</h2>
          <p className="text-base mt-4">
            Special attention is given to the privacy of children. Our services
            are not directed at children under the age of 18, and we do not
            knowingly collect personal data from this age group.
          </p>
          <p className="text-base mt-4">
            Any questions you may have regarding the processing of your personal
            data should be directed at the Chief Executive Officer of Sakina.
          </p>
          <Box className="w-full flex justify-center mt-8">
            <Button variant="contained" onClick={agreeAction}>
              Agree
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrivacyPolicyModal;
