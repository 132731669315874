import { useQuery } from "@tanstack/react-query"
import React, { useEffect } from "react"
import { useStore, useStoreTemporary } from "../../../Zustand"
import TherapistCard from "./Therapists/TherapistCard"
import ApiRequests from "../../api/ApiRequests"
import LoadingPage from "../../../components/Cards/LoadingPage"
import Navbar from "./Navbar"
import { ALL_THERAPISTS_TITLE } from "../helpers/constants"

function MyTherapists({showNavbar=true}) {
	const { getTherapistsList } = ApiRequests()
	const { sidebarWidth } = useStoreTemporary()

	const { data, isError, isLoading } = useQuery(["therapist-list"], getTherapistsList)
	const scrollToTop = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}
	console.log('Therapist List', data, "isError", isError)
	useEffect(() => {
		if (!isLoading) {
			scrollToTop();
		}
	},[isLoading])

	if (isLoading) return <LoadingPage />
	if (isError) return (
		<>
			{
				showNavbar ? <Navbar title={ALL_THERAPISTS_TITLE} /> : null
			}
			<h2>Error...</h2>
		</>
	)
	return (
		<>
			{
				showNavbar ? <Navbar title={ALL_THERAPISTS_TITLE} /> : null
			}
			<div
				style={{
					overflowY: "scroll",
					overflow: "hidden",
					width: window.innerWidth >=768 ? `calc(100vw - ${sidebarWidth}px)` : `calc(100vw - 32px)`,
					marginTop: "30px",
				}}
			>
				<h3
					style={{ margin: 10, fontWeight: 600, fontSize: 19, color: "#5f616a" }}
				>
					Matched Therapists
				</h3>
				<div
					className="md:ml-2.5 md:gap-5 gap-2"
					style={{
						display: "flex",
						flexWrap: "wrap",
						overflowX: "auto",
						marginBottom: 45,
						alignItems: "stretch"
					}}
				>
					{data?.data?.therapists.map((item, index) => (
						<TherapistCard details={item} key={`thcard-${index}`} />
					))}
				</div>
				{/* <ConditionsTags /> */}
			</div>
		</>
	)
}

export default MyTherapists
