import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function OnProccess() {
  return (
    <div style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px",
        textAlign: "center",
        flexDirection: "column",
    }}>
      <Typography variant="h4">
            Please login on desktop version
      </Typography>
      <Link to="/auth/therapist/signin" className="mt-10 text-lg text-blue-500">
            Login Page
      </Link>
    </div>
  );
}

export default OnProccess;
