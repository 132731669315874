import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Alert, Box, Button } from "@mui/material";
import "../Style.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useExternalBookStore, useTokenStore } from "../../../../../../Zustand";
import { createAxios } from "../../../../helpers/createAxios";
import LoadingPage from "../../../../../../components/Cards/LoadingPage";
import ConfirmBooking from "../../../../User/Components/ConfirmBooking";
import SnackbarX from "../../../../User/Components/SnackbarX";
import CustomDatePicker from "../../../../User/Components/TimePicker";
import ApiRequests from "../../../../../api/ApiRequests";
import { useNavigate } from "react-router-dom";

const timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);

export default function BookingDayPicker({ therapistInfo, open, setOpen }) {
  const { getPublicCalendarAvailability } = ApiRequests();
  const queryClient = useQueryClient();
  const [time, setTime] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isForCouples, setIsForCouples] = React.useState(false);
  const [isFreeTrial, setIsFreeTrial] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const { bookInfo, setBookInfo } = useExternalBookStore();
  const navigate = useNavigate();
  const date = dayjs(value).format("YYYY-MM-DD");

  const { data, isLoading, isError, refetch, isFetching } = useQuery(
    ["availability"],
    () => {
      const duration = isFreeTrial
        ? 15
        : isForCouples
        ? therapistInfo?.session_cost?.couple_cost_duration_minutes
        : therapistInfo?.session_cost?.individual_cost_duration_minutes;

      return getPublicCalendarAvailability({
        from_date: getDateWithTime(date),
        to_date: date + "T23:59:59.000Z",
        therapist_id: therapistInfo.id,
        time_slot_duration: duration,
      });
    }
  );

  const getDateWithTime = (dateString) => {
    // Parse the provided date
    const providedDate = dayjs(dateString);
    const now = dayjs();

    // Check if the provided date is today
    if (providedDate.isSame(now, "day")) {
      // Return current time in ISO 8601 format
      return now.toISOString();
    } else {
      // Return provided date at midnight in ISO 8601 format
      return providedDate.startOf("day").toISOString();
    }
  };

  React.useEffect(() => {
    refetch();
    setTime("");
  }, [value, isForCouples, isFreeTrial, refetch]);

  const availableHours = data?.data.time_slots?.map((slot) =>
    new Date(slot.start_time).getHours()
  );
  const uniqueHours = [...new Set(availableHours)]?.map((hour: any) => {
    return ConvertTimetoUTC(hour);
  });

  function ConvertTimetoUTC(hour): string {
    const newDateFormat = date.split("-").map((str) => parseInt(str));
    if (hour)
      return new Date(
        newDateFormat[0],
        newDateFormat[1] - 1,
        newDateFormat[2],
        hour,
        0,
        0
      )?.toISOString();
  }

  function dayBeforeToday(date) {
    return dayjs(date).isBefore(dayjs(), "day");
  }

  const confirmBooking = () => {
    const info = { therapistInfo, date, time };
    setBookInfo(info);
    console.log(bookInfo, info);
    
    navigate("book");
  };

  if (isLoading || loading) return <LoadingPage />;
  if (isError) return <h1>Error...</h1>;
  if (error) return <p>{error}</p>;

  return (
    <Box>
      {/* <ConfirmBooking
        therapist={therapistInfo}
        open={open}
        setOpen={setOpen}
        setOpenSnackbar={setOpenSnackbar}
        handleMutate={() => payForSession(time)}
        isForCouples={isForCouples}
        date={date}
        time={time}
      /> */}
      <SnackbarX
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        backgroundColor="#32a676"
        message="The session has been successfully booked!"
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          padding: "10px 25px 0px",
        }}
      >
        <p
          onClick={() => setIsFreeTrial(!isFreeTrial)}
          style={{
            color: "rgb(95 106 196 / 68%)",
            padding: "3px 10px",
            background: isFreeTrial ? "rgb(95 106 196 / 18%)" : "white",
            border: isFreeTrial
              ? "2px solid rgb(95 106 196 / 68%)"
              : "1px solid rgb(95 106 196 / 68%)",
            borderRadius: 6,
            fontWeight: isFreeTrial ? "bold" : "none",
            fontSize: 12,
            textAlign: "center",
            height: "fit-content",
            cursor: "pointer",
            width: "100%",
          }}
        >
          ⌛️ Free Trial / 15 min
        </p>
        <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
          <p
            onClick={() => setIsForCouples(false)}
            style={{
              width: "100%",
              color: "rgb(95 106 196 / 68%)",
              padding: "3px 10px",
              background: !isForCouples ? "rgb(95 106 196 / 18%)" : "white",
              border: !isForCouples
                ? "2px solid rgb(95 106 196 / 68%)"
                : "1px solid rgb(95 106 196 / 68%)",
              borderRadius: 6,
              fontWeight: !isForCouples ? "bold" : "none",
              fontSize: 12,
              cursor: "pointer",
            }}
          >
            🧕 Individual
          </p>
          <p
            onClick={() => setIsForCouples(true)}
            style={{
              width: "100%",
              color: "rgb(95 106 196 / 68%)",
              padding: "3px 10px",
              background: isForCouples ? "rgb(95 106 196 / 18%)" : "white",
              border: isForCouples
                ? "2px solid rgb(95 106 196 / 68%)"
                : "1px solid rgb(95 106 196 / 68%)",
              borderRadius: 6,
              fontWeight: isForCouples ? "bold" : "none",
              fontSize: 12,
              cursor: "pointer",
            }}
          >
            👩‍❤️‍👨 Couples
          </p>
        </Box>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          shouldDisableDate={(date) => dayBeforeToday(date)}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Box
        style={{
          padding: "0px 20px",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          margin: 0,
        }}
      >
        {isFetching ? (
          <LoadingPage />
        ) : uniqueHours?.length === 0 ? (
          <Alert sx={{ width: "100%", m: 1 }} severity="error">
            No available slot
          </Alert>
        ) : (
          <CustomDatePicker
            timeslots={data?.data.time_slots}
            setTime={setTime}
          />
        )}
      </Box>

      <Box
        style={{
          width: "100%",
          background: "rgb(245, 245, 245)",
          padding: "10px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "20px auto 10px",
          maxWidth: 270,
          fontSize: 14,
          borderRadius: "6px",
        }}
      >
        <p style={{ fontWeight: 600 }}>Chosen Date and Time</p>
        <p>{date}</p>
        <p>
          {dayjs(time).format("HH:mm") !== "Invalid Date"
            ? dayjs(time).format("HH:mm")
            : "Please choose time"}
        </p>
        <Button
          disabled={time === ""}
          onClick={confirmBooking}
          variant="contained"
          style={{
            borderRadius: 20,
            background: "rgb(226, 109, 128)",
            width: 100,
            fontWeight: 600,
            marginTop: 15,
          }}
        >
          Book
        </Button>
      </Box>
    </Box>
  );
}
