import axios from "axios"

export const createAxios = (token) => {
	const Axios = axios.create({
		baseURL: process.env.REACT_APP_API_ENDPOINT,
		headers: {
			"Content-Type": "application/json",
			accept: "application/json",
			authorization: "Bearer " + token,
		},
		timeout: 8000,
	})

	return Axios
}
