import { Checkbox, FormControlLabel } from "@mui/material";
import Link from "@mui/material/Link";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { useState } from "react";
export default function TermsAndConditions({ termsChecked, setTermsChecked, isOnboarding = false }) {
  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const agreeAction = () => {
    handleClose();
    setTermsChecked(true);
  };
  return (
    <>
      <div className={`p-4 ${isOnboarding ? "pl-0 pb-0": ""}`}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label={
            <span>
              I agree to the{" "}
              <Link
                onClick={(e) => handleOpen(e)}
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 ml-1"
              >
                Terms of Business
              </Link>
            </span>
          }
        />
      </div>
      <TermsAndConditionsModal
        handleClose={handleClose}
        open={open}
        agreeAction={agreeAction}
      />
    </>
  );
}
