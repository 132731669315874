import axios from "axios"
import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import LoginIcon from "@mui/icons-material/Login"
import Typography from "@mui/material/Typography"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { Alert, IconButton, InputAdornment } from "@mui/material"
import { useToastStore, useTokenStore } from "../../../Zustand"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"
import Toast from "../../sakinah/helpers/Toast"

export default function SignIn() {
	const navigate = useNavigate()
	const [email, setEmail] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [showPassword, setShowPassword] = React.useState(false)
	const [error, setError] = React.useState("")
	const { token, setToken } = useTokenStore()
	const { showToast, message, closeToast } = useToastStore();

	async function signIn() {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/session/log-in`,
				{
					email: email,
					session_type: 1,
					password: password,
				}
			)
			setToken(response.data)
			navigate("/")
		} catch (error) {
			console.log(error)
			setError("Invalid email or password")
		}
	}
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		signIn()
	}

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	console.log("token :>> ", token)

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				maxWidth: { xs: "100%", md: "450px" },
				background: "#5f6ac40f",
				borderRadius: "8px",
				margin: "10px",
			}}
			className="flex flex-col items-center bg-[#5f6ac40f] m-2.5 sm:pt-[30px] sm:p-10 p-4 rounded-lg"
		>
			<CssBaseline />
			<Toast
				message={message}
				show={showToast}
				onClose={closeToast}
			/>
			<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
				<LoginIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				Sign in
			</Typography>
			<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
				<TextField
					margin="normal"
					required
					fullWidth
					id="email"
					label="Email Address"
					name="email"
					autoComplete="email"
					autoFocus
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: <InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}}
					id="password"
					autoComplete="current-password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<FormControlLabel
					control={<Checkbox value="remember" color="primary" />}
					label="Remember me"
				/>
				{error && <Alert severity="error">{error}</Alert>}
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					Sign In
				</Button>
				<Link to={`/auth/therapist/signin`}>
					<Button
						fullWidth
						variant="outlined"
						color="error"
						className="md:!mt-6 md:!mb-4 m-0 !mb-2"
					>
						Sign In as a Therapist
					</Button>
				</Link>
				<Grid container style={{ display: "flex", justifyContent: "column" }}>
					<Grid
						item
						margin="auto"
						style={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<NavLink to="/auth/forgot-password/1">
							<span style={{ textDecoration: "underline", color: "#1976d2" }}>
								Forgot password?
							</span>
						</NavLink>
					</Grid>
					<Grid
						item
						margin="auto"
						style={{
							width: "100%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginBottom: 10,
						}}
					>
						<NavLink to="/auth/register">
							Don't have an account?{" "}
							<span style={{ textDecoration: "underline", color: "#1976d2" }}>
								Sign Up
							</span>
						</NavLink>
					</Grid>
				</Grid>
				{/* <p style={{ textAlign: "center", color: "grey", margin: 0 }}>or</p>
				<Link to="/auth/login-phone">
					<Button fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
						Sign In with Phone
					</Button>
				</Link> */}
			</Box>
		</Box>
	)
}
