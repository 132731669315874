import React from "react"
import data from "./Data/DummyData"
import TabComponent from "./TabComponent"
import TableCustomerProfile from "./Tables/TableCustomerProfile"
import TableSales from "./Tables/TableSales"
import PayoutHistory from "./PayoutHistory"
import Onboarding from "../Onboarding/Onboarding"
import Onboarding2 from "../Onboarding/Onboarding2"
import WorkingDays from "./WorkingDays"
import CalendarTimeOff from "./Calendars/CalendarTimeOff"

const TabOnboarding = () => {
	const [openTab, setOpenTab] = React.useState(1)
	const tableHead = ["Date", "Time", "Status", "Paid", "Action"]

	return (
		<>
			<div className="flex">
				<div className="w-full">
					<TabComponent
						tab1="Personal Details"
						tab2="Topics Covered"
						tab3=""
						tab4=""
						openTab={openTab}
						setOpenTab={setOpenTab}
						color="blue"
					/>
					<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
						<div className="px-4 py-5 flex-auto">
							<div className="tab-content tab-space">
								<div className={openTab === 1 ? "block" : "hidden"} id="link1">
									{/* Grid of profile information of the user : Phone, email, location */}
									<Onboarding
										hidden={true}
										prevPage=""
										nextPage=""
										text="Save Changes"
									/>
								</div>
								{/* <div className={openTab === 2 ? "block" : "hidden"} id="link2">
								{/* <div className={openTab === 2 ? "block" : "hidden"} id="link2">
									<Onboarding2
										hidden={true}
										prevPage=""
										nextPage=""
										text="Save Changes"
									/>
									{/* Here should be what it should be showed when clicked on tab2 
								</div> */}
								<div className={openTab === 2 ? "block" : "hidden"} id="link2">
									<Onboarding2
										hidden={true}
										prevPage="/therapist/onboarding/2"
										nextPage="/therapist/"
										text="Save Changes"
									/>
									{/* Here should be what it should be showed when clicked on tab3 */}
								</div>
								<div className={openTab === 4 ? "block" : "hidden"} id="link2">
									<div className="m-5 flex">
										{/* <WorkingDays /> */}
										{/* <CalendarTimeOff therapist={true} /> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default TabOnboarding
