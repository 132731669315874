// @ts-nocheck
import React, { useState } from "react"

import images from "../../../../../assets/images/images"

function AvailabilityManagement() {
	const [showAnswer1, setShowAnswer1] = useState(false)
	const [showAnswer2, setShowAnswer2] = useState(false)
	const [showAnswer3, setShowAnswer3] = useState(false)

	return (
		<div className="faq md:container mx-auto sm:my-16">
			<p className="my-14 font-medium text-xl  text-center leading-none">
				The default availability on Sakina is set from
				<span className="text-green-600">
					Monday to Sunday (7 days a week) from 9:00 to 18:00 UK Time
				</span>
				, giving you the flexibility to manage your availability as it fits your
				schedule.{" "}
			</p>
			<ul className="p-0">
				<li className="p-5 mb-5 bg-gray-50">
					<div
						className="question font-medium text-lg cursor-pointer"
						onClick={() => setShowAnswer1(!showAnswer1)}
					>
						How can I set my availability (i.e. working hourse) on Sakina?
					</div>
					<div
						className={`answer text-gray-700 pt-3 ${
							showAnswer1 ? "block" : "hidden"
						}`}
					>
						Your availability on Sakina can be set from Monday to Sunday (7 days a
						week) from 9:00 to 18:00 UK Time. This allows you the flexibility to
						manage your availability according to your schedule.
					</div>
					<div className={`answer pt-3 ${showAnswer1 ? "block" : "hidden"}`}>
						<div className="my-5">
							<h4 className="font-medium text-lg mt-4">
								Step 1: Open Google Calendar and Select Working hours
							</h4>
							<img
								src={images.workingHours1}
								alt="Screenshot 1. Working Hours"
								className="md:w-3/4 mt-2"
							/>
							<p>
								Step 1: Open your Google Calendar on your preferred device, and select
								working hours 9:00 - 18:00 UK Time
							</p>
							<p>Step 2: Click on "More options"</p>
						</div>
						<div className="my-5">
							<h4 className="font-medium text-lg mt-4">
								2. Title "Block" and Click "Custom"
							</h4>
							<img
								src={images.workingHours2}
								alt="Screenshot 2. Working Hours"
								className="md:w-3/4 mt-2"
							/>
							<p>
								Step 3 and 4: Change the title to "Block" and click on "Custom" to
								select the days of the week
							</p>
						</div>
						<div className="my-5">
							<h4 className="font-medium text-lg mt-4">
								3. Select the days you want off
							</h4>
							<img
								src={images.workingHours3}
								alt="Screenshot 3. Working Hours"
								className="md:w-3/4 mt-2"
							/>
							<p>
								Step 5: Select the days you want to be off, here I am selecting Monday,
								Friday as off. So every Monday and Friday, I will be off until I change
								it otherwise in the future.
							</p>
						</div>
						<div className="my-5">
							<h4 className="font-medium text-lg mt-4">4. Double Check</h4>

							<img
								src={images.workingHours4}
								alt="Screenshot 4. Working Hours"
								className="md:w-3/4 mt-2"
							/>
							<p>
								Step 6: Check if everything is correct. Preferably turn off
								notifications so that you won't receive notifications that it's your off
								day :)
							</p>
						</div>
					</div>
				</li>

				<li className="p-5 mb-3 bg-gray-50">
					<div
						className="question font-medium text-lg cursor-pointer"
						onClick={() => setShowAnswer3(!showAnswer3)}
					>
						How can I block times for lunch breaks or holidays?
					</div>
					<div
						className={`answer text-gray-700 pt-3 ${
							showAnswer3 ? "block" : "hidden"
						}`}
					>
						<h4 className="font-medium text-lg mt-4 text-red-500">
							1. How to block your lunchtime:
						</h4>
						<img
							src={images.lunchTimes}
							alt="Screenshot - Blocking Lunchtime"
							className="md:w-3/4 mt-2"
						/>
						<p>Step 1: Select the time you want to block for lunch.</p>
						<p>Step 2: Ensure that it is repeated daily.</p>

						<h4 className="font-medium text-lg mt-4 text-red-500">
							{" "}
							2. How to block holidays:
						</h4>
						<img
							src={images.holiday1}
							alt="Screenshot - Blocking Holidays 1"
							className="md:w-3/4 mt-2"
						/>
						<p>
							Step 1: Select the date you want to start your holiday and click on "More
							options".
						</p>

						<img
							src={images.holiday2}
							alt="Screenshot - Blocking Holidays 2"
							className="md:w-3/4 mt-2"
						/>
						<p>
							Step 2: Select the range of dates you want to have your holiday. Make
							sure the time is between 9:00 - 18:00 UK Time.
						</p>
					</div>
				</li>
			</ul>
		</div>
	)
}

export default AvailabilityManagement
