import create from 'zustand'
import {
    persist,
    createJSONStorage
} from 'zustand/middleware'


const useStoreTemporary = create((set) => ({
    snackbarOpen: false,
    setSnackbarOpen: () => set({
        snackbarOpen: true
    }),
    setSnackbarOpenFalse: (open) => set({
        snackbarOpen: open
    }),
    sidebarWidth: 300,
    setSidebarWidth: (width) => set({
        sidebarWidth: width
    }),
    onboardingComplete: false,
    setOnboardingComplete: () => set({
        onboardingComplete: true
    }),
}))

const useSidebarStore = create((set) => ({
    sidebarOpen: false,
    setSidebarState: (isOpen) => set({
        sidebarOpen: isOpen
    }),
    sidebarWidth: 250,
    setSidebarWidth: (width) => set({
        sidebarWidth: width
    })
}))


const useStore = create(
    persist(
        (set, get) => ({
            therapistsList: [],
            setTherapistsList: (list) => set((state) => ({
                therapistsList: list
            })),
            upcomingSessionState: [],
            setUpcomingSession: (session) => set((state) => ({
                upcomingSessionState: session
            })),
            // setUpcomingSession: (session) => set((state) => ({ upcomingSessionState: [...state.upcomingSessionState, session] })),
            removeSessions: () => set({
                upcomingSessionState: []
            }),
        }), {
            name: 'therapy-session', // unique name
            getStorage: () => sessionStorage,
        }
    )
)


const useStoreUser = create(
    persist(
        (set, get) => ({
            userInfo: {},
            setUserInfo: (user) => set({
                userInfo: user
            }),
            userLogin: {},
            setUserLogin: (user) => set({
                userLogin: user
            }),
        }), {
            name: 'current-user', // unique name
            getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
        }
    )
)


const useTokenStore = create(
    persist(
        (set, get) => ({
            token: {},
            setToken: (tokenX) => set({
                token: tokenX
            }),
        }), {
            name: 'user-access-token', // unique name
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        }
    )
)

const useFetchBookings = create(
    persist(
        (set, get) => ({
            events: [],
            setEvents: (info) => set((prev) => ({
                events: [...prev.info, ...info]
            })),
        }), {
            name: 'fetched-bookings-data', // unique name
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        }
    )
)



const useTherapistInfo = create((set, get) => ({
    info: {
        about_me: "",
        date_of_birth: "",
        bank_account: {
            account_holder_name: "",
            account_number: "",
            sort_code: "",
        },
        city: "",
        country_code: "GB",
        full_years_of_practice: null,
        gender: null,
        higher_education: "",
        languages_id: [],
        medical_conditions_id: [],
        memberships: "",
        name: "",
        phone_number: "",
        professional_achievements: "",
        session_cost: {
            couple_cost: null,
            couple_cost_duration_minutes: 90,
            currency: "GBP",
            individual_cost: null,
            individual_cost_duration_minutes: 50,
        },
        total_therapy_session_count: null,
        surname: "",
        title: "",
    },
    setInfo: (item) => set((prev) => ({
        info: {
            ...prev.info,
            ...item
        }
    })),
}))

const useToastStore = create((set) => ({
    showToast: false,
    message: "",
    openToast: (message) => set({ showToast: true, message }),
    closeToast: () => set({ showToast: false, message: "" }),
}));

const useCounterStore = create((set) => ({
    count: 0,
    increment: () => set((state) => ({ count: state.count + 1 })),
    decrement: () => set((state) => ({ count: state.count - 1 })),
    reset: () => set({ count: 0 }),
}));

const useExternalBookStore = create(
    persist((set) => ({
        bookInfo: {},
        setBookInfo: (bookInfo) => set({ bookInfo: bookInfo }),
    }), {
        name: 'external-book-store',
        getStorage: () => sessionStorage,
    })
);

export {
    useStore,
    useFetchBookings,
    useStoreTemporary,
    useStoreUser,
    useTokenStore,
    useTherapistInfo,
    useToastStore,
    useSidebarStore,
    useCounterStore,
    useExternalBookStore
}