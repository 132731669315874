import { Chip } from "@mui/material";

export default function MedicalConditionChip({ name }) {
//   const getRandomColor = () => {
//     const red = Math.floor(Math.random() * 256); // Random between 0-255
//     const green = Math.floor(Math.random() * 256); // Random between 0-255
//     const blue = Math.floor(Math.random() * 256); // Random between 0-255
//     // Convert each color component to a hexadecimal string
//     const redHex = red.toString(16).padStart(2, "0");
//     const greenHex = green.toString(16).padStart(2, "0");
//     const blueHex = blue.toString(16).padStart(2, "0");

//     // Concatenate the hex strings and prepend with a hash
//     return `#${redHex}${greenHex}${blueHex}`; // Construct an RGB color string
//   };
  const chipStyle = {
    // backgroundColor: getRandomColor(),
    backgroundColor: "#241e4c80",
  };
  return (
    <div className="mt-2 flex flex-wrap gap-2">
      <Chip
        variant="outlined"
        label={name}
        className={`!text-white !rounded-[10px] !p-[6px] !h-[35px] !text-base`}
        style={chipStyle}
      />
    </div>
  );
}
