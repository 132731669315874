const TabsCustomer = ({ client }) => {
  return (
    <>
      <div className="px-4 py-5 flex-auto shadow-md rounded-lg relative flex flex-col text-sm min-w-0 break-words max-w-full md:max-w-lg m-auto">
        {/* Grid of profile information of the user : Phone, email, location */}
        <div className="grid md:grid-cols-2 grid-cols-[auto] md:gap-3 gap-2 ">
          <div className="text-gray-500 text-base md:text-sm">Email:</div>
          <div>{client?.patient.email}</div>
          <div className="text-gray-500 text-base md:text-sm">Phone number:</div>
          <div>{client?.patient.mobile_number}</div>
          <div className="text-gray-500 text-base md:text-sm">Gender:</div>
          <div>{client?.patient.gender === 1 ? "Male" : "Female"}</div>

          <div className="text-gray-500 text-base md:text-sm">Tags</div>
          <div className="flex gap-1">
            {client?.medical_conditions.map((tag, index) => (
              <span className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded text-sky-700 bg-sky-200" key={`mc_${index}`}>
                {tag.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TabsCustomer;
