import React from "react";
import welcomeImage from "../images/welcomeImage.svg";

function WelcomeMessage(props) {
  return (
    <div
      className="bg-[#5f6ac4] p-4 sm:pt-5 sm:pb-8 sm:px-8 lg:max-w-[450px] h-[calc(100%-20px)] flex-1 text-white m-5 rounded-lg self-stretch"
      style={{
        flex: 1,
        margin: 10,
        borderRadius: 8,
        color: "white"
      }}
    >
      <h3 className="font-semibold text-[22] mb-3 sm:mb-5">
        As-salamu aleikum! 🙂
      </h3>
      <p className="mb-2 sm:mb-4">
        We are glad that you have taken the step to seek support and guidance
        for your mental health and well-being. We are here to provide a safe and
        confidential space for you to share your thoughts, feelings, and
        experiences.
      </p>
      <p>
        May Allah bless and guide you on your journey towards healing and
        growth.
      </p>
      <img src={welcomeImage} className="w-[200px] sm:w-[300px] md:w-[400px] mx-auto" alt="" />
    </div>
  );
}

export default WelcomeMessage;
