import React, { useState } from "react"
import { Box } from "@mui/system"
import { Link } from "react-router-dom"
import HomeIcon from "@mui/icons-material/Home"
import { useLocation } from "react-router-dom"
import { auth } from "../../../Firebase"
import { signOut } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import ShieldMoonIcon from "@mui/icons-material/ShieldMoon"
import LogoutIcon from "@mui/icons-material/Logout"
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"

import {
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material"
import VideoChatIcon from "@mui/icons-material/VideoChat"
import PersonIcon from "@mui/icons-material/Person"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import SettingsIcon from "@mui/icons-material/Settings"
import { useSidebarStore, useStoreTemporary, useTokenStore } from "../../../Zustand"

function Sidebar() {
	const location = useLocation()
	const navigate = useNavigate()
	const [hide, setHide] = useState(true)
	const [isShown, setIsShown] = useState(false)
	const { setSidebarWidth } = useStoreTemporary()
	const { sidebarOpen, setSidebarState } = useSidebarStore()
	const { setToken } = useTokenStore()

	const handleLogout = () => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				navigate("/auth/login")
				setToken({})
				console.log("Signed out successfully")
			})
			.catch((error) => {
				// An error happened.
			}).finally(() => {
				closeSideMenu();
			});
	}

	const closeSideMenu = () => {
		setSidebarState(false);
	};
	
	return (
		<>
			{
				sidebarOpen ? 
					<div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 z-[11]" onClick={()=>setSidebarState(false)}></div>
				: null
			}
				<Box className={`
						bg-white
						flex flex-col fixed
						!z-20
						md:relative
						overflow-x-hidden 
						${hide ? "md:w-[300px] md:p-4" : "md:w-[90px] md:p-4"}
						${sidebarOpen ? " w-[250px] p-4" : "w-[0px]"}
						h-[100vh] overflow-auto
						`
					}
					style={{
						overflowY: "auto",
						overflowX: "hidden",
						transition: "width 0.3s ease-out, padding 0.3s ease-in-out",
					}}
					onMouseEnter={() => setIsShown(true)}
					onMouseLeave={() => setIsShown(false)}
				>
					<Link to="/" style={{ display: "flex", marginLeft: !hide && 10 }} onClick={closeSideMenu}>
						<ShieldMoonIcon
							style={{ width: 35, height: 35, marginRight: 5, color: "#5f6ac4" }}
						/>
						<h2
							style={{
								fontWeight: 600,
								fontSize: 22,
								alignItems: "center",
								color: "#323331",
								marginTop: 0,
								marginLeft: 2,
								maxWidth: 180,
								minWidth: 170,
								display: hide ? "flex" : "none",
							}}
						>
							{" "}
							Sakina App
						</h2>
					</Link>
					<IconButton
						className="!hidden md:!block"
						onClick={() => (
							setHide(!hide),
							setIsShown(!isShown),
							hide ? setSidebarWidth(90) : setSidebarWidth(300)
						)}
						style={{
							position: "absolute",
							top: hide ? 15 : 60,
							right: hide ? 2 : 22,
							color: "#BAB9CC",
							display: isShown ? "flex" : "none",
						}}
					>
						{hide ? (
							<KeyboardDoubleArrowLeftIcon />
						) : (
							<KeyboardDoubleArrowRightIcon />
						)}
					</IconButton>
					<Box
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "column",
							marginTop: 66,
						}}
					>
						<List>
							{data.map((item, index) => (
								<Link key={index} to={item.link} onClick={closeSideMenu}>
									<ListItem disablePadding>
										<ListItemButton
											className="onHover"
											style={{
												background:
													location.pathname == item.link ? "#5f6ac4" : "inherit",
												color: location.pathname == item.link ? "white" : "#BAB9CC",
												borderRadius: 5,
												marginBottom: 5,
											}}
										>
											<ListItemIcon style={{ color: "inherit" }}>
												{item.icon}
											</ListItemIcon>
											<ListItemText primary={item.name} />
										</ListItemButton>
									</ListItem>
								</Link>
							))}
						</List>
						<Box>
							<Link to="/settings" onClick={closeSideMenu}>
								<ListItem disablePadding>
									<ListItemButton
										className="onHover"
										style={{
											background: location.pathname.includes("settings")
												? "#5f6ac4"
												: "inherit",
											color: location.pathname.includes("settings")
												? "white"
												: "#BAB9CC",
											borderRadius: 5,
											marginBottom: 5,
										}}
									>
										<ListItemIcon style={{ color: "inherit" }}>
											<SettingsIcon />
										</ListItemIcon>
										<ListItemText primary="Settings" />
									</ListItemButton>
								</ListItem>
							</Link>
							<ListItem disablePadding>
								<ListItemButton
									onClick={handleLogout}
									className="onHover"
									style={{
										color: "#BAB9CC",
										borderRadius: 5,
										marginBottom: 5,
									}}
								>
									<ListItemIcon style={{ color: "inherit" }}>
										<LogoutIcon />
									</ListItemIcon>
									<ListItemText primary="Log out" />
								</ListItemButton>
							</ListItem>
						</Box>
					</Box>
				</Box>
		</>
	)
}

export default Sidebar

const data = [
	{
		name: "Dashboard",
		link: "/",
		icon: <HomeIcon />,
	},
	{
		name: "My Sessions",
		link: "/sessions",
		icon: <VideoChatIcon />,
	},
	{
		name: "All Therapists",
		link: "/therapist-list",
		icon: <PersonIcon />,
	},
	// {
	// 	name: "Conditions",
	// 	link: "/preferences",
	// 	icon: <AcUnitIcon />,
	// },
]
