import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined"
import RedoIcon from "@mui/icons-material/Redo"
import ClearIcon from "@mui/icons-material/Clear"
import { IconButton } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"

const ButtonWithPopover = ({ open, onClose, handleLogout, text, title }) => {
	const styleModel = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		border: "0px solid #000",
		boxShadow: 24,
		p: 4,
	}

	return (
		<>
			<Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={styleModel}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						{title}
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						{text}{" "}
					</Typography>
					<div className="mt-3 pt-3 ">
						<button className="btn-secondary" onClick={onClose}>
							Cancel
						</button>
						<button className="btn-primary" onClick={handleLogout}>
							Proceed
						</button>
					</div>
				</Box>
			</Modal>
		</>
	)
}

export default ButtonWithPopover
