const PROFILE_TITLE = 'Profile';
const SETTINGS_TITLE = 'Settings';
const MY_SESSIONS_TITLE = 'My sessions';
const ALL_THERAPISTS_TITLE = 'All therapists';
const THERAPIST_PROFILE_TITLE = 'Therapist profile';
export {
    PROFILE_TITLE,
    SETTINGS_TITLE,
    MY_SESSIONS_TITLE,
    ALL_THERAPISTS_TITLE,
    THERAPIST_PROFILE_TITLE
}