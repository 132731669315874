import { Checkbox, FormControlLabel } from "@mui/material";
import Link from "@mui/material/Link";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import { useState } from "react";
export default function PrivacyPolicy({ privacyChecked, setPrivacyChecked }) {
  const handleCheckboxChange = (event) => {
    setPrivacyChecked(event.target.checked);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const agreeAction = () => {
    handleClose();
    setPrivacyChecked(true);
  };
  return (
    <>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={privacyChecked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label={
            <span>
              I agree to the{" "}
              <Link
                onClick={(e) => handleOpen(e)}
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 ml-1"
              >
                Privacy Policy
              </Link>
            </span>
          }
        />
      </div>
      <PrivacyPolicyModal
        handleClose={handleClose}
        open={open}
        agreeAction={agreeAction}
      />
    </>
  );
}
