import React, { useEffect, useState } from "react"
import data from "./Data/DummyData"
import TabComponent from "./TabComponent"
import TableCustomerProfile from "./Tables/TableCustomerProfile"
import TableSales from "./Tables/TableSales"
import PayoutHistory from "./PayoutHistory"
import { useStoreUser } from "../../../../Zustand"
import ApiRequests from "../../../api/ApiRequests"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

const TabPayments = ({ color, tab1, tab2, tab3, tab4 }) => {
	const [openTab, setOpenTab] = React.useState(1)
	const tableHead = ["Date", "Time", "Name", "Status", "Action"]
	const { userInfo, setUserInfo } = useStoreUser()
	const therapistId = userInfo?.data?.therapist?.id
	const { id } = useParams()
	const { getBookingList } = ApiRequests()
	const [allTransactions, setAllTransactions] = useState([])

	const {
		data: bookingData,
		isError,
		isLoading,
	} = useQuery({
		queryKey: ["bookings-data-for-transactions"],
		queryFn: () => getBookingList(),
	})

	useEffect(() => {
		if (bookingData?.data?.bookings) {
			setAllTransactions(
				bookingData?.data?.bookings?.filter(
					(event) =>
						event?.therapist_id === therapistId &&
						event?.status === "succeeded_payment_checkout"
						||
						event?.status === "refund_completed" ||
						event?.status === "free_trial"
				)
			)
		}
	}, [bookingData, id])
	if (isError) return <h1>Error</h1>
	if (isLoading) return <h1>Loading...</h1>

	return (
		<>
			<div className="flex">
				<div className="w-full">
					<TabComponent
						tab1={tab1}
						tab2={tab2}
						tab3={tab3}
						tab4={tab4}
						openTab={openTab}
						setOpenTab={setOpenTab}
						color={color}
					/>
					<div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6">
						<div className="tab-content tab-space">
							<div className={openTab === 1 ? "block" : "hidden"} id="link1">
								{/* Grid of profile information of the user : Phone, email, location */}

								<TableSales tableHead={tableHead} events={allTransactions} />
							</div>
							<div className={openTab === 2 ? "block" : "hidden"} id="link2">
								<PayoutHistory />
								{/* Here should be what it should be showed when clicked on tab2 */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default TabPayments
