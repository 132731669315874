//@ts-nocheck
import React, { useState, useEffect } from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import CheckboxesGroup from "../../User/Components/CheckboxesGroup"
import { Link, useNavigate } from "react-router-dom"
import {
	Container,
	Grid,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	FormControl,
} from "@mui/material"
import { useTherapistInfo, useStoreUser } from "../../../../Zustand"
import { useForm } from "react-hook-form"
import { upperCaseFirst } from "upper-case-first"

import InputValidated from "../Components/InputValidated";
import ApiRequests from "../../../api/ApiRequests";
import Toast from "../../helpers/Toast"
import TermsAndConditions from "../Components/Onboarding/TermsAndConditions"
import PrivacyPolicy from "../Components/Onboarding/PrivacyPolicy"

const Onboarding2 = ({ hidden, text, prevPage, nextPage }) => {
	const [preferences, setPreferences] = React.useState([])
	const { info, setInfo } = useTherapistInfo()
	const { setUserInfo } = useStoreUser()
	const [imageFile, setImageFile] = useState(null)
	const [showToast, setShowToast] = useState(false)
	const [termsChecked, setTermsChecked] = useState(false);
	const [privacyChecked, setPrivacyChecked] = useState(false);
	console.log("INFOO: ", info);

	/// images
	const [myImages, setMyImages] = useState([])

	const {
		getMedicalConditions,
		getTherapistInfo,
		postTherapistInfo,
		postUploadFiles,
	} = ApiRequests()

	const {
		register,
		formState: { errors },
	} = useForm()

	// Medical conditions
	const [medical, setMedicalCondition] = React.useState(null)
	const [fetchedInfo, setFetchedInfo] = useState([])
	const [tMessage, setTMessage] = useState("")
	const navigate = useNavigate()

	useEffect(() => {
		smoothScrollToTop();
		const fetchData = async () => {
			try {
				const response = await getMedicalConditions()
				setMedicalCondition(response.data)
			} catch (error) {
				console.log("error 17", error)
			}
			try {
				const response = await getTherapistInfo()
				setFetchedInfo(response.data)
				if (response.data?.therapist?.medical_conditions?.length) {
					const arr = [];
					response.data.therapist.medical_conditions.forEach(element => {
						arr.push(element.id);
					});
					setPreferences(prev => [...prev , ...arr]);
				}
			} catch (error) {
				console.log("Error:", error.message)
			}
		}

		fetchData()
	}, [])
	function smoothScrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}
	const handleInputChange = ({ target: { name, value } }) => {
		console.log(info);
		
		//@ts-ignore
		if (
			name === "account_holder_name" ||
			name === "account_number" ||
			name === "sort_code"
		) {
			setInfo({
				bank_account: { ...info.bank_account, [name]: value },
			})
		}
		// Update sessionCost separately
		if (name === "couple_cost" || name === "individual_cost") {
			const numericValue = parseFloat(value)
			setInfo({
				session_cost: {
					...info.session_cost,
					[name]: !isNaN(numericValue) ? numericValue*100 : '',
				},
			})
		} else {
			setInfo({
				[name]: value,
			})
		}
	}

	const handleSubmit = () => {
		console.log("info: >>>>>", preferences);
		setInfo({
			...info,
			medical_conditions_id: preferences,
		})
		if (!termsChecked || !privacyChecked) return;
		if (!info.name || 
			!info.surname || 
			!info.gender || 
			!info.phone_number || 
			!info.date_of_birth || 
			!info.city || 
			(info.full_years_of_practice != 0 && !info.full_years_of_practice) || 
			(info.total_therapy_session_count != 0 && !info.total_therapy_session_count) ||
			!info.title || 
			!info.languages_id?.length || 
			!info.about_me || 
			!info.memberships ) {
				setTMessage("Please fill all fields in previous page")
				setShowToast(true)
				return;
			}
		if (
			!preferences?.length ||
			!info.session_cost.couple_cost ||
			!info.session_cost.individual_cost ||
			!info.bank_account.account_holder_name ||
			!info.bank_account.account_number ||
			!info.bank_account.sort_code
			) {
				console.log({imageFile});
				
			setTMessage("Please fill all fields")
			setShowToast(true)
			return;
		}
		if (!imageFile) {
			setTMessage("Please choose a photo")
			setShowToast(true)
			return;
		}

		onSubmit()
	}

	const fileUploadFunc = async (isNavigate) => {
		if (imageFile) {
			const formData = new FormData();
			formData.append("profile_picture", imageFile);
			// formData.append("type", "profile_picture");
			try {
				const response = await postUploadFiles(formData)
				console.log("response Images", response)
				if (isNavigate) navigate("/therapist/onboarding/sync-calendar");
			} catch (error) {
				console.error("Error uploading files:", error)
			}
		}
	}
	const onSubmit = async () => {
		try {
			const responseGetTherapistInfo = await getTherapistInfo() // Wait for the response
			console.log("responseGetTherapistInfo :>> ", responseGetTherapistInfo)
			setUserInfo(responseGetTherapistInfo)
			if (responseGetTherapistInfo.data.onboarding_completed) {
				navigate("/therapist/onboarding/sync-calendar")
				console.log("onboarding_completed")
				fileUploadFunc(true);
			} else {				
				console.log("AAAAAAAAAAAAAAAA: ", info);
				
				const onBoardingResult = await postTherapistInfo(info) // Wait for the response
				console.log("onBoardingResult", onBoardingResult)
				if (onBoardingResult.status === 200) {
					fileUploadFunc(false);
					navigate("/therapist/onboarding/sync-calendar")
				}
			}
		} catch(error) {
			//@ts-ignore
			setTMessage(error.response?.data?.message || "An error occurred")
			setShowToast(true)
			console.log("error", error)
		}
	}

	function filterBySituation(array = []) {
		const condition = {}
		if (array?.length == 0 && array?.length !== undefined) return
		for (const item of array) {
			const conditionName = item.type

			if (!condition.hasOwnProperty(conditionName)) {
				condition[conditionName] = []
			}
			condition[conditionName].push(item)
		}

		return Object.entries(condition).map(([situationName, situationArray]) => ({
			situationName,
			situationArray,
		}))
	}

	return (
		<Container style={{ overflow: "auto", padding: "0 30px 30px" }}>
			<Toast
				message={tMessage}
				show={showToast}
				onClose={() => setShowToast(false)}
				background="#dc3545"
			/>
			<CssBaseline />
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<h2 style={{ fontSize: "3rem", margin: "3rem 0 2rem 0" }} className="!text-2xl lg:!text-5xl sm:!text-3xl">
					Select topics you work with <span className="text-blue-400"> </span>
				</h2>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					{filterBySituation(medical?.medical_conditions)?.map((item) => (
						<Grid
							item
							xs={12}
							sm={6}
							style={{ borderRadius: 10, padding: "10px 5px" }}
						>
							<CheckboxesGroup
								title={upperCaseFirst(item?.situationName)}
								data={item?.situationArray}
								preferences={preferences}
								setPreferences={setPreferences}
							/>
						</Grid>
					))}
				</Grid>
			</Box>
			<div className="flex justify-between mt-10 !flex-col sm:!flex-row">
				<FormControl fullWidth sx={{ m: 1 }} className="!m-0 sm:!m-2">
					<InputLabel htmlFor="individual_cost">
						Price of individual session per 50 mins*
					</InputLabel>
					<OutlinedInput
						id="individual_cost"
						name="individual_cost"
						type="number"
						startAdornment={<InputAdornment position="start">£</InputAdornment>}
						label="Price of individual session for 50 mins "
						placeholder="Enter the price for individual session for 50 mins"
						value={info?.session_cost.individual_cost/100 || null}
						required
						onInput={handleInputChange}
					/>
				</FormControl>
				<FormControl fullWidth sx={{ m: 1 }} className="!mt-4 !m-0 sm:!m-2">
					<InputLabel htmlFor="couple_cost">
						Price of couple session for 90 mins*
					</InputLabel>
					<OutlinedInput
						id="couple_cost"
						name="couple_cost"
						type="number"
						startAdornment={<InputAdornment position="start">£</InputAdornment>}
						placeholder="Enter the price for couple session for 90 mins"
						label="Price of couple session for 90mins "
						value={info?.session_cost.couple_cost/100 || null}
						required
						onInput={handleInputChange}
					/>
				</FormControl>
			</div>
			<h4 className="mt-5 font-semibold mb-3">Bank account details*</h4>
			<InputValidated
				id="account_holder_name"
				name="account_holder_name"
				label="Name"
				type="text"
				placeholder="Your name as mentioned in your bank account"
				register={register("account_holder_name", {
					required: true,
					maxLength: 30,
				})}
				error={errors.account_holder_name}
				onInput={handleInputChange}
				value={info.bank_account.account_holder_name}
			/>
			<InputValidated
				id="account_number"
				name="account_number"
				label="Account Number"
				type="text"
				placeholder=""
				register={register("account_number", { required: true, maxLength: 30 })}
				error={errors.account_number}
				onInput={handleInputChange}
				value={info.bank_account.account_number}
			/>
			<InputValidated
				id="sort_code"
				name="sort_code"
				label="Sort Code"
				type="text"
				placeholder=""
				register={register("sort_code", { required: true, maxLength: 30 })}
				error={errors.sort_code}
				onInput={handleInputChange}
				value={info.bank_account.sort_code}
			/>

			<h3 className="input-label">Upload Photo*</h3>
			<input type="file" accept="image/*" lang="en" onChange={(e) => setImageFile(e.target.files[0])} />
			<TermsAndConditions termsChecked={termsChecked} setTermsChecked={setTermsChecked} isOnboarding={true}/>	
			<PrivacyPolicy privacyChecked={privacyChecked} setPrivacyChecked={setPrivacyChecked}/>	
			<div
				className={`flex w-full py-10 space-x-4 gap-2 ${
					hidden ? "justify-center" : "justify-between"
				}`}
			>
				<div className={`${hidden ? "hidden" : "w-full"}`}>
					<Link to={prevPage} className={`${hidden && "hidden"}`}>
						<button className="btn-secondary w-full">Back</button>
					</Link>
				</div>
				<div className="w-full !m-0">
					<button className="btn-primary w-full disabled:bg-gray-300 disabled:cursor-not-allowed duration-300 ease-in-out" onClick={handleSubmit} disabled={!termsChecked || !privacyChecked}>
						{text}
					</button>
				</div>
			</div>
		</Container>
	)
}

export default Onboarding2
