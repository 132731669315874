import { useQuery } from "@tanstack/react-query"
import { Avatar, Box, Button, Chip } from "@mui/material"
import { Link, useParams } from "react-router-dom"
import Calendar from "./Calendar"
import therapistImage from "../images/therapist1.png"
import ApiRequests from "../../../api/ApiRequests"
import LoadingPage from "../../../../components/Cards/LoadingPage"
import Navbar from "../Navbar"

function TherapistInfoPage(props) {
	const { id } = useParams()
	const { getTherapistsList } = ApiRequests()

	const { data, isError, isLoading } = useQuery(
		["therapist-list"],
		getTherapistsList
	)

	const therapist = data?.data?.therapists.find((x) => x.id === id)
	if (isLoading) return <LoadingPage />
	if (isError) return (
		<>
			<Navbar title={`${therapist?.name}'s profile`} />
			<h2>Error...</h2>
		</>
	)

	return (
		<Box className="flex flex-wrap md:flex-row flex-col">
			<Navbar title={`${therapist?.name}'s profile`} />

			<div className="md:min-h-full p-4 md:py-5 md:px-2.5 flex-2 mr-15 mt-4 text-white items-stretch flex flex-col justify-between md:m-[5px] m-0"
				style={{
					flex: 2,
					borderRadius: 23,
					background: "#e0e1f1",
					width: window.innerWidth < 768 ? "calc(100vw - 32px)" : null 
				}}
			>
				<Box>
					<Box className="flex flex-wrap md:py-2.5 md:px-5"
						sx={{
							alignItems: "left",
							justifyContent: "start",
							width: "100%",
						}}
					>
						<Avatar
							className="mr-2.5 md:mr-6"
							src={therapist?.profile_picture || therapistImage}
							sx={{ bgcolor: "purple", width: 100, height: 100 }}
						/>
						<Box
							alignItems="center"
							flexDirection="column"
							className="md:ml-0 md:w-max p-0 flex justify-center !items-start"
						>
							<h4
								style={{
									color: "#323331",
									fontWeight: 600,
									fontSize: 18,
									padding: 0,
									margin: 0,
								}}
							>
								{therapist?.name || "Name"}
							</h4>
							<p style={{ color: "grey", marginBottom: 5 }}>{therapist.title}</p>
							<Box className="flex flex-wrap">
								<p
									style={{
										color: "rgb(226, 109, 128)",
										padding: "3px 10px",
										background: "white",
										border: "2px solid rgb(226, 109, 128)",
										borderRadius: 12,
										marginBottom: 5,
										marginRight: 5,
										width: "fit-content",
										fontSize: 12,
									}}
								>
									Price for individual:
									<p style={{ fontWeight: "bold", fontSize: 16 }}>
										£{therapist.session_cost.individual_cost/100} /{" "}
										{therapist.session_cost.individual_cost_duration_minutes} min
									</p>
								</p>
								<p
									style={{
										color: "rgb(226, 109, 128)",
										padding: "3px 10px",
										background: "white",
										border: "2px solid rgb(226, 109, 128)",
										width: "fit-content",
										borderRadius: 12,
										marginBottom: 5,
										fontSize: 12,
									}}
								>
									Price for couples:
									<p style={{ fontWeight: "bold", fontSize: 16 }}>
										£{therapist.session_cost.couple_cost/100} /{" "}
										{therapist.session_cost.couple_cost_duration_minutes} min
									</p>
								</p>
							</Box>
						</Box>
					</Box>
					<Box className="md:pt-5 md:px-5 pb-2.5 mt-2 w-full">
						<h4
							style={{
								color: "#323331",
								fontWeight: 600,
								fontSize: 18,
								padding: 0,
								margin: 0,
								marginBottom: 12,
							}}
						>
							About therapist
						</h4>
						<p
							style={{
								color: "#323331",
								fontSize: 14,
								padding: 0,
								margin: 0,
								marginBottom: 12,
							}}
						>
							<strong>Years of Experience: </strong>
							{therapist.full_years_of_experience} years
						</p>
						<p
							style={{
								color: "#323331",
								fontSize: 14,
								padding: 0,
								margin: 0,
								marginBottom: 12,
							}}
						>
							<strong>Education: </strong> {therapist.higher_education}
						</p>
						<p
							style={{
								color: "#323331",
								fontSize: 14,
								padding: 0,
								margin: 0,
								marginBottom: 12,
							}}
						>
							<strong>Achievement: </strong>
							{therapist.professional_achievements}
						</p>
						<p
							style={{
								color: "#323331",
								fontSize: 14,
								padding: 0,
								margin: 0,
								marginBottom: 12,
							}}
						>
							<strong>About me: </strong>
							{therapist?.about_me}
						</p>
					</Box>
				</Box>

				<Box
					display="flex"
					alignItems="center"
					flexDirection="row"
					justifyContent="center"
				>
					<Link to="/therapist-list">
						<Button
							variant="contained"
							style={{
								borderRadius: 20,
								color: "black",
								background: "white",
								width: 100,
								fontWeight: 600,
								boxShadow: "none",
							}}
						>
							Back
						</Button>
					</Link>
				</Box>
			</div>
			<Calendar therapist={therapist} />
		</Box>
	)
}

export default TherapistInfoPage
