import { Box, Container } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import WelcomeMessage from "../../sakinah/User/Components/WelcomeMessage";
import { Grid } from "@mui/material";
import { useStoreUser } from "../../../Zustand";
import { useEffect } from "react";

const theme = createTheme();

function AuthContainer(props: any) {
	const { setUserInfo } = useStoreUser();
  useEffect(() => {
    setUserInfo({});
  }, [])
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <WelcomeMessage />
            </Grid>
            <Grid container item xs={12} md={6} className="justify-center md:justify-baseline">
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default AuthContainer;
