import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { createTheme } from "@mui/material/styles"
import { NavLink, useLocation } from "react-router-dom"
import { useState } from "react"
import { Alert, IconButton, InputAdornment, capitalize } from "@mui/material"
import axios from "axios"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"

function Copyright(props: any) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{"Copyright© "}
			<Link color="inherit" href="https://sakina.io/">
				Sakina{" "}
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	)
}

export default function SignUpTherapist() {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")
	const [showPassword, setShowPassword] = React.useState(false)
	const [message, setMessage] = useState("")
	const [errorMessage, setErrorMessage] = useState("")
	const location = useLocation();
	console.log({location});
	
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/onboarding/start`,
				{
					email: email,
					onboarding_entity: 2,
					password: password,
				}
			)
			console.log(response, "response")
			// Assuming the sign-up was successful, set the success message
			setMessage(
				"Account created! Check your email to verify your email address and complete the onboarding process."
			)
			setEmail("")
			setPassword("")
			setConfirmPassword("")
			setErrorMessage("")
		} catch (error) {
			console.log(error, "error")
			const errMsg = error.response.data.message || error.response.data[0].message;
			setErrorMessage(errMsg)
			setMessage("")
		}
	}

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<>
			<CssBaseline />
			<Container component="main" maxWidth="xs" className="!p-0 md:!p-4">
				<CssBaseline />
				<Box
				className="md:mt-16"
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Therapist Sign up
					</Typography>
					<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password"
									label="Password"
									type={showPassword ? "text" : "password"}
									InputProps={{
										endAdornment: <InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}}
									id="password"
									autoComplete="new-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									value={confirmPassword}
									name="confirmPassword"
									label="Confirm Password"
									type={showPassword ? "text" : "password"}
									id="password"
									autoComplete="new-password"
									onChange={(e) => setConfirmPassword(e.target.value)}
								/>
							</Grid>
							{message && (
								<Grid item xs={12}>
									<Alert severity="success">{message}</Alert>
								</Grid>)}
							{confirmPassword !== password && (
								<Grid item xs={12}>
									<Alert severity="error">Passwords do not match!</Alert>
								</Grid>
							)}
							{errorMessage && (
								<Grid item xs={12}>
									<Alert severity="error">
										{" "}
										The following error occured{" "}
										<span className="font-semibold italic"> "{capitalize(errorMessage)}"</span>. Please
										try again
									</Alert>
								</Grid>
							)}
							<Grid item xs={12}>
								<Alert severity="info">
									Password must contain at least one upper case, one lower case letter, one
									special character and at least 8 characters long
								</Alert>
							</Grid>
						</Grid>

						<Button type="submit" disabled={confirmPassword !== password} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Sign Up
						</Button>
						<NavLink to={`/auth/register`}>
							<Button fullWidth variant="outlined" color="error" className="md:mt-6" sx={{ mb: 2 }}>
								Sign up as User
							</Button>
						</NavLink>

						<Grid container justifyContent="center">
							<Grid item>
								<NavLink to="/auth/therapist/signin">
									Already have an account?
									<span style={{ textDecoration: "underline", color: "#1976d2" }}>
										Sign in
									</span>
								</NavLink>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 5 }} />
			</Container>
		</>
	)
}
