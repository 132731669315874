import { Box, CircularProgress } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTokenStore } from "../../../Zustand"

function VerifyEmail(props) {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const { token, setToken } = useTokenStore()
	const urlParams = new URLSearchParams(window.location.search)
	const verificationToken = urlParams.get("verification-token")

	async function onBoardingVerifyEmail() {
		setLoading(true)
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/onboarding/verify-email`,
				{
					token: verificationToken,
				}
			)
			response.data !== undefined && !token.length && setToken(response.data)
			setLoading(false)
			navigate("/user-info")
		} catch (error) {
			setLoading(false)
			console.log("error 17", error)
		}
	}

	useEffect(() => {
		if (verificationToken) {
			onBoardingVerifyEmail()
		} else {
			console.log("No verification token found")
		}
	}, [])

	return loading ? (
		<Box sx={{ display: "flex", h: "100vh", w: "100vw" }}>
			<CircularProgress />
		</Box>
	) : (
		<Box>Something went wrong.</Box>
	)
}

export default VerifyEmail
