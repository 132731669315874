import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function Toast({ message, show, onClose, background = "#32a676" }) {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={show}
            onClose={onClose}
            autoHideDuration={6000}
        >
            <SnackbarContent 
                message={message}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                sx={{ backgroundColor: background }} 
            />
        </Snackbar>
    );
}

export default Toast;
