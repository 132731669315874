import * as React from "react"
import dayjs, { Dayjs } from "dayjs"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { Alert, Box, Button } from "@mui/material"
import "./Style.css"
import ConfirmBooking from "./ConfirmBooking"
import SnackbarX from "./SnackbarX"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import ApiRequests from "../../../api/ApiRequests"
import { createAxios } from "../../helpers/createAxios"
import { useTokenStore } from "../../../../Zustand"
import LoadingPage from "../../../../components/Cards/LoadingPage"
import CustomDatePicker from "./TimePicker"

const timezone = require("dayjs/plugin/timezone")
dayjs.extend(timezone)

export default function DayPicker({ therapist, open, setOpen }) {
	const { getCalendarAvailability } = ApiRequests()
	const queryClient = useQueryClient()
	const [time, setTime] = React.useState("")
	const [openSnackbar, setOpenSnackbar] = React.useState(false)
	const [isForCouples, setIsForCouples] = React.useState(false)
	const [isFreeTrial, setIsFreeTrial] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [error, setError] = React.useState(null)

	const [value, setValue] = React.useState<Dayjs | null>(dayjs())
	const date = dayjs(value).format("YYYY-MM-DD")
	
	const { data, isLoading, isError, refetch, isFetching } = useQuery(
		["availability"],
		() => {
			const duration = isFreeTrial ? 15 :
			 (isForCouples ? therapist?.session_cost?.couple_cost_duration_minutes : therapist?.session_cost?.individual_cost_duration_minutes);

			return getCalendarAvailability({
				from_date: getDateWithTime(date),
				to_date: date + "T23:59:59.000Z",
				therapist_id: therapist.id,
				time_slot_duration: duration
			})
		}
	)

	const getDateWithTime = (dateString) => {
		// Parse the provided date
		const providedDate = dayjs(dateString);
		const now = dayjs();

		// Check if the provided date is today
		if (providedDate.isSame(now, 'day')) {
			// Return current time in ISO 8601 format
			return now.toISOString();
		} else {
			// Return provided date at midnight in ISO 8601 format
			return providedDate.startOf('day').toISOString();
		}
	}

	const { token } = useTokenStore()

	async function payForSession(time) {
		setLoading(true)
		const Axios = createAxios(token.access_token)
		const duration = isForCouples ? 90 : 50 // Determine duration based on session type
		
		try {
			const { data } = await Axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/reservation`,
				{
					start_time: dayjs(time)?.toISOString(),
					end_time: dayjs(time).add(duration, "minute")?.toISOString(), // <-- changed here
					therapist_id: therapist.id,
					is_session_for_couple: isForCouples,
					is_free_trial: isFreeTrial,
				}
			)
			console.log("PayForSession response", data);	
			setLoading(false)
			queryClient.invalidateQueries(["availability"])
			window.open(data.checkout_session_url)
		} catch (error) {
			console.log("PayForSession error", error);	
			setLoading(false)
			setError(error.response.data.message)
		}
	}

	React.useEffect(() => {
		refetch()
		setTime("");
	}, [value, isForCouples, isFreeTrial, refetch])

	const availableHours = data?.data.time_slots?.map((slot) =>
		new Date(slot.start_time).getHours()
	)
	const uniqueHours = [...new Set(availableHours)]?.map((hour: any) => {
		return ConvertTimetoUTC(hour)
	})

	function ConvertTimetoUTC(hour): string {
		const newDateFormat = date.split("-").map((str) => parseInt(str))
		if (hour)
			return new Date(
				newDateFormat[0],
				newDateFormat[1] - 1,
				newDateFormat[2],
				hour,
				0,
				0
			)?.toISOString()
	}

	// Disable dates before today and weekends
	const isWeekend = (date: Dayjs) => {
		const day = date.day()
		return day === 0 || day === 6
	}

	function dayBeforeToday(date) {
		//.add(1, 'day') should be removed later
		return dayjs(date).isBefore(dayjs().add(1, "day"), "day")
		// return dayjs(date).subtract(1, 'day').isBefore(dayjs(), "day")
	}

	if (isLoading || loading) return <LoadingPage />
	if (isError) return <h1>Error...</h1>
	if (error) return <p>{error}</p>

	return (
		<Box>
			<ConfirmBooking
				therapist={therapist}
				open={open}
				setOpen={setOpen}
				setOpenSnackbar={setOpenSnackbar}
				handleMutate={() => payForSession(time)}
				isForCouples={isForCouples}
				date={date}
				time={time}
			/>
			<SnackbarX
				open={openSnackbar}
				setOpen={setOpenSnackbar}
				backgroundColor="#32a676"
				message="The session has been successfully booked!"
			/>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					flexDirection: "column",
					padding: "10px 25px 0px",
				}}
			>
				<p
					onClick={() => setIsFreeTrial(!isFreeTrial)}
					style={{
						color: "rgb(95 106 196 / 68%)",
						padding: "3px 10px",
						background: isFreeTrial ? "rgb(95 106 196 / 18%)" : "white",
						border: isFreeTrial
							? "2px solid rgb(95 106 196 / 68%)"
							: "1px solid rgb(95 106 196 / 68%)",
						borderRadius: 6,
						fontWeight: isFreeTrial ? "bold" : "none",
						fontSize: 12,
						textAlign: "center",
						height: "fit-content",
						cursor: "pointer",
						width: "100%",
					}}
				>
					⌛️ Free Trial / 15 min
				</p>
				<Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
					<p
						onClick={() => setIsForCouples(false)}
						style={{
							width: "100%",
							color: "rgb(95 106 196 / 68%)",
							padding: "3px 10px",
							background: !isForCouples ? "rgb(95 106 196 / 18%)" : "white",
							border: !isForCouples
								? "2px solid rgb(95 106 196 / 68%)"
								: "1px solid rgb(95 106 196 / 68%)",
							borderRadius: 6,
							fontWeight: !isForCouples ? "bold" : "none",
							fontSize: 12,
							cursor: "pointer",
						}}
					>
						🧕 Individual
					</p>
					<p
						onClick={() => setIsForCouples(true)}
						style={{
							width: "100%",
							color: "rgb(95 106 196 / 68%)",
							padding: "3px 10px",
							background: isForCouples ? "rgb(95 106 196 / 18%)" : "white",
							border: isForCouples
								? "2px solid rgb(95 106 196 / 68%)"
								: "1px solid rgb(95 106 196 / 68%)",
							borderRadius: 6,
							fontWeight: isForCouples ? "bold" : "none",
							fontSize: 12,
							cursor: "pointer",
						}}
					>
						👩‍❤️‍👨 Couples
					</p>
				</Box>
			</Box>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<StaticDatePicker
					displayStaticWrapperAs="desktop"
					shouldDisableDate={(date) => dayBeforeToday(date)}
					value={value}
					onChange={(newValue) => {
						setValue(newValue)
					}}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
			<Box
				style={{
					padding: "0px 20px",
					display: "flex",
					justifyContent: "center",
					flexWrap: "wrap",
					margin: 0,
				}}
			>
				{isFetching ? (
					<LoadingPage />
				) : uniqueHours?.length === 0 ? (
					<Alert sx={{ width: "100%", m: 1 }} severity="error">
						No available slot
					</Alert>
				) : (
					<CustomDatePicker timeslots={data?.data.time_slots} setTime={setTime} />
				)}

				{/* {isFetching ? (
					<LoadingPage />
				) : uniqueHours?.length === 0 ? (
					<Alert sx={{ width: "100%", m: 1 }} severity="error">
						No available slot
					</Alert>
				) : (
					uniqueHours
						?.filter((item) => new Date(item) > new Date())
						?.map((item, index) => (
							<Button
								key={index}
								onClick={() => setTime(item)}
								style={{
									padding: "2px 8px",
									background: item === time ? "rgb(226, 109, 128)" : "white",
									color: item === time ? "white" : "rgb(226, 109, 128)",
									border: "1px solid rgb(226, 109, 128)",
									fontSize: 12,
									boxShadow: "none",
									margin: 5,
									fontWeight: 500,
									borderRadius: 6,
								}}
							>
								{dayjs(item).format("HH:mm")}
							</Button>
						))
				)} */}
			</Box>

			<Box
				style={{
					width: "100%",
					background: "rgb(245, 245, 245)",
					padding: "10px 0px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					margin: "20px auto 10px",
					maxWidth: 270,
					fontSize: 14,
					borderRadius: "6px",
				}}
			>
				<p style={{ fontWeight: 600 }}>Chosen Date and Time</p>
				<p>{date}</p>
				<p>
					{dayjs(time).format("HH:mm") !== "Invalid Date"
						? dayjs(time).format("HH:mm")
						: "Please choose time"}
				</p>
				<Button
					disabled={time === ""}
					onClick={() => {
						setOpen(true)
					}}
					variant="contained"
					style={{
						borderRadius: 20,
						background: "rgb(226, 109, 128)",
						width: 100,
						fontWeight: 600,
						marginTop: 15,
					}}
				>
					Book
				</Button>
			</Box>
		</Box>
	)
}
