import React from 'react'

export default function NotFound(){
 
  return (
    <>
      <div className='mt-5'>
        <div className='max-w-[1440px] my-0 mx-auto'>
          <div className='flex'>
            <div className='mx-auto'>
              <div className='text-center my-6 mx-0'>
                <h1 className='text-[130px]'>404</h1>
                <div>
                  <p className='text-2xl'>Page not found</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
