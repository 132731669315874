import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import { Alert, capitalize, IconButton, InputAdornment, Typography } from "@mui/material"
import { NavLink, useNavigate } from "react-router-dom"
import axios from "axios"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"

function Copyright(props: any) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{"Copyright © "}
			<a color="inherit" href="#">
				Sakina Therapy
			</a>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	)
}

export default function SignUp() {
	const [email, setEmail] = React.useState("")
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")
	const [showPassword, setShowPassword] = React.useState(false)
	const [message, setMessage] = React.useState("")
	const [errorMessage, setErrorMessage] = React.useState("")

	async function signUp() {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_ENDPOINT}/onboarding/start`,
				{
					email: email,
					onboarding_entity: 1,
					password: password,
				}
			)
			setMessage(
				"Account created! Check your email to verify your email address and complete the onboarding process."
			)
			setEmail("")
			setPassword("")
			setConfirmPassword("")
			setErrorMessage("");
		} catch (error) {
			console.log(error)
			const errMsg = error.response.data.message || error.response.data[0].message;
			setErrorMessage(errMsg);
			setMessage("");
			setTimeout(() => console.log(errorMessage), 0);
		}
	}
	// Handle register
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		signUp()
	}

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<Box className="sm:pt-[30px] sm:p-10 p-4 flex flex-col items-center bg-[#5f6ac40f] m-2.5 rounded-lg"
			sx={{
				maxWidth: { xs: "100%", md: "450px" },
			}}
		>
			<CssBaseline />
			<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
				<LockOpenIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				Sign up
			</Typography>
			<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							required
							value={email}
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							value={password}
							name="password"
							label="Password"
							type={showPassword ? "text" : "password"}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}}
							id="password"
							autoComplete="new-password"
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							value={confirmPassword}
							name="confirmPassword"
							label="Confirm Password"
							type={showPassword ? "text" : "password"}
							id="password"
							autoComplete="new-password"
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
					</Grid>
					{message &&
						<Grid item xs={12}>
							<Alert severity="success">{capitalize(message)}</Alert>
						</Grid>
					}
					{confirmPassword !== password && (
						<Grid item xs={12}>
							<Alert severity="error">Passwords do not match!</Alert>
						</Grid>
					)}
					{errorMessage && (
						<Grid item xs={12}>
							<Alert severity="error">
								{" "}
								The following error occured{" "}
								<span className="font-semibold italic"> "{capitalize(errorMessage)}"</span>. Please
								try again
							</Alert>
						</Grid>
					)}
					<Grid item xs={12}>
						<Alert severity="info">
							Password must contain at least one upper case, one lower case letter, one
							special character and at least 8 characters long
						</Alert>
					</Grid>
				</Grid>
				<Button type="submit" disabled={confirmPassword !== password} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
					Sign Up
				</Button>

				<NavLink to="/auth/therapist/signup">
					<Button fullWidth variant="outlined" color="error" sx={{ mt: 3, mb: 2 }}>
						Sign Up as a Therapist
					</Button>
				</NavLink>
				<Grid container justifyContent="center">
					<Grid item>
						<NavLink to="/auth/login">
							Already have an account?{" "}
							<span style={{ textDecoration: "underline", color: "#1976d2" }}>
								Sign in
							</span>
						</NavLink>
					</Grid>
				</Grid>
			</Box>
			<Copyright sx={{ mt: 2 }} />
		</Box>
	)
}
