import React from 'react';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import GppBadTwoToneIcon from '@mui/icons-material/GppBadTwoTone';
import GppBadRoundedIcon from '@mui/icons-material/GppBadRounded';
interface ErrorComponentProps {
    errorMessage: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ errorMessage }) => {
    return (
        <div className="flex flex-col justify-center items-center h-screen text-center px-4">
            <GppBadOutlinedIcon style={{ fontSize: 100, color: '#676767' }} />
            <p className="text-2xl font-medium mt-4 text-[#676767]">{errorMessage}</p>
        </div>
    );
};

export default ErrorComponent;
