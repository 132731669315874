import React from "react"
import TabOnboarding from "../Components/TabOnboarding"
import Navbar from "../Components/Navbar"

const SettingsTherapist = () => {
	return (
		<div>
			<Navbar title="Settings"/>
			<TabOnboarding />
		</div>
	)
}

export default SettingsTherapist
