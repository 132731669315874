import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Avatar } from "@mui/material"
import therapistImage from "../images/therapist1.png"
import CssBaseline from "@mui/material/CssBaseline"
import dayjs from "dayjs"
import { Box } from "@mui/material"

import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';

export default function SessionsList({ title, data }) {
	console.log("title, data", title, data);
	const isNotDesktop = window.innerWidth < 768;
	return (
		<Box sx={{ marginTop: "40px" }}>
			<h3 className="font-semibold text-[19px] md:ml-2.5 text-[#5f616a] ">
				{title} ({data?.length})
			</h3>
			<TableContainer
				component={Paper}
				className="mt-5 md:mx-2.5 mb-2.5"
				style={{
					width: isNotDesktop ? "100%" : "calc(100% - 25px)",
					boxShadow: "none",
					maxHeight: "600px",
				}}
			>
				<CssBaseline />
				<Table aria-label="simple table">
					<TableHead>
						<TableRow
							style={{
								background: "rgb(95, 106, 196)",
								borderRadius: 12,
								color: "white",
							}}
						>
							<TableCell
								style={{ color: "white", fontWeight: 600, fontSize: 15 }}
								//
							>
								Therapist
							</TableCell>
							<TableCell
								style={{ color: "white", fontWeight: 600, fontSize: 15 }}
							>
								Type
							</TableCell>
							<TableCell
								style={{ color: "white", fontWeight: 600, fontSize: 15 }}
							>
								Date
							</TableCell>
							<TableCell
								style={{ color: "white", fontWeight: 600, fontSize: 15 }}
							>
								Time
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.length > 0 &&
							data
								?.sort(
									(a, b) =>
										dayjs(b.date + " " + b.time?.slice(0, 5)).unix() -
										dayjs(a.date + " " + a.time?.slice(0, 5)).unix()
								)
								?.map((row, index) => (
									<TableRow
										key={row.docId}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
											//
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Avatar
												src={therapistImage}
												sx={{
													width: 35,
													height: 35,
													border: "2px solid rgb(95, 106, 196)",
													marginRight: 1.5,
												}}
											/>
											<p>{row.therapist_name}</p>
										</TableCell>
										<TableCell>
											{row.is_session_for_couple ? <><PeopleIcon className="!w-5 ml-2"/></> : <><PersonIcon className="!w-5 ml-2" /></>}
										</TableCell>
										<TableCell>
											{dayjs(row.start_time).format("	MMM D, YYYY")}
										</TableCell>
										<TableCell>
											{dayjs(row.start_time).format("	h:mm A")}
										</TableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}
