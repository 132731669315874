import { Box, CircularProgress } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTokenStore } from "../../../Zustand"

function VerifyEmailTherapist() {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(true)
	const { token, setToken } = useTokenStore()
	const urlParams = new URLSearchParams(window.location.search)
	const verificationToken = urlParams.get("verification-token")

  async function onBoardingVerifyEmailTherapist() {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/onboarding/verify-email`, {
        token: verificationToken,
      })
      console.log("verificationToken", verificationToken)
      console.log("Verify email data", response.data)
      response.data !== undefined && !token.length && setToken(response.data)
      navigate("/therapist/onboarding/")
      console.log("Verify email token :>> ", !token)
    } catch (error) {
      console.log("error 17", error)
    } finally {
			setLoading(false);
		};
  }

	useEffect(() => {
		if (verificationToken) {
			onBoardingVerifyEmailTherapist()
		} else {
			console.log("No verification token found")
		}
	}, [])
  
  if (!verificationToken) return (<Layout>Verificatition token not found</Layout>)
  if (loading) return (<Layout><CircularProgress /></Layout>)
  return (<Layout>Something went wrong.</Layout>)
}

const Layout = ({children}) => {
  return (
    <Box className="items-center w-screen h-screen flex justify-center">
      {children}
    </Box>
  )
}

export default VerifyEmailTherapist
