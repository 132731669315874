import { Box, Grid } from "@mui/material"
import AccountMenu from "../../auth/User/SignOut"
import { useStoreUser } from "../../../Zustand"
import SideMenuMobile from "../helpers/sideMenuMobile"
function Navbar(props) {
	const { userInfo } = useStoreUser((state) => state)
	const defaultTitle = (
		<span>
		  As-salamu aleikum {userInfo?.name ? <strong>{userInfo.name}</strong> : ""} 🙂
		</span>
	);
	const {title = defaultTitle, showNavbar = false} = props;
	return (
		<Grid
			item
			xs={12}
			className={`text-white md:px-2.5 pb-0 rounded-[5px] w-full items-center mb-[14px] !flex justify-between ${showNavbar || 'md:!hidden'}`}
		>	
			<Box className="w-full md:w-auto flex items-center gap-2">
				<span className="md:hidden">
					<SideMenuMobile />
				</span>
				<h3 className="text-center flex-1 md:flex-none md:text-[25px] text-[22px] text-[#32325d]">
					{title}
				</h3>
			</Box>
			<AccountMenu />
		</Grid>
	)
}

export default Navbar
