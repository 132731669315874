import { Box, Button, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom";
import SyncCalendar from "../Components/SyncCalendar"

function SyncCalendarPage() {
	return (
		<div>
			<Box
				sx={{
					background: "#7bf1a8",
					display: "flex",
					justifyContent: "center",
					flexDirection: "column",
					alignItems: "center",
					borderRadius: 2,
					minHeight: "250px",
					p: 1,
					m: 1,
					height: window.innerWidth < 600 ? "calc(100vh - 112px)" : null,
					textAlign: "center"
				}}
			>
				<Typography variant="h4" className="!mb-8 sm:!mb-0">
					Sync your calendar and patients will be able to book sessions with you{" "}
					<br />
				</Typography>
				<SyncCalendar />
				<RouterLink to={`${window.innerWidth < 768 ? "/onproccess" : "/therapist"}`} className="mt-10">
					<Button variant="contained">
						Go to Dashboard
					</Button>
				</RouterLink>
			</Box>
		</div>
	)
}

export default SyncCalendarPage
