import React from "react"
import TableTherapist from "../../Components/Tables/TableTherapist"
import CalendarDashboard from "../../Components/Calendars/CalendarDashboard"
// import AvailabilityModal from "../Components/AvailabilityModal";
// import AvailabilityForm from "../Components/AvailabilityWorkingDay";
// import AvailabilityEditor from "../Components/AvailabilityWorkingDay";
// import AvailabilityBreaks from "../Components/AvailabilityBreaks";
import WorkingDays from "../../Components/WorkingDays"
import CalendarTimeOff from "../../Components/Calendars/CalendarTimeOff"
import MyCalendar from "./MyCalendar"
import TabCalendar from "../../Components/TabCalendar"
import Navbar from "../../Components/Navbar"

const Calendar = () => {
	return (
		<>
			<Navbar title="Calendar"/>
			<TabCalendar
				color="blue"
				tab1="Calendar"
				tab2="Manage Your Availability"
				tab3=""
				tab4=""
			/>
		</>
	)
}

export default Calendar
