import { Grid } from "@mui/material"
import Statistics from "./Components/Statistics"
import SessionsList from "./MySessions/SessionsList"
import dayjs from "dayjs"
import ApiRequests from "../../api/ApiRequests"
import { useQuery } from "@tanstack/react-query"
import LoadingPage from "../../../components/Cards/LoadingPage"

function MySessions() {
	const { getBookingList } = ApiRequests()

	const { data, isLoading, isError } = useQuery(["bookingList"], () =>
		getBookingList()
	)

	const sessions = data?.data?.bookings

	// Filtering upcoming sessions
	const upcomingSessions =
		sessions?.length > 0 &&
		sessions
			?.filter((session) => dayjs(session.start_time).unix() > dayjs().unix())
			.filter((session) => session.status === "succeeded_payment_checkout")

	// Filtering unsuccessful payment
	const unsuccessfulPaymentSessions =
		sessions?.length > 0 &&
		sessions
			?.filter((session) => dayjs(session.start_time).unix() > dayjs().unix())
			.filter((session) => session.status === "failed_payment_checkout")

	// Filtering previous sessions
	const prevSessions =
		sessions?.length > 0 &&
		sessions?.filter(
			(session) => dayjs(session.start_time).unix() < dayjs().unix()
		)

	// Filtering previous sessions
	const cancelledSessions =
		sessions?.length > 0 &&
		sessions?.filter((session) => session.cancel === true)

	if (isLoading) return <LoadingPage />
	if (isError) return <div>Something went wrong while fetching bookings...</div>

	return (
		<div style={{ display: "flex", flexDirection: "column" }}>
			<Statistics displayGraphs={true} />
			<Grid container>
				<Grid item xs={12}>
					<SessionsList title="Upcoming Sessions" data={upcomingSessions} />
				</Grid>
				<Grid item xs={12}>
					<SessionsList
						title="Unsuccessful Payment"
						data={unsuccessfulPaymentSessions}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SessionsList title="Previous Sessions" data={prevSessions} />
				</Grid>
				<Grid item xs={12} md={6}>
					<SessionsList title="Cancelled Sessions" data={cancelledSessions} />
				</Grid>
			</Grid>
		</div>
	)
}

export default MySessions
