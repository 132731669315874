import { Avatar, Box, Button, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import therapistImage from "../images/therapist1.png";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import MoneyIcon from "@mui/icons-material/Money";
function TherapistCard({ details }) {
  const interval628_768 = window.innerWidth < 768 && window.innerWidth >=628;
  const lessThan425 = window.innerWidth < 425;
  return (
    <div
      style={{
        padding: "16px 14px",
        borderRadius: 20,
        minHeight: 250,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        background: "#fff",
        width: lessThan425 ? `calc(100vw - 32px)` : (interval628_768 ? `calc(50vw - 20px)` : null)
      }}
      className=" md:w-[361px]"
    >
      <Box className="w-full">
        <Box display="flex" style={{ width: "100%" }} alignItems="center">
          <Avatar
            src={
              details.profile_picture ? details.profile_picture : therapistImage
            }
            sx={{ bgcolor: "purple", width: 70, height: 70 }}
          />
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            className="ml-2.5"
          >
            <Typography variant="h5" sx={{ fontWeight: "600" }} className="mx424:!text-xl">
              {details.name} {details.surname}
            </Typography>
            <Box display="flex" className="flex-wrap" justifyContent="space-between">
              <Typography
                variant="caption"
                style={{ color: "#80699F" }}
                className="text-sm whitespace-pre-line break-words text-ellipsis overflow-hidden mx424:max-w-[40%] md:max-w-[60%] hyphens-auto"
              >
                {details.title}
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                gap={1}
                style={{ width: "40%" }}
              >
                <ConnectWithoutContactIcon
                  sx={{ color: "#80699F", minWidth: "16px", width: "16px" }}
                />
                <Typography
                  variant="caption"
                  className="text-sm"
                  style={{ color: "#80699F" }}
                >
                  {details.total_therapy_session_count > 1000
                    ? `1000+`
                    : details.total_therapy_session_count}{" "}
                  sessions
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid container flexDirection="column" className="mt-5">
          <Typography variant="body1" className="!font-semibold">
            Medical condtions:
          </Typography>
          <Box
            display="flex"
            overflow="auto"
            maxHeight={140}
            className="mt-4"
            gap="4px"
            flexWrap="wrap"
            sx={{
              "&::-webkit-scrollbar": { width: "4px" },
              "&::-webkit-scrollbar-track": { backgroundColor: "#f1f1f1" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#aeadad",
                borderRadius: "3px",
              },
              "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#888" },
            }}
          >
            {details.medical_conditions.map((unit, index) => (
              <Chip
                label={unit.name}
                key={index}
                sx={{
                  "& span": {
                    padding: "0",
                  },
                }}
                style={{
                  borderRadius: "12px",
                  background: "#F0F9F4",
                  color: "#6CA594",
                  fontSize: "14px",
                  padding: "6px",
                }}
                className="font-semibold"
              />
            ))}
          </Box>
        </Grid>
        <Grid
          container
          flexWrap="wrap"
          className="mt-6"
          justifyContent="space-between"
        >
          <MoneyIcon
            sx={{ color: "#80699F", minWidth: "24px", width: "24px" }}
          />
          <Typography className="!font-semibold mx360:!text-sm">
            <span style={{ color: "#80699F" }}>
              £ {details.session_cost.individual_cost / 100 ?? "N/A"}
            </span>{" "}
            / {details.session_cost.individual_cost_duration_minutes} mins
          </Typography>
          <Typography className="!font-semibold !text-sm">
            <span style={{ color: "#80699F" }}>
              £ {details.session_cost.couple_cost / 100 ?? "N/A"}
            </span>{" "}
            / {details.session_cost.couple_cost_duration_minutes} mins
          </Typography>
        </Grid>
        <Typography
          variant="body2"
          className="w-full !mt-3"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",
            MozBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {details.about_me}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        className="mt-8 w-full"
        justifyContent="space-evenly"
      >
        <Box className="text-center">
          <Link
            to={`/therapist-profile/${details.id}`}
            state={{ therapist: details }}
          >
            <Button
              variant="contained"
              className="!font-semibold !text-base !bg-white mx424:!text-sm"
              style={{
                borderRadius: "12px",
                color: "#6CA594",
                boxShadow: "none",
                textTransform: "none",
              }}
            >
              View Profile
            </Button>
          </Link>
        </Box>
        <Link
          to={`/therapist-profile/${details.id}`}
          state={{ therapist: details }}
        >
          <Button
            variant="contained"
            className="!text-white !font-semibold !text-base mx424:!text-sm mx424:!px-5"
            style={{
              borderRadius: "12px",
              background: "#80699F",
              boxShadow: "none",
              textTransform: "none",
              padding: "13px 28px",
            }}
          >
            Book Now
          </Button>
        </Link>
      </Box>
    </div>
  );
}

export default TherapistCard;
