import { Alert, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { sendPasswordResetEmail } from "firebase/auth";
import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Link, Navigate, useParams } from "react-router-dom";
import { auth } from "../../../Firebase";
import ApiRequests from "../../api/ApiRequests";

function ForgotPassword(props) {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const params = useParams();
  const isUSer = params&&params.type=="1" ? true : false;
  const { sendPasswordResetLink } = ApiRequests();
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    sendPasswordResetLink(email, isUSer ? 1 : 2).then(() => {
      setMessage("Please check your email");
    }).catch((error) => {
      console.log("ERROR",error);
      
      const errorMessage = error.message;
      setIsError(true)
      setMessage(`[ERR] ${errorMessage}`);
      console.log("errorMessage", errorMessage);
      // ..
    }).finally(() => {
      setIsLoading(false);
    });
    // sendPasswordResetEmail(auth, email)
    //   .then(() => {
    //     setMessage("Please check your email");
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     setIsError(true)
    //     setMessage(`[ERR] ${errorMessage}`);
    //     console.log("errorMessage", errorMessage);
    //     // ..
    //   });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "100%",
        minWidth: "400px",
        background: "#5f6ac40f",
        borderRadius: "8px",
        padding: 5,
        paddingTop: "30px",
        margin: "10px",
      }}
    >
      <CssBaseline />
      <Typography component="h1" variant="h5" mb="10px">
        Reset Password
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {message && <Alert severity={isError ? "error" : "success"}>{message}</Alert>}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }} disabled={isLoading}>
        {isLoading ? <CircularProgress size={24} /> : 'Reset password'}
      </Button>
      <Link to={`${isUSer ? '/auth/login' : '/auth/therapist/signin'}`}>
        <Button fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
          Go to Login
        </Button>
      </Link>
    </Box>
  );
}

export default ForgotPassword;
