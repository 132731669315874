import React from "react";
import CancelSession from "../CancelSession";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

const TableSales = ({ tableHead, events }) => {
  const now = new Date(); // Current time

  const upcomingEvents = events.filter((item) => new Date(item.end_time) > now);
  const pastEvents = events.filter((item) => new Date(item.end_time) < now);

  const statusTextMapping = {
    succeeded_payment_checkout: "Paid",
    refund_completed: "Refund completed",
    free_trial: "Free Trial",
    cancelled: "Cancelled",
  };

  return (
    <div>
      <PrepTable
        tableHead={tableHead}
        events={upcomingEvents}
        // events={pastEvents}
        statusTextMapping={statusTextMapping}
        title="Upcomming Sessions"
      />
      <PrepTable
        tableHead={tableHead}
        events={pastEvents}
        statusTextMapping={statusTextMapping}
        title="Past Sessions"
      />
    </div>
  );
};

const PrepTable = ({ tableHead, events, statusTextMapping, title }) => (  
  <div className="mt-2">
    <h2 className="text-lg font-semibold p-4">{title}</h2>
    <Box className="w-full overflow-auto shadow-md rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            {tableHead.map((title, index) => (
              <th scope="col" className="px-4 py-3" key={`sales_${index}`}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        {!events.length ? (
          <Typography className="p-4 w-max">No sessions</Typography>
        ) : (
          <tbody>
            {/* <tr className="bg-white border-b  "> */}
            {events.map((item) => (
              <tr key={`sales_data_${item.id}`}>
                <td className="p-4">
                  <Box className="flex items-center gap-1 w-max">
                    <CalendarMonthOutlinedIcon style={{ color: "inherit" }} />
                    {dayjs(item.start_time).format("DD MMM YYYY")}
                  </Box>
                </td>
                <td className="p-4">
                  <Box className="flex items-center gap-1 w-max">
                    <ScheduleOutlinedIcon style={{ color: "inherit" }} />
                    {dayjs(item.start_time).format("HH:mm")} -{" "}
                    {dayjs(item.end_time).format("HH:mm")}
                  </Box>
                </td>
                <td className="p-4">
                  <Box className="flex items-center gap-1 w-max">
                    {item.patient_name}
                  </Box>
                </td>
                <td className="p-4">
                  <Box className="flex items-center gap-1 w-max">
                    {statusTextMapping[item.status] || item.status}
                  </Box>
                </td>
                <td className="p-4">
                  <CancelSession
                    isLoad={null}
                    mutate={null}
                    selectedEvent={null}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </Box>
  </div>
);

export default TableSales;
