import React from "react"
import VideocamIcon from "@mui/icons-material/Videocam"
import therapistImage from "../images/therapist1.png"
import calendar from "../images/calendar.svg"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Avatar, Button, IconButton } from "@mui/material"
import dayjs from "dayjs"
import SnackbarX from "./SnackbarX"
import CancelIcon from "@mui/icons-material/Cancel"
import ApiRequests from "../../../api/ApiRequests"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import ConfirmationModal from "../../../../components/Confirm/ConfirmationModal"
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';

function TherapySession({ session, upSessions }) {
	const [open, setOpen] = React.useState(false)
	const [openCancel, setOpenCancel] = React.useState(false)
	const queryClient = useQueryClient()
	const { cancelBooking } = ApiRequests()
	const { mutate } = useMutation(() => cancelBooking(session.id), {
		onSuccess: () => (
			queryClient.invalidateQueries(["availability"]),
			queryClient.invalidateQueries(["bookingList"])
		),
	})

	return (
		<div className="m-2.5 ml-0 md:ml-2.5 max-w-[380px] min-w-[380px] md:max-w-[420px] md:min-w-[420px]"
			style={{
				boxShadow:
					"rgba(50, 50, 93, 0.05) 0px 2px 5px -1px, rgba(0, 0, 0, 0.2) 0px 1px 3px -1px",
				padding: "10px 20px 10px",
				flex: 2,
				borderRadius: 8,
				color: "white",
				maxHeight: 250,
				display: "flex",
				justifyContent: "space-between",
				flexDirection: "column",
				position: "relative",
				background:
					upSessions?.length === 0
						? "white"
						: session?.cancel
						? "rgb(253, 237, 237)"
						: "rgb(95 106 196 / 5%)",
			}}
		>
			<SnackbarX
				open={open}
				setOpen={setOpen}
				backgroundColor="#32a676"
				message="The session has been successfully deleted!"
			/>
			<ConfirmationModal
				openConfirm={openCancel}
				setOpenConfirm={setOpenCancel}
				action={() => mutate()}
				topic="Cancel Session"
				message="Are you sure you want to cancel your session?"
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					height: "100%",
				}}
			>
				<div>
					<h3
						style={{
							color: session.cancel ? "rgb(216 70 68)" : "#5f6ac4",
							fontWeight: 600,
							fontSize: 20,
							padding: 0,
							marginBottom: 5,
						}}
					>
						{session.cancel && (
							<>
								<ErrorOutlineIcon
									style={{ width: 27, height: 27, marginBottom: 3 }}
								/>{" "}
								This session has been cancelled!
							</>
						)}
					</h3>
					<p
						style={{
							color: "rgb(50, 51, 49)",
							fontSize: "24px",
							fontWeight: 600,
							marginBottom: 5,
						}}
					>
						{dayjs(session.start_time).format("	MMM D, YYYY")}
					</p>
					<p
						style={{
							color: "#323331bf",
							fontSize: "15px",
							marginBottom: 10,
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						<AccessTimeIcon
							style={{
								color: "rgb(110 110 110 / 96%)",
								width: 30,
								height: 27,
								marginLeft: -3,
								marginRight: 5,
							}}
						/>{" "}
						{dayjs(session.start_time).format("	h:mm A")}
					</p>
					<p
						style={{
							color:
								session.status === "failed_payment_checkout"
									? "red"
									: session.status === "refund_in_progress"
									? "orange"
									: session.status === "refund_completed"
									? "purple"
									: "rgb(110 110 110 / 96%)",
							fontSize: "12px",
							marginBottom: 0,
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						Status:{" "}
						{session.status === "succeeded_payment_checkout"
							? "Payment Successful"
							: session.status === "failed_payment_checkout"
							? "Payment Failed"
							: session.status === "refund_in_progress"
							? "Refund in Progress"
							: session.status === "refund_completed"
							? "Refund Completed"
							: session.status}
					</p>
					<p
						style={{
							color: "rgb(110 110 110 / 96%)",
							fontSize: "12px",
							marginBottom: 10,
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						Session type: {session.is_session_for_couple ? <><PeopleIcon className="!w-5 ml-2"/></> : <><PersonIcon className="!w-5 ml-2" /></>}
					</p>
					<div
						style={{
							color: "#323331bf",
							fontSize: "15px",
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						<Avatar
							src={therapistImage}
							sx={{
								bgcolor: "#323331bf",
								border: "1px solid #5f6ac4",
								width: 25,
								height: 25,
								marginRight: 1,
							}}
						/>
						<div>
							<p
								style={{
									fontSize: 12,
									color: "#323331bf",
									marginBottom: -5,
								}}
							>
								Therapist
							</p>
							{session.therapist_name}
						</div>
					</div>
					<Button
						// disabled={leftTime > 5}
						// disabled={
						//   !session.cancel ? disableCall(session.date, session.time) : true
						// }
						style={{
							background: "#5f61c4",
							color: "white",
							marginTop: 15,
							marginBottom: 10,
							textTransform: "none",
						}}
					>
						<a
							target="_blank"
							rel="noreferrer"
							href={session?.conference_details?.url}
						>
							<VideocamIcon style={{ marginRight: 6 }} />
							Video Call
						</a>
					</Button>
					<IconButton
						onClick={() => setOpenCancel(true)}
						color="error"
						style={{
							// background: "white",
							color: "rgb(226, 109, 128)",
							// border: "1px solid rgb(226, 109, 128)",
							textTransform: "none",
							position: "absolute",
							top: "10px",
							right: "5px",
						}}
					>
						<CancelIcon />
					</IconButton>
				</div>
				<img className="h-[90px] md:h-[100px]"
					style={{
						color: "aqua",
						position: "absolute",
						top: 40,
						right: "40px",
					}}
					src={calendar}
					alt="calendar"
				/>
			</div>
		</div>
	)
}

export default TherapySession
